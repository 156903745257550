import range from 'lodash/range';

import {getPnrCount} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';
import { getAllBookingStatuses } from "airborne/store/modules/confirm_hotels/selectors/details";

export function selectNotBooked(state, after=-Infinity) {
    const statuses = getAllBookingStatuses(state);
    return range(0, getPnrCount(state))
        .filter((index)=> index > after)
        .filter((index)=> {
            return statuses[index] === null || statuses[index] === 'failed';
        });
}

function findIncomplete(state, after=-Infinity) {
    return selectNotBooked(state)
        .filter((index)=> index > after)
        .filter((index)=> getBooking(state, index).step !== 'book');
}

export function selectNextIndex(state, index) {
    const [nextIncomplete=null] = findIncomplete(state, index);
    if (nextIncomplete == null) {
        const [firstIncomplete=null] = findIncomplete(state);
        return firstIncomplete;
    }
    return nextIncomplete;
}
