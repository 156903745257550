import * as types from "airborne/store/modules/pricing_air/actionTypes";
import {requestFaresPricing} from "airborne/store/modules/pricing_air/actions/request";
import {getPricingFareByKey, getSeparatedTicketsCurrentOD} from "airborne/store/modules/pricing_air/selectors";

export const enableSeparatedTickets = () => ({type: types.AIR_ENABLE_SEPARATED_TICKETS});

export const setSeparatedTicketsOD = (currentOD) => ({
    type: types.AIR_SET_SEPARATED_TICKET_OD,
    currentOD
});

export const startSeparatedTicketsSearch = () => async (dispatch) => {
    dispatch(enableSeparatedTickets());
    await dispatch(requestFaresPricing());
};

export const saveSeparatedFare = (fareGroupKey) => (dispatch, getState) => {
    const currentOD = getSeparatedTicketsCurrentOD(getState());
    const separatedFare = getPricingFareByKey(getState(), fareGroupKey);

    dispatch({
        type: types.AIR_SAVE_SEPARATED_FARE,
        currentOD,
        separatedFare,
    });
};

export const resetSeparatedFare = (OD) => (dispatch) => {
    dispatch({
        type: types.AIR_SAVE_SEPARATED_FARE,
        currentOD: OD,
        separatedFare: null,
    });
};

export const resetSeparatedTickets = () => ({type: types.AIR_RESET_SEPARATED_TICKETS});
