import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';
import ProfileList from './ProfileList';

import {selectTspmProfile} from 'airborne/store/modules/homepage/actions/tspm';
import {getTravelers, isCompaniesLoading} from 'airborne/store/modules/homepage/selectors/tspm';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';

function mapStateProps(state, {index}) {
    const {pnr} = getPnrProfile(state, index);
    return {
        data: getTravelers(state, index),
        loading: isCompaniesLoading(state, index),
        pnr,
    };
}

@connect(mapStateProps,
    (dispatch, {index})=> ({
        onChange: (profile)=> dispatch(selectTspmProfile(profile, index))
    })
)
export default class MultyTravelerModal extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        pnr: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    };

    state = {
        selected: null,
        pnr: null,
    };

    static getDerivedStateFromProps({pnr}, state) {
        if (state.pnr !== pnr) {
            return {
                selected: null,
                pnr,
            };
        }
        return state;
    }

    findProfile(profileId) {
        const {data} = this.props;
        return find(data, (profile)=> profile.id === profileId);
    }

    handleSelect = (id)=> {
        this.setState({selected: id});
    }

    handleSubmit = ()=> {
        const profile = this.findProfile(this.state.selected);
        this.props.onChange(profile)
            .then(this.props.onHide);
    }

    render() {
        const {data, onHide, pnr, loading} = this.props;
        const {selected} = this.state;
        return (
            <Modal
                show
                keyboard
                backdrop="static"
                onHide={onHide}
                className="modal--rev modal-top"
                backdropClassName="modal-top"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Select Traveler Profile for PNR')} <strong>{pnr}</strong>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="modal-header-form">
                        <strong>{gettext('Multiple profiles were identified. Please choose the correct traveler profile.')}</strong>
                    </div>
                    <ProfileList
                        data={data}
                        selected={selected}
                        onChange={this.handleSelect} />
                </Modal.Body>

                <Modal.Footer>
                    <Button disabled={!selected} onClick={this.handleSubmit} bsStyle="success" progress={loading}>
                        {gettext('Select')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
