import isEmpty from 'lodash/isEmpty';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';
import {nopAborted} from 'airborne/homepage2/helpers/autocomplete';

const CATEGORIES = {
    'airports_only': 'Airport',
    'cities_only': 'City',
    'countries': 'Country',
    'custom_regions': 'Region',
    'states': 'State',
};

const TYPE_ICONS = {
    cr: 'google-maps',
    ct: 'compass',
    c: 'building',
    a: 'airplane',
    s: 'map',
};

const AUTOCOMPLETE_DESTINATION_TYPES_EXCLUSIONS = {'exclude_airports': true, 'exclude_custom_regions': true};

function query(url, params, parse, term) {
    return api('GET', url, {
        data: {
            term,
            user: settings.USER.id || '',
            ...params
        },
        id: 'destination_autocomplete',
    }).then(
        function ({data, 'match_data': match}) {
            const matchData = match || data.match_data;
            if (matchData && !isEmpty(matchData)) {
                return parse(match || data);
            }
            return [];
        }
    ).then(
        (results)=>
            (results.length ? results : 'No destinations found')
    ).catch(nopAborted);
}

function parseResponse(data) {
    const {'match_data': matches, order} = data;
    return order
        .map((category)=> ({
            category: CATEGORIES[category],
            items: matches[category]
        }))
        .filter(({items})=> items && items.length)
        .map(({category, items})=> ({
            category,
            items: items
                .map(({label, type, value})=> ({
                    label,
                    value,
                    autocompleteCategory: category,
                    icon: TYPE_ICONS[type],
                }))
        }));
}


function parseCountries(data) {
    const {match_data: {countries}} = data; // eslint-disable-line camelcase
    return countries;
}

function parseCountryNames(data) {
    const countries = parseCountries(data);
    return countries.map(({label})=> ({
        label,
        value: label
    }));
}

export const destinationAutocomplete = {
    query: query.bind(
        null, '/api/companies/destinations_autocomplete/', {}, parseResponse
    ),
};

export const destinationCountryAutocomplete = {
    query: query.bind(
        null, '/api/regions/country_autocomplete/', {}, parseCountries
    ),
};

export const destinationCountryNameAutocomplete = {
    query: query.bind(
        null, '/api/regions/country_autocomplete/', {}, parseCountryNames
    ),
};

export const destinationCityAutocomplete = {
    query: query.bind(null, '/api/regions/city_autocomplete/', {}, parseResponse),
};

export const destinationCountryStateCityAutocomplete = {
    query: query.bind(null, '/api/companies/destinations_autocomplete/', AUTOCOMPLETE_DESTINATION_TYPES_EXCLUSIONS, parseResponse),
};

export const destinationAirportAutocomplete = {
    query: query.bind(null, '/api/regions/airport_autocomplete/', {}, parseResponse),
};

export const destinationAirPolicyAutocomplete = {
    query: query.bind(null, '/api/companies/destinations_autocomplete/', {'exclude_states': true}, parseResponse),
};
