'process i18n';
import settings from 'airborne/settings';
import aio from 'midoffice/helpers/aio';
import noop from 'lodash/noop';
import location from 'airborne/location';
import gettext from 'airborne/gettext';
import api from 'midoffice/helpers/api';
import getErrors from 'midoffice/helpers/apiErrors';
import {partialCancelledWarning} from 'midoffice/components/errorMessages';
import {searchBookings} from 'midoffice/bookings/actions';
import StreamService, {MESSAGE_TYPES, ERRORS} from 'airborne/services/Stream';
import {
    showError,
    showInfo,
    showLoader,
    hideLoader,
    hideModal,
} from 'midoffice/data/actions/modals';
import air from 'airborne/store/modules/search_hotels/actions/air';
import session from 'airborne/session';

function toModification(sid) {
    location.assign(`/?sid=${sid}`);
    session.set('sid', '');
    return {type: 'TO_HOMEPAGE'};
}

export function modifyBooking(uid) {
    return function modifyBookingD(dispatch) {
        dispatch({type: 'LOADING', endpoint: 'bookingModification', id: uid});

        return api('POST', '/hotel/api/create-modification-session/', {
            data: {uid},
        }).then(
            ({sid}) => {
                dispatch({type: 'LOADED', endpoint: 'bookingModification'});
                dispatch(toModification(sid));
            },
            response => {
                dispatch({type: 'NET_FAIL', endpoint: 'bookingModification'});
                dispatch(showError(getErrors(response)));
            }
        );
    };
}

function informSuccess(data) {
    return showInfo(
        ...(data.status === 'partial_cancel'
            ? [partialCancelledWarning(), 'warning']
            : [gettext('Booking canceled'), 'success'])
    );
}
const FALLBACK_TIMEOUT = 30 * 1000;

async function waitForStatuses(response, uid) {

    function conditionCheck(event) {
        const {type, message} = event;
        const {cancellationStatus, bookingId} = message;
        if (type === MESSAGE_TYPES.BOOKING_STATUS && bookingId === uid && cancellationStatus === 'success') {
            return true;
        }
    }


    try {
        await StreamService.reactOnEvent(
            noop,
            conditionCheck,
            FALLBACK_TIMEOUT,
        );
    }
    catch (error) {
        if (error === ERRORS.PUSH_STREAM_INIT_ERROR) {
            return response.cancellation_status === 'success' || response.cancellation_status === 'failed';
        }
        throw error;
    }
}

function informError(response) {
    return showError([
        gettext(
            'Cancellation failed. Check Cancellation Error Details for more information.'
        ),
        getErrors(response) || '',
    ]);
}

const timeoutErrorText = () =>
    gettext(
        'Something went wrong. There is a chance that the transaction went through behind the scene. Please try again in few minutes, but only after making sure that the booking is not canceled.'
    );

const getReservationDetails = async (uid) => {
    return await air(
        'GET',
        '/hotels/get_reservation_details/',
        {
            'booking_uid': uid,
        },
    );
};

const isReservationDetailsResolved = (response) => {
    if (response.cancellation_status === 'failed') {
        throw response;
    }
    return response.cancellation_status === 'success';
};

export function cancelBooking(uid) {
    const checkCancellationStatus = aio.retry(
        async (uid) =>
            await getReservationDetails(uid),
        async (response) => {
            await waitForStatuses(response, uid);
            const cancellationResponse = await getReservationDetails(uid);
            return isReservationDetailsResolved(cancellationResponse);
        },
        2,
        20,
        false,
        timeoutErrorText(),
    );

    return function cancelBookingD(dispatch) {
        dispatch({type: 'LOADING', endpoint: 'bookingModification', id: uid});
        dispatch(showLoader(gettext('Cancelling reservation')));
        settings.set({sid: `f${(+new Date()).toString(16)}`});

        return air(
            'POST',
            '/hotels/cancel_reservation/',
            {
                'booking_uid': uid,
            },
        )
            .then(() => new Promise((resolve, reject) => {
                checkCancellationStatus(uid).then((response) => {
                    if (response.cancellation_status === 'failed') {
                        reject({body: response.cancellation_error.external_error_text});
                    }
                    resolve(response);
                }).catch((response) => {
                    reject(response);
                });
            }))
            .then(response => {
                dispatch({type: 'LOADED', endpoint: 'bookingModification'});
                dispatch(informSuccess(response));
                dispatch(searchBookings());
            })
            .catch(response => {
                dispatch({type: 'NET_FAIL', endpoint: 'bookingModification'});
                dispatch(informError(response));
            })
            .finally(() => {
                dispatch(hideModal());
                dispatch(hideLoader());
            });
    };
}
