'process i18n';
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import {splitCamelCase} from 'midoffice/helpers/format';
import amenityFormat from 'airborne/search2/amenities';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';


function reasonable(after, before) {
    return (after && before) && (Math.round(after - before));
}

function getTaxes(taxes, after, before, currency) {
    if (taxes) {
        return {
            money: taxes,
            currency,
        };
    }
    if (reasonable(after, before)) {
        return {
            money: after - before,
            currency: currency,
        };
    }

    return null;
}

function raw(html) {
    return html ? (<div dangerouslySetInnerHTML={{__html: html}} />)
        : null;
}

function rawspan(html) {
    return html ? (<span dangerouslySetInnerHTML={{__html: html}} />)
        : null;
}

export class Line extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        children: PropTypes.any,
        italic: PropTypes.bool,
    };

    render() {
        const {label, children, italic} = this.props;
        if (!children) {
            return null;
        }
        return (<div>
            {label && (<span>{label}:&nbsp;</span>)}
            {italic ? (<i>{children}</i>) : children}
        </div>);
    }
}

export class Desc extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        children: PropTypes.any,
    };

    render() {
        const {label, children} = this.props;
        if (!children) {
            return null;
        }

        return (<div className="modal-rate-desc__wrap">
            <h2 className="modal-rate-desc__title">
                {label}
            </h2>
            <div className="well well-sm">
                {children}
            </div>
        </div>);
    }
}

export class List extends React.Component {
    static propTypes = {
        children: PropTypes.array,
    };

    render() {
        const {children} = this.props;
        if (!children) {
            return null;
        }
        return (<ul className="no-margin">
            {children.map((line, idx)=> <li key={idx}>{line}</li>)}
        </ul>);
    }
}

export class BreakLines extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]),
        dangerouslySetInnerHTML: PropTypes.string,
    };

    render() {
        const {children, dangerouslySetInnerHTML} = this.props;

        if (dangerouslySetInnerHTML) {
            return (<div dangerouslySetInnerHTML={{__html: dangerouslySetInnerHTML}}/>);
        }

        if (!children) {
            return null;
        }

        const lines = Array.isArray(children)
            ? children
            : children.split('\n');
        return (<div>
            { lines.map((line, idx)=> (
                <div key={idx}>{line}</div>
            )) }
        </div>);
    }
}

export class CheckinInstructions extends React.Component {
    static propTypes = {
        special: PropTypes.string,
        instructions: PropTypes.string,
    };

    render() {
        const {special, instructions} = this.props;
        if (!special && !instructions) {
            return null;
        }

        return (<div className="modal-rate-desc__extra">
            <p>
                <b>{ gettext('Check-In Instructions') }</b>
            </p>
            <p>
                <i>{ gettext('The following details are general hotel rules. Information related to this specific rate may differ. Refer to the description above for the most accurate details for this rate.') }</i>
            </p>
            { rawspan(special) }
            { raw(instructions) }
        </div>);
    }
}

export class Fees extends React.Component {
    static propTypes = {
        fees: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string.isRequired,
                unit: PropTypes.string.isRequired,
                frequency: PropTypes.string.isRequired,
                amount: PropTypes.number,
            })
        ),
        currency: PropTypes.string.isRequired,
    };

    renderFee(fee, idx) {
        const {currency} = this.props;
        return (
            <li key={idx}>
                { splitCamelCase(fee.description) }:&nbsp;
                { formatPrice(fee.amount, currency) }&nbsp;
                { fee.unit.toLowerCase() }&nbsp;
                { fee.frequency.toLowerCase() }
            </li>
        );
    }
    render() {
        const {fees} = this.props;
        return (<div>
            { gettext('Additional fees, due at hotel') }:
            <ul>
                {fees.map((fee, idx)=> (
                    this.renderFee(fee, idx)
                ))}
            </ul>
        </div>);
    }
}

export class Details extends React.Component {
    static propTypes = {
        room_type_code: PropTypes.any,
        rate_code: PropTypes.any,
        rate_key: PropTypes.string,
        commission: PropTypes.shape({
            description: PropTypes.any,
            percent: PropTypes.any,
            commissionable: PropTypes.bool,
        }),
        checkin_time: PropTypes.string,
        checkout_time: PropTypes.string,
        tax_mark: PropTypes.string,
        voucher_observation: PropTypes.string,
        bed_types: PropTypes.array,
        max_occupancy: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        smoking_preferences: PropTypes.string,
        rate_inclusions_list: PropTypes.array,
        per_night: PropTypes.array,
        tax_details: PropTypes.array,
        taxes: PropTypes.number,
        hotel_fees: PropTypes.number,
        total: PropTypes.number,
        total_after_taxes: PropTypes.number,
        total_before_taxes: PropTypes.number,
        promotion_details: PropTypes.array,
        payment_details: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]),
        payment_cards_accepted: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]),
        hold_time: PropTypes.string,
        min_stay: PropTypes.string,
        max_stay: PropTypes.string,
        plan_name: PropTypes.string,
        description: PropTypes.string,
        additional_rate_information: PropTypes.string,
        rate_promoline: PropTypes.string,
        rate_details_promoline: PropTypes.string,
        room_description: PropTypes.string,
        rate_details_description: PropTypes.string,
        rate_description: PropTypes.string,
        detailed_rate_description: PropTypes.string,
        amenities: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
        })),
        rate_value_adds: PropTypes.array,
        disclaimer: PropTypes.string,
        cancellation_policy: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        important_info: PropTypes.string,
        check_in_instructions: PropTypes.string,
        special_check_in_instructions: PropTypes.string,
        currency: PropTypes.string,
        noAgent: PropTypes.bool,
        provider_id: PropTypes.string,
        provider_hotel: PropTypes.string,
        chain_code: PropTypes.string,
        chain_name: PropTypes.string,
        iata_code: PropTypes.string,
        reception: PropTypes.string,
        deposit_policy: PropTypes.string,
    }

    renderRates(plan_name, description, additional_rate_information, rate_inclusions_list, per_night, currency) {
        if (!per_night && !plan_name && !description) {
            return null;
        }

        return (<div>
            <h2 className="modal-rate-desc__title">
                { gettext('Rate Information') }
            </h2>
            <div className="well well-sm">
                { plan_name && (<div>
                    <b>{ plan_name }</b>
                    <hr className="hr-condensed" />
                </div>) }
                <Line>
                    <p>{description}</p>
                </Line>

                <div className="modal-rate-desc__row">
                    <BreakLines>
                        {additional_rate_information}
                    </BreakLines>
                </div>

                <div className="modal-rate-desc__row">
                    <BreakLines>
                        {rate_inclusions_list}
                    </BreakLines>
                </div>

                <div>
                    <strong>{ gettext('Nightly rates') }:</strong>
                    <ul className="modal-rate-desc__list">
                        { per_night.map((nightly_rate, idx)=> (
                            <li key={idx}>
                                { nightly_rate.date }:&nbsp;
                                { formatPrice(nightly_rate.rate, currency) }
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>);
    }

    renderAgent() {
        const {
            iata_code, chain_code, chain_name, provider_hotel,
            provider_id,
            room_type_code, rate_code,
            commission,
        } = this.props;
        const description = (commission && commission.description) || null;
        const percent = (commission && commission.percent) || null;
        const commissionable = (commission && commission.commissionable) || null;

        return (<div className="modal-rate-desc__wrap">
            <h2 className="modal-rate-desc__title">
                { gettext('Agent Information') }
            </h2>

            <div className="well well-sm">
                <Line label={gettext('IATA code')} italic>
                    {iata_code}
                </Line>

                <Line label={gettext('Hotel chain')} italic>
                    {chain_name && `${ chain_name } (${chain_code})`}
                </Line>

                <Line label={gettext('Supplier hotel ID')} italic>
                    {provider_hotel || provider_id}
                </Line>

                <Line label={gettext('Supplier room ID')} italic>
                    {room_type_code}
                </Line>

                <Line label={gettext('Supplier rate ID')} italic>
                    {rate_code}
                </Line>

                <Line label={gettext('Commission details')} italic>
                    { description || percent || (commissionable
                        ? gettext('Commissionable') : gettext('Non-Commissionable'))}
                </Line>

            </div>
        </div>);
    }

    render() {
        const {
            noAgent,
            currency,
            checkin_time, checkout_time,
            rate_description, detailed_rate_description, rate_details_description,
            room_description,
            rate_value_adds,
            voucher_observation,
            cancellation_policy,
            important_info,
            bed_types, max_occupancy, smoking_preferences,
            rate_inclusions_list,
            per_night=[],
            tax_details,
            total_after_taxes,
            total_before_taxes,
            total,
            promotion_details,
            payment_details,
            payment_cards_accepted,
            hotel_fees,
            hold_time, min_stay, max_stay,
            plan_name, description, disclaimer,
            additional_rate_information,
            rate_promoline, rate_details_promoline,
            special_check_in_instructions, check_in_instructions, reception,
            deposit_policy,
        } = this.props;

        const taxes = getTaxes(
            this.props.taxes,
            total_after_taxes, total_before_taxes, currency
        );

        return (<div className="modal-rate-desc"><section className="modal-rate-desc__section">
            <div className="modal-rate-desc__wrap">
                <h2 className="modal-rate-desc__title">
                    { gettext('Description') }
                </h2>
                <div className="well well-sm">
                    { raw(room_description) }

                    <BreakLines>
                        {detailed_rate_description || rate_details_description || rate_description}
                    </BreakLines>
                </div>

                { voucher_observation && (<div className="well well-sm">{ voucher_observation }</div>) }

                <div className="well well-sm">
                    <Line label={gettext('Bed type')}>
                        {bed_types && bed_types.join(', ')}
                    </Line>
                    <Line label={gettext('Max occupancy')}>
                        {max_occupancy}
                    </Line>
                </div>

                <div className="well well-sm">
                    <BreakLines>
                        {rate_value_adds && rate_value_adds.length && rate_value_adds.map(amenityFormat) || null}
                    </BreakLines>
                </div>

                <div className="well well-sm">
                    <Line label={gettext('Smoking preferences')}>
                        {smoking_preferences}
                    </Line>
                </div>

                <div className="well well-sm">
                    <Line label={gettext('Check-in time')} italic>
                        {checkin_time}
                    </Line>

                    <Line label={gettext('Check-out time')} italic>
                        {checkout_time}
                    </Line>
                </div>
            </div>

            <div className="modal-rate-desc__wrap">
                { this.renderRates(plan_name || rate_details_promoline || rate_promoline, description, additional_rate_information, rate_inclusions_list, per_night, currency) }

                <div className="well well-sm">
                    <Line label={gettext('Taxes and surcharges')}>
                        {Array.isArray(tax_details)
                            ? Boolean(tax_details.length) && (<List>{tax_details}</List>)
                            : raw(tax_details)}
                    </Line>

                    <Line label={gettext('Total taxes and surcharges')}>
                        {taxes && (<i>{formatPrice(taxes.money, taxes.currency)}</i>)}
                    </Line>

                    <Line label={gettext('Estimated total')}>
                        <i>
                            { formatPrice(total || total_after_taxes || total_before_taxes, currency) }
                        </i>
                    </Line>

                    {hotel_fees && (<Fees currency={currency} fees={hotel_fees} />)}
                </div>
            </div>

            <div className="modal-rate-desc__wrap">
                { disclaimer && (<div className="well well-sm">{ disclaimer }</div>) }
            </div>

            <Desc label={gettext('Deposit Policy')}>
                {deposit_policy && (<BreakLines>{deposit_policy}</BreakLines>)}
            </Desc>

            <Desc label={gettext('Cancelation Policy')}>
                {cancellation_policy && (<BreakLines>{cancellation_policy}</BreakLines>)}
            </Desc>

            <Desc label={gettext('Reception Hours')}>
                {raw(reception)}
            </Desc>

            <Desc label={gettext('Important information')}>
                {important_info && (
                    <BreakLines dangerouslySetInnerHTML={important_info} />
                )}
            </Desc>

            { noAgent ? null : this.renderAgent() }

            <Desc title={gettext('Promotion Details')}>
                {promotion_details && (
                    <BreakLines>{promotion_details}</BreakLines>
                )}
            </Desc>

            <div className="modal-rate-desc__wrap">
                <h2 className="modal-rate-desc__title">
                    { gettext('Booking Requirements') }
                </h2>

                <div className="well well-sm">
                    <BreakLines>
                        {payment_details}
                    </BreakLines>

                    <div className="modal-rate-desc__row">
                        <Line label={gettext('Accepted credit cards')} italic>
                            {Array.isArray(payment_cards_accepted)
                                ? payment_cards_accepted.map((card)=> (card.code || String(card))).join(', ')
                                : payment_cards_accepted
                            }
                        </Line>
                    </div>

                    <CheckinInstructions
                        instructions={check_in_instructions}
                        special={special_check_in_instructions} />

                    <Line label={gettext('Hold time')} italic>
                        {hold_time}
                    </Line>

                    <Line label={gettext('Min stay')} italic>
                        {min_stay}
                    </Line>
                    <Line label={gettext('Max stay')} italic>
                        {max_stay}
                    </Line>
                </div>
            </div>
        </section></div>);
    }
}

