'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {TabContent} from 'midoffice/components/TabsWithContent';
import gettext from 'airborne/gettext';
import {Line} from 'midoffice/components/RateDetails';

export default class AgentInfoTab extends React.Component {
    static propTypes = {
        'iata_code': PropTypes.string,
        'chain_name': PropTypes.string,
        'chain_code': PropTypes.string,
        'provider_hotel': PropTypes.string,
        'provider_id': PropTypes.string,
        'room_type_code': PropTypes.string,
        'rate_code': PropTypes.string,
        'commission': PropTypes.object,
    }
    render() {
        const {
            'iata_code': iataCode,
            'chain_name': chainName,
            'chain_code': chainCode,
            'provider_hotel': providerHotel,
            'provider_id': providerId,
            'room_type_code': roomTypeCode,
            'rate_code': rateCode,
            commission,
        } = this.props;
        const description = (commission && commission.description) || null;
        const percent = (commission && commission.percent) || null;
        const commissionable = (commission && commission.commissionable) || null;

        return (<TabContent>
            <div className="modal-rate-desc__wrap">
                <h4>
                    <strong>{gettext('Agent Information')}</strong>
                </h4>

                <div>
                    <Line label={gettext('IATA code')} italic>
                        {iataCode}
                    </Line>

                    <Line label={gettext('Hotel chain')} italic>
                        {chainName && `${ chainName } (${chainCode})`}
                    </Line>

                    <Line label={gettext('Supplier hotel ID')} italic>
                        {providerHotel || providerId}
                    </Line>

                    <Line label={gettext('Supplier room ID')} italic>
                        {roomTypeCode}
                    </Line>

                    <Line label={gettext('Supplier rate ID')} italic>
                        {rateCode}
                    </Line>

                    <Line label={gettext('Commission details')} italic>
                        { description || percent || (commissionable ? gettext('Commissionable') : gettext('Non-Commissionable'))}
                    </Line>

                </div>
            </div>
        </TabContent>);
    }
}
