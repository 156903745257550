import omit from 'lodash/omit';
import settings from 'airborne/settings';
import React from 'react';
import PropTypes from 'prop-types';
import Input from 'midoffice/newforms/widgets/Input';
import Select from 'midoffice/newforms/widgets/Select';

export function states(country) {
    return settings.COUNTRY_STATES[country];
}

export function fixAddress(value, prefix='') {
    const country = value[prefix + 'country_code'];
    const state = value[prefix + 'state_code'];
    const countryStates = states(country);
    return (countryStates && countryStates[state])
        ? value
        : omit(value, prefix + 'state_code');
}

export default class BillingAddress extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        isDefaultLabel: PropTypes.bool,
    };

    static contextTypes = {
        form: PropTypes.shape({
            value: PropTypes.object.isRequired,
        }).isRequired,
    }

    getStates() {
        const {name=''} = this.props;
        const {value} = this.context.form;
        return states(value && value[name + 'country_code']);
    }

    render() {
        const {name='', isDefaultLabel, ...extra} = this.props;
        const stateChoices = this.getStates();
        const sectionTitle = isDefaultLabel ? 'Default Billing Address' : 'Billing Address';
        return (<div>
            <h5 className="form-secondary-title">{sectionTitle}</h5>

            <Input.Field name={name + 'address'} label="Address" {...extra} />
            <Input.Field name={name + 'city'} label="City" {...extra} />
            <Select.Field
                name={name + 'country_code'}
                label="Country"
                choices={settings.COUNTRIES}
                allowEmpty
                {...extra} />
            {stateChoices && (<Select.Field
                required
                name={name + 'state_code'}
                label="State"
                choices={Object.entries(stateChoices)}
                allowEmpty
                {...extra} />)}
            <Input.Field name={name + 'postal_code'} label="Postal Code (ZIP)" {...extra} />
        </div>);
    }
}
