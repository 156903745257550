import sortBy from 'lodash/sortBy';


export function getChoices(collection) {
    return Array.isArray(collection) ? sortBy(
        collection
            .filter(([code])=> code !== '___')
            .map(([code, name])=> [code, `${code} - ${name}`]),
        (item)=> item[0]
    ) : [];
}
