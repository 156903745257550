'process i18n';
import gettext from 'airborne/gettext';

export function partialCancelledWarning() {
    return gettext('A cancellation has been requested, but the hotel did not immediately return a cancellation confirmation number. Contact the hotel to confirm reservation is canceled.');
}

export function ghostBookingWarning() {
    return gettext('This hotel booking has been confirmed, but the user did not see a confirmation page. There is a good chance that a duplicate booking will take place and that this one needs to be canceled.');
}

export function counterofferBookingInfo() {
    return gettext('This reservation requires attention from an agent. If you are planning on following up – click on More → “Reservation Actions” and select “Assign to Me” to let others know you are working on it.');
}

export function remarksAddBookingInfo() {
    return gettext('The remarks were not added successfully during this booking.');
}

export function postTicketingRemarksAddBookingInfo() {
    return gettext('The post ticketing remarks were not added successfully during this booking.');
}

export function ticketingFailedMessage() {
    return gettext('Tickets were not issued successfully for this booking.');
}

export function canceledByExternal() {
    return gettext('This reservation has been cancelled outside of ASB, and possibly rebooked externally.');
}

export function passiveSegmentsErrorMessage() {
    return gettext('This air booking has been confirmed with provider, but the system failed to create the corresponding passive segment in this GDS PNR.');
}
