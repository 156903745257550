import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';
import Input from 'midoffice/newforms/widgets/Input';

import ErrorBox from './ErrorBox';
import ProfileList from './ProfileList';
import ShortcutWrapper from 'midoffice/newforms/components/ShortcutWrapper';

import schema from './TravelerSearchSchema';

import {getPnrProfile, getHomepagePnrIndex} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';
import {guestTravelerEnabled} from 'airborne/search2/helpers/hyatt';

import {
    searchTspmProfile,
    discardTspmProfiles,
    selectTspmProfile, closeProceedWithoutPnr,
} from 'airborne/store/modules/homepage/actions/tspm';

import {
    isTravelersLoading,
    isCompaniesLoading,
    getTravelers,
    noTravelersFound,
    hasResults,
    getTravelersError, getSelectedConfiguration,
} from 'airborne/store/modules/homepage/selectors/tspm';
import {isCompanyWithoutPnr} from 'airborne/store/modules/homepage/selectors/homepage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import {currentCompany} from "airborne/homepage2/helpers/search";


gettext('This field is required');

@injectFormContext
class SearchForm extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        configurationId: PropTypes.number,
    };

    componentDidMount() {
        setTimeout(
            () => this.inputRef && this.inputRef.focus && this.inputRef.focus(),
            100,
        );
    }

    handleReference = (input)=> {
        this.inputRef = input; // eslint-disable-line immutable/no-mutation
    };

    render() {
        const {loading, onSubmit} = this.props;
        return (<div className="modal-header-form">
            <form data-cy="tspm-profile-search-form" className="form-inline" onSubmit={onSubmit} >
                <Input.Field
                    ref={this.handleReference}
                    label={gettext('Email')}
                    labelSuffix=""
                    name="email"
                    inputSize="split"
                    grid={false} />
                <Input.Field
                    label={gettext('Last Name')}
                    labelSuffix=""
                    name="name"
                    inputSize="split"
                    grid={false} />

                <Button
                    disabled={loading}
                    progress={loading}
                    bsStyle="primary"
                    onClick={this.props.onSubmit}>
                    <Shortcut label={gettext('Search')} shortcut={SHORTCUTS_LIST.N} action={this.props.onSubmit} isModalShortcut isTab />
                </Button>
            </form>
        </div>);
    }
}

function mapStateProps(state, {index}) {
    const profile = getPnrProfile(state, index) || {};

    return {
        loading: isTravelersLoading(state, index),
        saving: isCompaniesLoading(state, index),
        data: getTravelers(state, index),
        empty: noTravelersFound(state, index),
        hasResults: hasResults(state, index),
        error: getTravelersError(state, index),
        selected: profile['tspm_traveler_id'] || null,
        guestEnabled: guestTravelerEnabled(state),
        proceedWithoutPnr: isCompanyWithoutPnr(state),
        configurationId: currentCompany(state),
    };
}


@connect((state)=> ({
    index: getHomepagePnrIndex(state),
    skipTspmModal: getFeatureFlag(state, 'QA_SKIP_TSPM_MODAL'),
}))
@connect(mapStateProps, (dispatch, {index}) => ({
    onSearch: (value, configurationId)=> dispatch(searchTspmProfile(value, configurationId, index)),
    onClose: ()=> dispatch(discardTspmProfiles(index)),
    onChange: (profile)=> dispatch(selectTspmProfile(profile, index)),
    closeProceedWithoutPnr: () => dispatch(closeProceedWithoutPnr()),
}))
export default class TravelerModal extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        saving: PropTypes.bool,
        index: PropTypes.number,
        selected: PropTypes.string,
        error: PropTypes.object,
        empty: PropTypes.bool,
        hasResults: PropTypes.bool,
        data: PropTypes.array.isRequired,
        guestEnabled: PropTypes.bool,
        proceedWithoutPnr: PropTypes.bool,
        skipTspmModal: PropTypes.bool,
        configurationId: PropTypes.number,

        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        closeProceedWithoutPnr: PropTypes.func.isRequired,
    };

    static defaultProps = {
        skipTspmModal: true,
    }

    state = {
        value: {
            email: null,
            name: null,
        },
        errors: null,
        selected: this.props.selected,
        data: this.props.data,
    };

    componentWillUnmount() {
        const {onClose, closeProceedWithoutPnr, skipTspmModal} = this.props;

        if (!skipTspmModal) {
            closeProceedWithoutPnr();
        }

        onClose();
    }

    static getDerivedStateFromProps({data}, {selected}) {
        if (data.length && !data.map(({id}) => id).includes(selected)) {
            return {
                selected: data[0].id,
            };
        }

        return null;
    }


    findProfile(profileId) {
        const {data} = this.props;
        return find(data, (profile)=> profile.id === profileId);
    }

    getSelectedIndex = () => this.props.data
        .map(profile => profile.id)
        .indexOf(this.state.selected);

    selectNextProfile = () => {
        const {data} = this.props;
        const nextElement = data[this.getSelectedIndex() + 1];

        nextElement && this.handleSelect(nextElement.id);
    };

    selectPrevProfile = () => {
        const {data} = this.props;
        const prevElement = data[this.getSelectedIndex() - 1];

        prevElement && this.handleSelect(prevElement.id);
    };

    handleChange = ({value}) => {
        this.setState({value, errors: null});
    };

    handleSearch = () => {
        const {value} = this.state;
        const {configurationId} = this.props;
        const errors = schema.validate(value, configurationId);
        if (noErrors(errors)) {
            this.props.onSearch(value, configurationId);
        }
        else {
            this.setState({errors});
        }
    };

    handleSelect = id => {
        this.setState({selected: id});
    };

    handleNone = () => {
        this.props.onHide();
        this.props.onChange(null);
    };

    handleSubmit = () => {
        const profile = this.findProfile(this.state.selected);

        if (!Boolean(profile) || this.props.saving) {
            return null;
        }

        this.props.onChange(profile)
            .then(this.props.onHide);
    };

    handleSubmitShortcutAction = () => {
        if (document?.activeElement?.tagName === 'INPUT') {
            this.handleSearch();
            document.activeElement.blur();
        }
        else {
            this.state.selected && this.handleSubmit();
        }
    };

    renderPrompt() {
        return (<h4>{gettext('Please enter email and/or last name, then click on the Search button.')}</h4>);
    }

    renderEmpty() {
        return (<ErrorBox append={gettext('Please enter different search criteria.')}>
            {gettext('No Results Found')}
        </ErrorBox>);
    }

    renderProceedWithoutPnrWarning() {
        return (
            <div className="alert alert-warning">
                <strong>{gettext('The TSPM profile is absent.')}</strong>
                <p>{gettext('Please search for the appropriate profile and select it first.')}</p>
            </div>
        );
    }

    renderError(error) {
        return (<ErrorBox append={error.body}>{error.title}</ErrorBox>);
    }

    render() {
        const {loading, saving, data, guestEnabled, empty, error, hasResults, onHide, proceedWithoutPnr, configurationId} = this.props;
        const {value, errors: formErrors, selected} = this.state;
        const hasProfile = Boolean(this.findProfile(selected));
        const showPrompt = !empty && !error && !hasResults;

        return (
            <ShortcutWrapper shortcuts={[
                {shortcut: 'enter', action: this.handleSubmitShortcutAction},
                {shortcut: 'up', action: this.selectPrevProfile},
                {shortcut: 'down', action: this.selectNextProfile},
            ]}>
                <Modal show keyboard backdrop="static" onHide={onHide} className="modal--rev" animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {gettext('Select TSPM Traveler Profile')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <SearchForm
                            configurationId={configurationId}
                            loading={loading}
                            value={value}
                            errors={formErrors}
                            schema={schema}
                            onChange={this.handleChange}
                            onSubmit={this.handleSearch} />
                        {!error && (<ProfileList
                            data={data}
                            selected={selected}
                            onChange={this.handleSelect} />)}
                        <div className="modal-body__panel">
                            {empty && this.renderEmpty()}
                            {error && this.renderError(error)}
                            {showPrompt && !proceedWithoutPnr && this.renderPrompt()}
                            {showPrompt && proceedWithoutPnr && this.renderProceedWithoutPnrWarning()}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {guestEnabled && (<Button bsStyle="link" name="no_traveler" className="pull-left" onClick={this.handleNone}>
                            <Shortcut label={gettext('Book without using traveler profile »')} shortcut={SHORTCUTS_LIST.U} action={this.handleNone} isModalShortcut isTab />
                        </Button>)}
                        <Button bsStyle="light" onClick={onHide}>
                            <Shortcut label={gettext('Cancel')} shortcut={SHORTCUTS_LIST.X} action={onHide} isModalShortcut isTab />
                        </Button>
                        <Button data-cy="tspm-profile-search-select-button" disabled={saving || !hasProfile} progress={saving} onClick={this.handleSubmit} bsStyle="success" >
                            <Shortcut label={gettext('Select')} shortcut={SHORTCUTS_LIST.ENTER} action={this.handleSubmit} isModalShortcut isInherit />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ShortcutWrapper>
        );
    }
}
