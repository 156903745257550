import wrap from 'airborne/search2/LazyComponent';

export function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const {default: modal} = require('airborne/bookings2/BookingModal');
            resolve({list, modal});
        }, 'bookings2');
    });
}

export const BookingsList = wrap(load, 'list');
export const BookingModal = wrap(load, 'modal');
