import generateUUID from 'airborne/checkout2/helpers/uuid';
import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import {getRecommendations} from 'airborne/store/modules/checkout_hotels/actions/recommend';
import browserHistory from 'airborne/browserHistory';


function toCheckout2(hotelId, rateKey, index) {
    browserHistory.push('/ui/hotels/checkout');
    return {type: 'TO_CHECKOUT', hotelId, rateKey, uuid: generateUUID(), index};
}

export default function bookRate(hotelId, rateKey) {
    return function bookRateD(dispatch) {
        const index = 0;
        dispatch({type: 'BOOKING_DETAILS', data: [], index});
        dispatch({type: 'BOOKING_UID', uid: null, index});

        dispatch({type: 'BOOKING_LOADING2', index});
        return dispatch(getRateDetails(hotelId, rateKey, true))
            .then(()=> dispatch(toCheckout2(hotelId, rateKey, index)))
            .then(()=> dispatch(getRecommendations(hotelId, rateKey, index, true)))
            .catch(()=> dispatch({type: 'BOOKING_FAIL', index}));
    };
}
