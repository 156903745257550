import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import gettext from 'airborne/gettext';
import {injectField} from 'midoffice/newforms/decorators';
import React from 'react';
import PropTypes from 'prop-types';
import Tags from 'midoffice/newforms/widgets/Tags';

function byLabel(term, {label, parentValue}) {
    return parentValue.toLowerCase().indexOf(term) !== -1 || label.toLowerCase().indexOf(term) !== -1;
}

function orderedChains(list, exactValue) {
    return orderBy(list, ({parentValue, label, value})=> ([
        parentValue !== exactValue,
        parentValue,
        value !== parentValue,
        label,
    ]));
}

function skipDefault(option) {
    return {skip: true, ...option};
}

const chainAutocomplete = {
    query(term, {choices}) {
        const matching = Object.values(choices)
            .filter(byLabel.bind(null, term.toLowerCase()));

        matching.forEach(({parentValue, value})=> {
            parentValue !== value && matching.push(
                skipDefault(choices[parentValue])
            );
        });

        const exactMatch = choices[term.toUpperCase()];
        const matches = uniqBy(compact([
            exactMatch &&
            exactMatch.value !== exactMatch.parentValue &&
            skipDefault(choices[exactMatch.parentValue]),
            exactMatch,
            ...orderedChains(matching, exactMatch && exactMatch.value),
        ]), 'value');
        return matches.length
            ? Promise.resolve(matches)
            : Promise.reject(gettext('No results found'));
    },

    empty({choices}) {
        return Promise.resolve(orderedChains(choices));
    },
};

function addLabel(value, choices) {
    return value && value.map((chain)=> (choices[chain] && {
        value: chain,
        label: `${chain} — ${choices[chain].label}`
    }));
}

@injectField
export default class ChainsField extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        choices: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleChange = (value, name)=> {
        this.props.onChange(
            (value && value.length)
                ? value.map(({value})=> value)
                : null,
            name
        );
    };

    render() {
        const {value, choices, ...props} = this.props;

        return (<Tags
            value={compact(addLabel(value, choices))}
            autocompleteSource={chainAutocomplete}
            autocompleteExtra={{choices}}
            withChainInfo
            clearable
            {...props}
            onChange={this.handleChange} />);
    }
}

