/* global IS_PROD,global */
import wrap from 'airborne/search2/LazyComponent';
import {connect} from 'react-redux';
import {isRatesLoading} from 'airborne/store/modules/search_cars/selectors';
import gettext from 'airborne/gettext';

import Loader from 'airborne/common/Loader';


export function loadCarSearch() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./Search');
            resolve(loaded);
        }, 'search_cars');
    });
}

const CarLoader = connect(state => ({
    ready: !isRatesLoading(state),
    title: gettext('Loading cars, please wait…'),
}))(Loader);

const CarSearch = wrap(loadCarSearch, 'default', CarLoader);
export default CarSearch;

global.loadCarSearch = IS_PROD ? null : loadCarSearch; // eslint-disable-line immutable/no-mutation
