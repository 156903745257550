import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

function isInvoiceOnly(options) {
    return options && options.length === 1 && options[0] === 'invoice';
}

export default class TotalPay extends React.Component {
    static propTypes = {
        paid: PropTypes.bool,
        className: PropTypes.string,
        paidBy: PropTypes.string,
        paidPolicy: PropTypes.oneOf(['D', 'G']),
        total: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        paymentOptions: PropTypes.array,
    };

    render() {
        const {className, paid, paidPolicy, total, currency, paymentOptions} = this.props;

        return (
            <div className={className}>
                <div className={className +'__col'}>
                    <AnnotateTooltip id="due-now">
                        {isInvoiceOnly(paymentOptions)
                            ? gettext('Invoice payment')
                            : (paid ? gettext('Total paid') : gettext('Due now'))
                        }
                        {paidPolicy === 'G'
                            ? gettext('No payment is needed today. You will pay on departure.')
                            : (paid
                                ? gettext('You have paid the full amount.')
                                : gettext('You will be charged the full amount after you complete this booking.'))
                        }
                    </AnnotateTooltip>
                    <div className={className +'__value'}>
                        {annotateCurrency(formatPrice(paidPolicy === 'D' ? total : 0, currency))}
                    </div>
                </div>
                <div className={className +'__col'}>
                    <AnnotateTooltip id="due-on-departure">
                        {gettext('Due on departure')}
                        {paidPolicy === 'G'
                            ? gettext('Payment is due on departure from the hotel.')
                            : gettext('No additional payment is needed on departure.')
                        }
                    </AnnotateTooltip>
                    <div className={className +'__value'}>
                        {annotateCurrency(formatPrice(paidPolicy === 'G' ? total : 0, currency))}
                    </div>
                </div>
            </div>
        );
    }
}

