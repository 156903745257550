import React from 'react';
import gettext from 'airborne/gettext';
import SelectTags from 'midoffice/newforms/widgets/SelectTags';
import {getAllianceChoices} from './helpers';

export default class AirAlliancesField extends React.Component {
    getAllianceChoices = () => {
        const {chosenValues = []} = this.props;
        return getAllianceChoices().filter(([alliance]) => !chosenValues.includes(alliance));
    };

    render() {
        const {chosenValues = []} = this.props;
        return (
            <SelectTags.Field
                label={gettext('Alliances')}
                labelCol={3}
                choices={this.getAllianceChoices()}
                name="airlineAlliances"
                key={chosenValues.join('|')}
            />
        );
    }
}
