import settings from 'airborne/settings';
import React from 'react';
import gettext from 'airborne/gettext';

class ProfileDetails extends React.Component {
    render() {
        const {email,
            'last_name': lastName,
            'first_name': firstName,
            'agency_name': agencyName,
            'company_name': companyName} = settings.USER;

        return (
            <div className="well-details break-word break-word--no-hyphens">
                <h4>
                    {firstName} {lastName}
                </h4>
                <ul className="list-unstyled">
                    <li>
                        {email}
                    </li>
                    {agencyName && <li>
                        <strong>{gettext('Agency Branch:')}</strong> {agencyName}
                    </li>}
                    {companyName && <li>
                        <strong>{gettext('Company:')}</strong> {companyName}
                    </li>}
                </ul>
            </div>

        );
    }
}

export default ProfileDetails;
