import React from 'react';

function wrap(text, key, className, onClick) {
    return <strong key={key} className={className} onClick={onClick}>{text}</strong>;
}

export default function markup(text, className=null, onClick) {
    return text.split('**').map(
        (part, idx)=> idx % 2 ? wrap(part, idx, className, onClick) : part
    );
}

const wrapLink = (text, href, target, dataTestAttr) => (
    <a href={href} target={target} data-test={dataTestAttr} key={href}>{text}</a>
);

const markupWithLink = (text, href, target, dataTestAttr) =>
    text.split('##').map((part, idx) => idx % 2 ? wrapLink(part, href, target, dataTestAttr) : part);

export {
    markupWithLink
};
