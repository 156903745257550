import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {isBookable} from 'airborne/homepage2/helpers/pnr';
import Button from 'midoffice/components/Button';
import {bookRate} from 'airborne/store/modules/search_cars/actions/book';

const mapStateProps = createSelector([
    ({carBooking})=> carBooking.loading || carBooking.checkoutLoading,
    ({carBooking}, props)=> (
        carBooking.rateId === props.rateId &&
        (carBooking.checkoutLoading || carBooking.loading)
    ),
    (state)=> isBookable(state, 0),
], (disabled, progress, show)=> ({disabled, progress, show})
);

@connect(
    mapStateProps,
    (dispatch, {rateId})=> ({
        onClick: ()=> dispatch(bookRate(rateId)),
    }),
    (stateProps, dispatchProps, ownProps)=> ({
        ...stateProps,
        ...dispatchProps,
        ...pick(ownProps, 'className', 'children', 'bsStyle'),
    }),
)
export default class BookButton extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        rateId: PropTypes.string,
    };

    render() {
        const {show, ...props} = this.props;
        if (!show) {
            return null;
        }
        return (<Button {...props} />);
    }
}
