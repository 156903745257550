import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import flow from 'lodash/flow';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';

import SelectAutocomplete from 'midoffice/newforms/widgets/SelectAutocomplete';
import Input from 'midoffice/newforms/widgets/Input';

import MultiPnrSearchResult from './MultiPnrSearchResult';

import pnrSearchSchema from 'airborne/homepage2/PNRSearchSchema';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import {
    companyAutocompleteRecentCapitalized
} from 'airborne/homepage2/autocomplete/CompanyAutocomplete';

import {getPnrErrors, getPnrLoading, getPnrForm, isAddPnr, isMultiPnr, getAllPnrs, getHomepagePnrIndex, isModification} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getStep, getSSOCaseNumber, isPnrReady} from 'airborne/store/modules/homepage/selectors/homepage';
import {retrievePNR, noPNR, formChangePNR, selectCompany, closeAddPnr, discardPnrForm} from '../store/modules/homepage/actions/pnr';
import settings from 'airborne/settings';
import {saveAsCaseNumber} from 'airborne/helpers/asCaseNumber';
import {changeProduct} from 'airborne/store/modules/homepage/actions';
import {getAirDisabledReason, getCarsDisabledReason, getHotelsDisabledReason} from 'airborne/homepage2/Right';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {openProceedWithoutPnr} from 'airborne/store/modules/homepage/actions/tspm';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {isCars} from 'airborne/store/modules/homepage/selectors/product';
import {getCarModificationFlowBooking} from 'midoffice/car-bookings/selectors';

@injectFormContext
class PnrEditor extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        readonly: PropTypes.bool,
        isAddPnr: PropTypes.bool,
        errors: PropTypes.object,
        misconf: PropTypes.bool,
        value: PropTypes.shape({
            company: PropTypes.shape({
                value: PropTypes.number,
            }),
            pnr: PropTypes.string,
        }),
        ssoCaseNumber: PropTypes.string,
        schema: PropTypes.object.isRequired,

        isModal: PropTypes.bool,
        asDesktopEnabled: PropTypes.bool.isRequired,
        carsDisabledReason: PropTypes.string,
        airDisabledReason: PropTypes.string,
        hotelsDisabledReason: PropTypes.string,
        skipTspmModal: PropTypes.bool,

        noPNR: PropTypes.func.isRequired,
        retrievePNR: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onCloseAddPnr: PropTypes.func.isRequired,
        discardPnrForm: PropTypes.func.isRequired,
        changeProduct: PropTypes.func.isRequired,
        openProceedWithoutPnr: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {onChange, discardPnrForm, ssoCaseNumber, value: propsValue, errors} = this.props;
        if (this.shouldResetForm()) {
            discardPnrForm();
        }

        if (ssoCaseNumber) {
            saveAsCaseNumber(ssoCaseNumber);
            onChange({
                value: {
                    ...propsValue,
                    'caseNumber': ssoCaseNumber
                },
                errors
            });
        }
    }

    shortcuts = {
        proceed: SHORTCUTS_LIST.P,
    };

    getActiveTab = () => {
        const {carsDisabledReason, airDisabledReason, hotelsDisabledReason} = this.props;
        if (carsDisabledReason && airDisabledReason && hotelsDisabledReason) {
            return false;
        }

        if (hotelsDisabledReason && !carsDisabledReason) {
            return 'cars';
        }

        if (hotelsDisabledReason && !airDisabledReason) {
            return 'air';
        }
    };

    shouldResetForm = () => this.props.isModal;

    noErrors() {
        const {value, schema} = this.props;
        return flow(
            schema.validate.bind(schema),
            noErrors,
        )(value);
    }

    getErrorMessages() {
        const {errors, misconf} = this.props;
        if (errors && errors.form) {
            return errors.form.map(({pnr, message}, idx, {length}) => ({
                message: (length === 1 || !pnr) ? message : `${pnr}: ${message}`,
                key: pnr || 'misconf',
            }));
        }

        if (misconf) {
            return [{
                key: 'misconf',
                message: gettext('Configuration Error. This client\'s configuration does not permit bookings for travelers without a TSPM traveler profile, and TSPM connection is turned off.'),
            }];
        }
        return [];
    }

    handleKeyDown = (event)=> {
        switch (event.key) {
            case 'Enter':
                event.preventDefault();
                this.handleSearch();
        }
    };

    handleSearch = async ()=> {
        const {
            value: {company, pnr, caseNumber},
            misconf, noPNR, changeProduct,
            openProceedWithoutPnr,
            index,
            skipTspmModal,
        } = this.props;
        const activeTabForChanging = this.getActiveTab();
        if (!company || misconf || !this.noErrors()) {
            return null;
        }
        if (caseNumber) {
            saveAsCaseNumber(caseNumber);
        }
        if (!pnr) {
            noPNR(company);

            if (activeTabForChanging) {
                changeProduct(activeTabForChanging);
            }
        }
        else {
            await this.props.retrievePNR();
        }
        if (!skipTspmModal) {
            openProceedWithoutPnr(pnr);
        }
    };

    renderSubmit() {
        const {
            loading,
            misconf,
        } = this.props;

        const canSearch = Boolean(!misconf) && this.noErrors();

        return (
            <Button bsStyle="secondary"
                disabled={!canSearch || loading}
                progress={loading}
                name="submit"
                data-testid="pnr-search-proceed"
                onClick={this.handleSearch}>
                {gettext('Proceed')}
            </Button>);
    }


    renderError() {
        const messages = this.getErrorMessages();
        if (messages.length === 0) { return null; }

        return messages.map(({key, message}) => <Row key={key}>
            <Col xs={12} className="highlight-red">
                <div className="ag-left-block__offset">{message}</div>
            </Col>
        </Row>);
    }

    renderButtons() {
        const {isAddPnr, onCloseAddPnr} = this.props;

        return (<div className="ag-left-block__offset">
            {isAddPnr && (<Button bsStyle="link"
                name="close"
                onClick={onCloseAddPnr}>
                <Shortcut label={gettext('Cancel')} shortcut={SHORTCUTS_LIST.Q} action={onCloseAddPnr} isModalShortcut />
            </Button>)}
            {this.renderSubmit()}
        </div>);
    }

    renderContent() {
        const {isAddPnr, readonly, isModal, asDesktopEnabled} = this.props;

        return (<div className="ag-home-form" onKeyDown={this.handleKeyDown} >
            <Row>
                <SelectAutocomplete.Field
                    name="company"
                    autocompleteSource={companyAutocompleteRecentCapitalized}
                    autocompleteExtra={settings.USER_LOCALE}
                    id="mainInputOnPage"
                    label={gettext('Company')}
                    labelSuffix=""
                    disabled={isAddPnr || readonly}
                    isAddPnr={isAddPnr}
                    placeholder={gettext('Please select a company to proceed')}
                    inputCol={9}
                    withAgencyInfo
                    clearOnFocus
                />
            </Row>

            {asDesktopEnabled && (
                <Row>
                    <Input.Field name="caseNumber"
                        disabled={readonly}
                        label={gettext('Case #')}
                        labelSuffix=""
                        autoComplete="off"
                        hint={gettext('Agentsource Desktop')}
                        inputCol={9} />
                </Row>
            )}

            <Row>
                <Input.Field name="pnr"
                    disabled={readonly}
                    label={gettext('PNR #')}
                    labelSuffix=""
                    autoComplete="off"
                    inputCol={9} />
            </Row>

            <Row>
                <Col xs={12}>
                    {!isModal && this.renderButtons()}
                </Col>
            </Row>

            {this.renderError()}
        </div>);
    }

    renderModal() {
        const {onCloseAddPnr} = this.props;

        return (<Modal onHide={onCloseAddPnr} backdrop="static" show>
            <Modal.Header closeButton><h4 className="modal-title">{gettext('Add a traveler')}</h4></Modal.Header>
            <Modal.Body>
                <div className="ag-pnr-modal">
                    {this.renderContent()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="ag-pnr-modal__footer">
                    {this.renderButtons()}
                </div>
            </Modal.Footer>
        </Modal>);
    }

    render() {
        const {isAddPnr, isModal} = this.props;

        if (isModal) {
            return this.renderModal();
        }

        return (
            <div>
                <div className="block-title">
                    {isAddPnr
                        ? gettext('Additional PNR. Who\'s Traveling?')
                        : gettext('Who\'s Traveling?')
                    }
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

function stateProps(state, {index}) {
    const pnrErrors = getPnrErrors(state, index);
    const step = getStep(state);
    const carsDisabledReason = getCarsDisabledReason(state);
    const airDisabledReason = getAirDisabledReason(state);
    const hotelsDisabledReason = getHotelsDisabledReason(state);

    return {
        editable: !isPnrReady(state) && step !== 'dest',
        misconf: Boolean(pnrErrors && pnrErrors.misconf),
        canBeMulti: !(isAddPnr(state) && isCars(state)),
        readonly: isModification(state),
        loading: getPnrLoading(state, index),
        errors: pnrErrors,
        value: getPnrForm(state, index),
        isAddPnr: isAddPnr(state),
        isMultiPnr: isMultiPnr(state),
        ssoCaseNumber: getSSOCaseNumber(state),
        carsDisabledReason,
        airDisabledReason,
        hotelsDisabledReason,
    };
}

@connect((state)=> ({
    index: getHomepagePnrIndex(state),
    pnrs: getAllPnrs(state),
    skipTspmModal: getFeatureFlag(state, 'QA_SKIP_TSPM_MODAL'),
    carModifyFlowBooking: getCarModificationFlowBooking(state),
}))
@connect(stateProps, (dispatch, {isModal=false, pnrs, onHide}) => ({
    retrievePNR: ()=> dispatch(retrievePNR()),
    noPNR: (company)=> dispatch(noPNR(company, 0)),
    onChange: ({value, errors})=> dispatch(formChangePNR({value, errors})),
    onCloseAddPnr: ()=> isModal && pnrs.length === 0 ? onHide() : dispatch(closeAddPnr(isModal)),
    selectCompany: (company)=> dispatch(selectCompany(company)),
    discardPnrForm: ()=> dispatch(discardPnrForm()),
    changeProduct: (product)=> dispatch(changeProduct(product)),
    openProceedWithoutPnr: (pnrId) => dispatch(openProceedWithoutPnr(pnrId)),
}))
export default class PNRSearch extends React.Component {
    static propTypes = {
        isModal: PropTypes.bool,
        isMultiPnr: PropTypes.bool.isRequired,
        editable: PropTypes.bool.isRequired,
        pnrs: PropTypes.arrayOf(PropTypes.string).isRequired,
        value: PropTypes.object,
        ssoCaseNumber: PropTypes.string,
        isAddPnr: PropTypes.bool.isRequired,
        canBeMulti: PropTypes.bool.isRequired,
        misconf: PropTypes.bool,
        errors: PropTypes.object,
        onHide: PropTypes.func,
        skipTspmModal: PropTypes.bool,
        readonly: PropTypes.bool,

        onChange: PropTypes.func.isRequired,
        onCloseAddPnr: PropTypes.func.isRequired,
        selectCompany: PropTypes.func.isRequired,
        discardPnrForm: PropTypes.func.isRequired,
        openProceedWithoutPnr: PropTypes.func.isRequired,
        carModifyFlowBooking: PropTypes.object,
    };

    static defaultProps = {
        isModal: false,
    };

    handleCompanyChange = ({value, errors})=> {
        if (value.company && this.props.value.company !== value.company) {
            this.props.selectCompany(value.company);
        }
        return this.props.onChange({value, errors});
    };

    handleCloseCompanyChange = ({value, errors})=> {
        this.props.selectCompany(value.company);
        return this.props.onChange({value, errors});
    };

    render() {
        const {editable, isAddPnr, pnrs, isModal, onHide, value, errors, canBeMulti, carModifyFlowBooking, readonly} = this.props;
        const asDesktopEnabled = Boolean(settings['AS_DESKTOP_ENABLED']) && !isModal;
        if (editable || isAddPnr) {
            const schema = pnrSearchSchema({isAddPnr, pnrs, asDesktopEnabled, canBeMulti});
            return (
                <PnrEditor
                    {...this.props}
                    isModal={isModal}
                    schema={schema}
                    onChange={this.handleCompanyChange}
                    asDesktopEnabled={asDesktopEnabled}
                    readonly={carModifyFlowBooking || readonly}
                />
            );
        }

        return <MultiPnrSearchResult isModal={isModal} onHide={onHide} onCompanyChange={this.handleCloseCompanyChange} value={value} errors={errors} />;
    }
}
