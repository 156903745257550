'process i18n';
import React, {Fragment} from 'react';
import TabsWithContent, {Content, MenuItem, TabsMenu} from 'midoffice/components/TabsWithContent';
import gettext from 'airborne/gettext';
import RateInfoTab from 'midoffice/components/hotelRateDetails/RateInfoTab';
import AgentInfoTab from 'midoffice/components/hotelRateDetails/AgentInfoTab';
import BookingInfoTab from 'midoffice/components/hotelRateDetails/BookingInfoTab';

export default class NewDetails extends React.Component {
    render() {
        return (<Fragment>
            <TabsWithContent
                className="modal-body__tabs-list"
                activeClass="modal-body__tabs-list--active"
                noDefaultClasses
                isList>
                <TabsMenu className="modal-body__tabs">
                    <MenuItem>{gettext('Rate Information')}</MenuItem>
                    <MenuItem>{gettext('Booking requirements')}</MenuItem>
                    <MenuItem>{gettext('Agent Information')}</MenuItem>
                </TabsMenu>
                <Content className="modal-body__tabs-content">
                    <RateInfoTab {...this.props} type="wrapper"/>
                    <BookingInfoTab {...this.props} type="freetext"/>
                    <AgentInfoTab {...this.props}/>
                </Content>
            </TabsWithContent>
        </Fragment>);
    }
}
