import escape from 'lodash/escape';
import {searchForm} from './search';
import {getPrevBookingId} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

function serialiseDestination(value) {
    const [type, code] = value.split(':', 2);
    if (type === 'Hotel') {
        return {
            'hotel': code,
            'is_specific_hotel_search': 1,
        };
    }

    return {destination: escape(code)};
}

function serialiseChains(chains) {
    if (!chains || !chains.length) {
        return {};
    }

    return {chains: chains.join(',')};
}

function serialiseHotelName(hotel) {
    return hotel ? {'hotel_name': hotel} : {};
}

export function serialiseRates(rates) {
    return rates ? {'special_rates': rates} : {};
};

export function serializeSearchParams(dest, sid, order, taskId) {
    if (!dest) {
        return {};
    }
    const {
        destination,
        dates,
        chains,
        guests: room1,
        'hotel_name': hotel,
        ...rest
    } = dest;
    return {
        'destination.name': destination.label,
        'checkin': dates.min,
        'checkout': dates.max,
        ...serialiseHotelName(hotel),
        ...serialiseChains(chains),
        ...serialiseDestination(destination.value),
        ...rest,
        room1,
        sid, order, 'task_id': taskId,
    };
};

export function serializeSearchMode(state) {
    const previousBookingId = getPrevBookingId(state, 0);

    return {
        modification: Boolean(previousBookingId),
    };
}
