import React from 'react';
import {gettext} from 'airborne/gettext';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'react-bootstrap/Button';

const OFFSET_TABLE = {
    top: [0, -20],
    bottom: [0, 20],
    left: [-20, 0],
    right: [20, 0],
};

export default class HighlightTooltip extends React.Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.string),
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        positionLeft: PropTypes.number,
        positionTop: PropTypes.number,
        onHide: PropTypes.func.isRequired,
    };

    static defaultProps = {
        placement: 'left',
    };

    render() {
        const {children: [title, ...messages], positionLeft, positionTop, onHide, placement} = this.props;
        const [offsetLeft, offsetTop] = OFFSET_TABLE[placement];
        const style = {
            left: positionLeft + offsetLeft,
            top: positionTop + offsetTop,
        };
        const cls = classnames('feature-tooltip', {
            [`feature-tooltip--${placement}`]: placement,
        });
        return (<div className={cls} style={style} >
            <h2 className="feature-tooltip__title">
                {title}
            </h2>
            {messages.map((text, idx)=>
                <p key={idx} className="feature-tooltip__text">{text}</p>
            )}
            <footer className="feature-tooltip__footer">
                <Button variant="link" onClick={onHide}>
                    {gettext('Got it!')}
                </Button>
            </footer>
        </div>);
    }
}
