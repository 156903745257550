import {displayBedTypes} from 'airborne/search2/helpers/rates';
import gettext from 'airborne/gettext';
import {formatStrict as formatPriceStrict, format as formatPrice} from 'airborne/utils/price';
import formatAddress from 'airborne/search2/helpers/address';
import {getExplanationText} from 'airborne/homepage2/helpers/acriss';
import {getMiliageText, getCarLocationText} from 'airborne/search_cars/helpers/distance';
import normalize from 'airborne/search2/helpers/normalize';
import isEqual from 'lodash/isEqual';
import amenityFormat from 'airborne/search2/amenities';
import {getRatePlanI18N} from 'airborne/search_cars/types';

const formatAveragePerNight = ({currency, average}) => {
    const [formatCurrency, formatAverage] = formatPrice(average, currency).split(' ');
    return `${formatCurrency} ${formatAverage}`;
};

const formatAmenities = (amenitiesCodes) =>
    amenitiesCodes.map(code => amenityFormat(code)).join(', ') || gettext('Not Included');

export const parseHotelRate = (hotel, rate) => {
    const {rateValueAdds, bedTypes, total, currency, cancellationPolicy} = rate;
    const {address, city, stateProvinceCode, postalCode, name} = hotel;

    return name + '\n' +
        `${formatAddress(address, city, stateProvinceCode, postalCode)}` + '\n' +
        `${gettext('Amenities:')} ${formatAmenities(rateValueAdds)}` + '\n' +
        `${gettext('Bed Type')}: ${displayBedTypes(bedTypes)}` + '\n' +
        `${gettext('Avg. / Night')}: ${formatAveragePerNight(rate)}` + '\n' +
        `${gettext('Estimated Total')}: ${formatPriceStrict(total, currency)}` +
        `\n${cancellationPolicy || ''}`;
};

const parseCarRateLocation = (pickup, dropoff) => isEqual(pickup, dropoff)
    ? `${gettext('Pick-up/Drop-off Location')}
${getCarLocationText(pickup)}`

    : `${gettext('Pick-up Location')}
${getCarLocationText(pickup)}

${gettext('Drop-off Location')}
${getCarLocationText(dropoff)}`;


export const parseCarRate = (rate) => {
    const {car, vendor, ratePlan, pickup, dropoff} = rate;
    const modelHeader = car.model ? `${gettext('Model')}: ${car.model} ${gettext('or similar')}\n`: '';
    const {amount, type} = ratePlan;
    const ratePlanPrice = formatPrice(amount, rate?.currency);

    return modelHeader + `${gettext('Total Price')}: ${formatPrice(rate?.totalAfterTaxes || rate?.total, rate?.currency)}
${gettext('Rate Plan')}: ${ratePlanPrice} / ${getRatePlanI18N(ratePlan).type}
${gettext('Supplied By')} ${vendor.name} (${vendor.code})
${gettext('ACRISS Code')}: ${car.type} - ${getExplanationText(car.type)}

${parseCarRateLocation(pickup, dropoff)}

${getMiliageText(ratePlan.distanceIncluded).replace(/\*/g, '')}`;
};


export const copyToClipboard = (text) => {
    const dummyTextarea = document.createElement('textarea');

    // Place in the top-left corner of screen regardless of scroll position.
    dummyTextarea.style.position = 'fixed';
    dummyTextarea.style.top = 0;
    dummyTextarea.style.left = 0;

    dummyTextarea.style.width = '2em';
    dummyTextarea.style.height = '2em';

    // Avoid flash of the white box if rendered for any reason.
    dummyTextarea.style.background = 'transparent';


    document.body.appendChild(dummyTextarea);

    dummyTextarea.value = text;
    dummyTextarea.select();
    document?.execCommand('copy');
    document.body.removeChild(dummyTextarea);
};

export const copyHotelOfferInfo = (hotel, rate) => {
    copyToClipboard(parseHotelRate(normalize(hotel), normalize(rate)));
};

export const copyHotelOffers = (offers) => {
    let offersText = '';
    offers.map(({hotel, rate}) => {
        offersText += parseHotelRate(hotel, rate) + '\n\n';
    });

    copyToClipboard(offersText);
};

export const copyCarOffer = rate => copyToClipboard(
    parseCarRate(normalize(rate))
);
