import React from 'react';
import PropTypes from 'prop-types';
import settings from 'airborne/settings';

import {connect} from 'react-redux';
import {getLang} from 'airborne/store/modules/header/selectors/i18n';
import classnames from 'classnames';

function mapStateProps(state) {
    const isAft = settings['SUB_PARTNER'] === 'aft';
    return {
        classNames: classnames(`lang-${getLang(state)}`, {
            'ag-branded': isAft,
        }) ,
    };
}

function toggle(element,classNames, flag) {
    classNames.split(' ').forEach((className) => element.classList[flag ? 'add' : 'remove'](className));
}

@connect(mapStateProps)
export default class BodyClassName extends React.Component {
    static propTypes = {
        classNames: PropTypes.string,
    };

    componentDidMount() {
        const {classNames} = this.props;
        toggle(document.body,classNames, true);
    }

    componentWillUnmount() {
        const {classNames} = this.props;
        toggle(document.body, classNames,false);
    }

    render() {
        return null;
    }
}
