import {FIELDS, FARE_OPTIONS} from '../types';
import {dropEmpty} from 'midoffice/helpers/urlParams';

const getFlightOptionKeys = (flightOptions) =>
    flightOptions.map(({flightOptionKey}) => flightOptionKey);

const getSegmentOptions = (formValue, segments) => {
    const classesOfService = formValue[FIELDS.services];

    return segments.map(({segmentIdRef}) => ({
        'segment_id_ref': Number(segmentIdRef),
        'class_of_service': classesOfService[Number(segmentIdRef)]
    }));
};

const isBestPricing = (formValue) => {
    return formValue[FIELDS.ignoreClassOfService];
};

const isRefundableOnly = (formValue) => {
    const fareOptions = formValue[FIELDS.fareOptions];

    return fareOptions?.includes(FARE_OPTIONS.refundable);
};

const isChangeableOnly = (formValue) => {
    const fareOptions = formValue[FIELDS.fareOptions];

    return fareOptions?.includes(FARE_OPTIONS.changeable);
};

const isNoPenalties = (formValue) => {
    const fareOptions = formValue[FIELDS.fareOptions];

    return fareOptions?.includes(FARE_OPTIONS.excludePenalties);
};

const isIncludedBaggage = (formValue) => {
    const fareOptions = formValue[FIELDS.fareOptions];

    return fareOptions?.includes(FARE_OPTIONS.baggageIncluded);
};

const serializePricingRequest = ({
    flightOptions,
    formValue,
    profile,
    currency,
    exchangeKey,
    isManualExchange,
    configurationId,
    segments,
}) => {
    const cabin = formValue[FIELDS.departureCabin];

    return dropEmpty({
        'currency': currency || 'EUR',
        'configuration_id': configurationId || profile.company,
        'flight_option_keys': getFlightOptionKeys(flightOptions),
        'segment_options': getSegmentOptions(formValue, segments),

        'price_class_code': formValue[FIELDS.fareFamilyCode],
        'cabin': cabin === 'any' ? null : cabin,

        'refundable_only': isRefundableOnly(formValue),
        'changeable_only': isChangeableOnly(formValue),
        'exclude_penalties': isNoPenalties(formValue),
        'included_baggage': isIncludedBaggage(formValue),
        'best_pricing': isBestPricing(formValue),
        'exchange_key': exchangeKey,
        'is_manual_exchange': isManualExchange,

        // We always need multiple fares for this request
        'alternate_fares': true,

        // Hardcoded traveler info
        'travelers': [{
            'traveler_type': 'adult'
        }]
    });
};

export default serializePricingRequest;
