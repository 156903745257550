'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/Modal';
import Button from 'midoffice/components/Button';
import UpdatePaymentInfoEditor from './UpdatePaymentInfoEditor';
import CCSelector from 'airborne/checkout2/payment/CCSelector';

import {noErrors} from 'midoffice/newforms/helpers';
import {getCardType, getAcceptedCards} from 'airborne/checkout2/helpers/cardValidators';
import createPaymentSchema from './UpdatePaymentInfoSchema';

import {formatStrict as formatPrice} from 'airborne/utils/price';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';

import {
    patchUPaymentForm,
    makeUPaymentRequest,
    hideUPaymentModal,
} from 'airborne/store/modules/update_payment/actions';
import {getUpdatePaymentState} from 'airborne/store/modules/update_payment/selectors';
import {getBooking} from 'midoffice/bookings/selectors';


function mapStateToProps(state, props) {
    const booking = getBooking(state, props);
    const {
        'rate': {
            'payment_cards_accepted': ccApiCodes,
        },
        'id': bookingUID,
    } = booking;

    const {
        value,
        errors,
        loading,
        message,
    } = getUpdatePaymentState(state);
    const {'credit_card_number': ccNumber} = value;

    const cardType = getCardType(ccNumber);
    const activeCard = cardType ? cardType.value : '';

    const schema = createPaymentSchema(ccApiCodes, cardType);
    const paymentCardsAccepted = getAcceptedCards(ccApiCodes);

    return {
        paymentCardsAccepted,
        activeCard,
        schema,
        value,
        errors,
        loading,
        bookingUID,
        booking,
        message,
    };
}


@connect(mapStateToProps, {
    onChange: patchUPaymentForm,
    updateBooking: makeUPaymentRequest,
    onHide: hideUPaymentModal,
})
export default class  extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        updateBooking: PropTypes.func.isRequired,
        schema: PropTypes.object.isRequired,
        value: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        bookingUID: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        booking: PropTypes.object.isRequired,
        message: PropTypes.arrayOf(PropTypes.string),
        paymentCardsAccepted: PropTypes.array.isRequired,
        activeCard: PropTypes.string.isRequired,
    };

    handleUpdatePayment = () => {
        const {schema, value, onChange, bookingUID, updateBooking} = this.props;
        const errors = schema.validate(value, {strict: true});
        if (noErrors(errors)) {
            updateBooking(bookingUID);
        }
        else {
            onChange({value, errors});
        }
    }

    renderExistingPaymentInfo() {
        const {
            'payment_detail': existingCreditCard,
            'hotel': {
                'name': hotelName,
            },
            'rate' : {
                total,
                currency,
            },
            'number': pnrNumber,
        } = this.props.booking;

        const infoList /** [label, value] **/ = [
            [gettext('PNR:'), pnrNumber],
            [gettext('Hotel:'), hotelName],
            [gettext('Est. Total:'), annotateCurrency(formatPrice(total, currency))],
            [gettext('Existing Credit Card:'), existingCreditCard],
        ];

        return (
            <>
                <h4>{gettext('Update Payment information:')}</h4>
                <div className="row-wrapper row-wrapper--condensed">
                    <div className="well well-sm">
                        {infoList.map(([label, value]) => (
                            <div key={label}>
                                <strong>{label}</strong> {value}
                            </div>
                        ))}
                    </div>
                </div>

                <h5 className="form-secondary-title">{gettext('New Credit Card:')}</h5>
                <hr />
            </>
        );
    }

    renderCCSelector() {
        const {paymentCardsAccepted, activeCard} = this.props;

        return (
            <CCSelector accepted={paymentCardsAccepted} active={activeCard} />
        );
    }

    renderErrorMessage(message) {
        return (
            <div className="alert alert-danger">
                {message}
            </div>
        );
    }

    render() {
        const {
            loading,
            onChange,
            value,
            errors,
            schema,
            onHide,
            message,
        } = this.props;

        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Modify Reservation')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.renderExistingPaymentInfo()}
                    {message && this.renderErrorMessage(message)}
                    {this.renderCCSelector()}
                    <UpdatePaymentInfoEditor
                        schema={schema}
                        value={value}
                        errors={errors}
                        onChange={onChange}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" onClick={onHide}>{gettext('Close')}</Button>
                    <Button
                        progress={loading}
                        disabled={loading}
                        bsStyle="success"
                        onClick={this.handleUpdatePayment}
                    >
                        {gettext('Update Payment Information')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
