import React from 'react';
import Left from 'airborne/homepage2/PNRSearch';
import Right from 'airborne/homepage2/Right';
import ExpirationWarning from 'airborne/homepage2/components/ExpirationWarning';

export default class AgentHomepage extends React.Component {
    render() {
        return (<div>
            <div className="ag-homepage-bg ag-homepage-bg--solid" />
            <ExpirationWarning containerStyles={{marginBottom: '-15px'}} />

            <div className="ag-agent-wrapper clearfix">
                <div className="ag-left-block js-left-block">
                    <Left />
                </div>
                <div className="ag-right-block js-right-block">
                    <Right />
                </div>
                <div className="ag-right-block-bg" />
            </div>
        </div>);
    }
}
