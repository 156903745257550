/* eslint-disable immutable/no-mutation */
import uniq from 'lodash/uniq';
import extend from 'lodash/extend';
import forms from 'midoffice/newforms';
import {ValidationError} from 'midoffice/newforms/exceptions';
import Autocomplete from 'midoffice/newforms/widgets/Autocomplete';
import Tags from 'midoffice/newforms/widgets/Tags';
import api from 'midoffice/helpers/api';


class OIDValidator {

    code = 'OID';
    message = 'Select value from autocomplete';

    constructor(autocomplete) {
        this.autocomplete = autocomplete;
    }

    validate(value) {
        if (value.value) { value = value.value; }
        if (!this.autocomplete.isItem(value)) {
            throw new ValidationError(this.message, this.code);
        }
    }
}


export class OIDAutocomplete {

    ignoreSelected(selectedValues, identifiers) {
        if (!selectedValues) {
            return identifiers;
        }
        return identifiers &&
          identifiers.filter(el => selectedValues &&
            !selectedValues.includes(el.unique_identifier));
    }

    query(query, {silent, ...extra}) {
        let {selectedValues} = extra;
        let requestParams = {search: query};
        if (extra && extra.gds) {
            requestParams = {...requestParams, provider: extra.gds};
        }

        if (extra && extra['valid_for_office']) {
            requestParams = {
                ...requestParams,
                'valid_for_office': extra['valid_for_office']
            };
        }

        return new Promise((resolve, reject)=> {
            return api('GET', '/api/gds-profiles-autocomplete/', {
                data: requestParams,
                id: 'gds_profile_autocomplete',
            }).then(
                (dataRaw)=> {
                    let data = this.ignoreSelected(selectedValues, dataRaw);
                    if (data && data.length > 0) {
                        this.saveResponse(data);
                        resolve(this.parseResponse(data));
                    }
                    else if (silent && data && data.length === 0) {
                        resolve(null);
                    }
                    else {
                        reject('No credentials found');
                    }
                },
                ({request})=> {
                    if (request.isAborted) {
                        resolve(null);
                    }
                    else {
                        reject('Server error');
                    }
                }
            );
        });
    }

    parseResponse(data) {
        return [{
            items: data.map((el)=> {
                return {
                    label: el.unique_identifier,
                    value: el.unique_identifier,
                    payload: el.agency_type,
                };
            })
        }];
    }

    saveResponse(data) {
        data = data.map((el)=> el.unique_identifier);
        this.items = uniq([...data, ...(this.items || [])]);
    }

    isItem(value) {
        if (!this.items || !value) { return true; }
        return this.items.includes(value);
    }
}

export class GdsProfileSelectionField extends forms.Field {

    constructor(options={}) {
        const autocomplete = new OIDAutocomplete();
        const {validators=[], ...rest} = options;
        super({
            widget: Autocomplete,
            validators: [new OIDValidator(autocomplete), ...validators],
            ...rest
        });
        this.autocompleteSource = autocomplete;
    }

    getDefaultMessages() {
        return extend(super.getDefaultMessages(), {
            'invalid': 'This field may not be blank.',
        });
    }

    validateEmptyValue(value) {
        const validated = super.validateEmptyValue(value);

        if (validated === '') {
            if (this.required) {
                this.fail('required');
            }
            if (!this.allowEmpty) {
                this.fail('invalid');
            }
        }

        return validated;
    }
}

export class GdsProfileListField extends forms.ArrayField {
    constructor(props) {
        super({...props, child: new forms.Field(props)});
        this.autocompleteSource = new OIDAutocomplete();
    }
}

export default class OIDPCC extends forms.Schema {
    fields = {
        'amadeus_pcc': new GdsProfileSelectionField({
            label: 'Amadeus Primary OID',
            required: false,
            allowEmpty: true,
            props: {
                commitText: true
            },
        }),
        'amadeus_pcc_extra': new GdsProfileListField({
            label: 'Amadeus Extra OIDs',
            allowEmpty: true,
            widget: Tags,
            props: {
                plain: true,
            },
            required: false,
        }),
        'sabre_pcc': new GdsProfileSelectionField({
            required: false,
            label: 'Sabre PCC',
            allowEmpty: true,
            props: {
                commitText: true
            },
        }),
        'sabre_pcc_extra': new GdsProfileListField({
            label: 'Sabre Extra PCCs',
            allowEmpty: true,
            widget: Tags,
            props: {
                plain: true,
            },
            required: false,
        }),
        'apollo_pcc': new GdsProfileSelectionField({
            required: false,
            label: 'Apollo PCC',
            allowEmpty: true,
            props: {
                commitText: true
            },
        }),
        'apollo_pcc_extra': new GdsProfileListField({
            label: 'Apollo Extra PCCs',
            allowEmpty: true,
            widget: Tags,
            props: {
                plain: true,
            },
            required: false,
        }),
        'galileo_pcc': new GdsProfileSelectionField({
            required: false,
            label: (() => {
                if (window.location.pathname.includes('allusers')) {
                    return 'Travelport+ PCC';
                }
                return 'Galileo PCC';
            })(),
            allowEmpty: true,
            props: {
                commitText: true
            },
        }),
        'galileo_pcc_extra': new GdsProfileListField({
            label: (() => {
                if (window.location.pathname.includes('allusers')) {
                    return 'Travelport+ Extra PCCs';
                }
                return 'Galileo Extra PCCs';
            })(),
            allowEmpty: true,
            widget: Tags,
            props: {
                plain: true,
            },
            required: false,
        }),
    }
}
