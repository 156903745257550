import partial from 'lodash/partial';
import {
    serverPaginateCollection,
    serverSearchCollection,
    changeForm,
} from 'midoffice/data/actions/serverFiltered';
import {paginateCollection} from 'midoffice/data/actions/common';
import './types';

const endpoint = 'externalBookings';
const filtersEndpoint = 'externalBookingFilters';

export const defaultPaginateBookings = partial(paginateCollection, endpoint);
export const paginateBookings = partial(serverPaginateCollection, endpoint, filtersEndpoint);
export const searchBookings = partial(serverSearchCollection, endpoint, filtersEndpoint);
export const changeFiltersForm = partial(changeForm, filtersEndpoint);
