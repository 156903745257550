import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Glyphicons from 'midoffice/components/Glyphicons';
import gettext from 'airborne/gettext';

export class InfoBox extends React.Component {
    static propTypes = {
        append: PropTypes.string,
        children: PropTypes.any,
        className: PropTypes.string,
        glyph: PropTypes.string,
    };

    static defaultProps = {
        className: 'alert-info',
        glyph: 'info-sign',
    };

    render() {
        const {className, glyph, children, append} = this.props;
        const cls = classnames('alert', className);

        return (<div className={cls}>
            <Glyphicons bsClass="glyphicon" glyph={glyph} />
            <strong>{children}</strong>
            <p className="alert__message">{append}</p>
        </div>);
    }
}


export class ChangeCompany extends React.Component {
    static propTypes = {
        'company_name': PropTypes.string,
        'sameCompany': PropTypes.bool,
        'pnr': PropTypes.string,
        'preview': PropTypes.bool.isRequired,
    };

    render() {
        const {
            pnr,
            sameCompany,
            'company_name': name,
            preview,
        } = this.props;
        if (sameCompany) {
            return null;
        }

        return preview
            ? (<div className="ag-block-list__alert">
                {gettext('The entity you selected was updated accordingly.')}
            </div>)
            : (<InfoBox append={gettext('The entity you selected was updated accordingly.')} >
                {gettext('ASB PNR {pnr} was previously used to make a booking for {name}.', {pnr, name})}
            </InfoBox>);
    }
}

export class NoProductWarning extends React.Component {
    static propTypes = {
        isAllTabsDisabled: PropTypes.bool,
    }
    render() {
        if (!this.props.isAllTabsDisabled) {
            return null;
        }

        return (<InfoBox className="alert-danger" glyph="exclamation-sign">
            {gettext('Searching for this company is not allowed.')}
        </InfoBox>);
    }
}

export class TspmMismatchWarning extends React.Component {
    static propTypes = {
        preview: PropTypes.bool,
        'traveler_profile_mismatch':  PropTypes.bool,
        'company_profile_mismatch':  PropTypes.bool,
        onEdit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        'traveler_profile_mismatch': false,
        'company_profile_mismatch': false,
    }

    handleClick = e=> {
        e.preventDefault();
        this.props.onEdit();
    }

    render() {
        const {
            preview,
            'traveler_profile_mismatch': travelerMismatch,
            'company_profile_mismatch': companyMismatch,
        } = this.props;

        if (!travelerMismatch && !companyMismatch) {
            return null;
        }

        return preview
            ? (<span className="highlight-red">
                {gettext('TSPM data mismatch.')}<br/> {gettext('Please ')}<a href="#" onClick={this.handleClick}>{gettext('click here')}</a> {gettext(' to review TSPM data.')}
            </span>)
            : (<InfoBox className="alert-warning" glyph="exclamation-sign">
                {gettext('Warning! TSPM Data mismatch.')}
            </InfoBox>);
    }
}

export class TspmEmailWarning extends React.Component {
    static propTypes = {
        'tspm_email': PropTypes.string,
        'preview': PropTypes.bool,
        'tspm_traveler_id': PropTypes.string,
    };

    render() {
        const {
            preview,
            'tspm_traveler_id': tspmId,
            'tspm_email': tspmEmail,
        } = this.props;

        const showNoEmailError = !!tspmId && !tspmEmail;

        if (!showNoEmailError) {
            return null;
        }

        return preview ? (
            <span className="highlight-red">
                {gettext(
                    'Warning! TSPM traveler profile includes invalid email address.'
                )}
            </span>
        ) : (
            <InfoBox glyph="exclamation-sign" className="alert-warning">
                {gettext(
                    'Warning! TSPM traveler profile includes invalid email address.'
                )}
            </InfoBox>
        );
    }
}


export class ExpiredTspmProfilesWarning extends React.Component {
    static propTypes = {
        'preview': PropTypes.bool,
        'expired_profiles_found': PropTypes.bool,
    };

    render() {
        const {
            preview,
            'expired_profiles_found': expiredProfilesFound,
        } = this.props;

        if (!expiredProfilesFound) {
            return null;
        }

        return preview ? (
            <span className="highlight-red">
                {gettext(
                    'Expired TSPM traveler profiles were excluded.'
                )}
            </span>
        ) : (
            <InfoBox glyph="exclamation-sign" className="alert-warning">
                {gettext(
                    'Expired TSPM traveler profiles were excluded.'
                )}
            </InfoBox>
        );
    }
}


export class AnotherPNRCompany extends React.Component {
    static propTypes = {
        'is_company_original': PropTypes.bool,
        preview: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        'is_company_original': true,
    };

    render() {
        const {'is_company_original': isCompanyOriginal, preview} = this.props;
        if (isCompanyOriginal) {
            return null;
        }

        return preview
            ? (<div className="ag-block-list__alert">
                {gettext('The PNR and Company selected do not match.')}
            </div>)
            : (<InfoBox className="alert-danger" append={gettext('You may continue. The preferred rates and travel policy of the selected company will be used for searches and booking.')} >
                {gettext('The PNR and Company selected do not match.')}
            </InfoBox>);
    }
}

export class GuestWarning extends React.Component {
    static propTypes = {
        preview: PropTypes.bool.isRequired,
        'guestEnabled': PropTypes.bool,
        'tspm_traveler_id': PropTypes.string,
    };

    render() {
        const {guestEnabled, 'tspm_traveler_id': tspmId, preview} = this.props;

        if (guestEnabled || tspmId) {
            return null;
        }

        return preview
            ? (<div className="ag-block-list__alert">
                {gettext('Guest bookings are not allowed. Please select a TSPM profile.')}
            </div>)
            : (<InfoBox>
                {gettext('Guest bookings are not allowed. Please select a TSPM profile.')}
            </InfoBox>);
    }
}


export class NoPNRWarning extends React.Component {
    static propTypes = {
        noPnrEnabled: PropTypes.bool.isRequired,
        pnr: PropTypes.string,
        product: PropTypes.oneOf(['hotels', 'cars', 'air', 'rail']),
    };

    render() {
        const {noPnrEnabled, pnr, product} = this.props;
        const isRail = product === 'rail';
        if (noPnrEnabled || pnr || isRail) { return null; }

        return (<InfoBox>
            {gettext('A valid PNR will be required to complete a booking during this session.')}
        </InfoBox>);
    }
}

export class UnticketedAirSegment extends React.Component {
    static propTypes = {
        'unticketed_air_segments_exist': PropTypes.bool,
        pnr: PropTypes.string
    };

    render() {
        const {'unticketed_air_segments_exist': unticketedAirSegmentError, pnr} = this.props;
        if (!unticketedAirSegmentError || !pnr) return null;

        return (
            <div className="ag-row">
                <div className="alert alert-warning">
                    <span className="glyphicon glyphicon-info-sign" />
                    <strong>{gettext('The retrieved PNR contains unticketed air segments.')}</strong>
                    <p className="alert__message">
                        {gettext('Please be aware that adding a hotel to this PNR could result in a reprice of the air portion, and the previously quoted price may change.')}
                        <br/>
                        <strong>{gettext('Please verify the stored fare prior to submitting to Compleat.')}</strong>
                    </p>
                </div>
            </div>
        );
    }
}
