import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';


export default class CurrencyDisclaimer extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        total: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        originalCurrency: PropTypes.string.isRequired,
        product: PropTypes.string.isRequired,
    };

    render() {
        const {className, total, currency, originalCurrency, product} = this.props;
        if (currency === originalCurrency) {
            return null;
        }
        const gettextParams = {
            originalCurrency,
            amount: formatPrice(total, currency)
        };

        const text = {
            hotels: gettext('You will be charged in {originalCurrency} – the hotel\'s (or supplier\'s) currency. The displayed amount ( {amount} ) is approximate and based on the exchange rate at the time of booking.', gettextParams),
            cars: gettext('You will be charged in {originalCurrency} – the vendor location\'s currency. The displayed amount ( {amount} ) is approximate and based on the exchange rate at the time of booking.', gettextParams),
        }[product];

        return (
            <p className={className}>
                {annotateCurrency(text)}
            </p>
        );
    }
}

