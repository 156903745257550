import zip from 'lodash/zip';
import chunk from 'lodash/chunk';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Glyphicons from 'midoffice/components/Glyphicons';
import omit from 'lodash/omit';
import {injectField} from 'midoffice/newforms/decorators';

const NULL = 'null';

const NON_DOM_ATTRIBUTES = [
    'isRequired',
    'emptyMessage',
    'missingChoiceMessage',
    'showErrorMessage',
    'schemaParams',
    'asDiv',
    'asTable',
    'control',
    'inputCol',
    'labelClassName',
    'labelComponent',
    'labelCol',
    'labelSuffix',
    'grid',
    'inline',
];

class RadioOption extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        label: PropTypes.any,
        help: PropTypes.any,
        value: PropTypes.any.isRequired,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        checked: PropTypes.bool,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,

        children: PropTypes.node,
    };

    static defaultProps = {
        className: 'radio',
        value: [],
        columns: 1,
        asTable: true,
        asDiv: false,
        disabled: false,
        readOnly: false
    };

    state = {
        id: `id-${this.props.name}-${uniqueId()}`
    };

    handleChange = ({target: {value}})=> {
        if (value === NULL) { value = null; }
        if (value === 'true') { value = true; }
        if (value === 'false') { value = false; }

        this.props.onChange(value);
    };

    render () {
        let {children, label, help, className, ...otherProps} = this.props;
        let {id} = this.state;

        return (
            <label className={classnames(className, {'control--disabled': otherProps.disabled})} htmlFor={id}>
                <input {...omit(otherProps, NON_DOM_ATTRIBUTES)}
                    onChange={this.handleChange}
                    type="radio"
                    id={id}/>
                <span className="radio__icon"/>
                &nbsp;{label}
                &nbsp;{help}
                {children}
            </label>
        );
    }
}


class HelpText extends React.Component {

    static propTypes = {
        text: PropTypes.string
    };

    render () {
        let text = this.props.text;
        if (text) {
            const tooltip = (
                <Tooltip id="help text" className="tooltip-modal">{text}</Tooltip>
            );

            return (
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <Glyphicons bsClass="glyphicon" glyph="info-sign" />
                </OverlayTrigger>
            );
        }
        return null;
    }
}


@injectField
export default class Radios extends React.Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        value: PropTypes.any,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        choices: PropTypes.array.isRequired,
        columns: PropTypes.number,
        asTable: PropTypes.bool,
        asDiv: PropTypes.bool,
        inline: PropTypes.bool,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        testIdPrefix: PropTypes.string,
    };

    static defaultProps = {
        className: 'radio-select',
        value: [],
        columns: 1,
        asTable: true,
        asDiv: false,
        inline: false,
        disabled: false,
        readOnly: false
    };

    handleSubClick = ({target: {value, checked}})=> {
        const [choiceValue, , , suboption={}] = this.props.choices[value];

        this.props.onChange(checked ? suboption.value : choiceValue, this.props.name);
    };

    handleChange = (value)=> {
        this.props.onChange(this.props.choices[value][0], this.props.name);
    };

    renderSuboption({value, helpText, label}, index) {
        const checked = this.props.value === value;
        const help = <HelpText text={helpText}/>;

        return (<label className="checkbox checkbox-rev checkbox__option clearfix">
            <input type="checkbox"
                {...omit(this.props, NON_DOM_ATTRIBUTES)}
                value={index}
                checked={checked}
                onChange={this.handleSubClick} />
            <span className="checkbox__icon"/>
                    &nbsp;{label}
                    &nbsp;{help}
        </label>);
    }

    /*
     * Render option from *this.props.choices*
     *
     * @param option: choices' option
     * @param index: choices' index
     */
    renderRadio([value, label, helpText, suboption={}], index) {
        let {asTable, asDiv, className, inline, testIds, testIdPrefix} = this.props;
        asTable = asTable && !asDiv;

        let checked = (
            (this.props.value === value)
            || (suboption.value === this.props.value)
        );

        let cls = classnames({
            [`${className}__option`]: !inline,
            'radio-inline': true,
            'radio radio-rev': true,
        });

        value = value === null ? NULL : value;

        const help = <HelpText text={helpText} />;

        const testId = testIds && testIds[index]
            ? testIds[index]
            : testIdPrefix
                ? `${testIdPrefix}-${index}`
                : undefined;

        const radio = (
            <RadioOption {...omit(this.props, ['testIds', 'testIdPrefix'])}
                onChange={this.handleChange}
                className={cls}
                key={index}
                checked={checked}
                value={index}
                label={label}
                help={help}
                data-testid={testId}>

                {suboption.value && this.renderSuboption(suboption, index)}
            </RadioOption>);

        if (asTable) {
            return <td key={value}>{radio}</td>;
        }
        return radio;
    }

    renderRow(row, rowIndex) {
        const {columns} = this.props;

        return (
            <tr key={rowIndex}>
                {row.map((choice, index)=> this.renderRadio(choice, rowIndex * columns + index))}
            </tr>
        );
    }

    renderTable(rows) {
        let className = this.props.className;

        return (
            <table style={{width: '100%'}} className={className}>
                <tbody>
                    {rows.map(this.renderRow.bind(this))}
                </tbody>
            </table>
        );
    }

    renderDivs(rows) {
        rows = zip(...rows); // group by column
        let {className, columns} = this.props;
        let colSize = Math.trunc(12 / columns);
        return (
            <Row className={className}>
                {rows.map((row, rowIndex)=> {
                    return (
                        <div key={rowIndex} className={`col-xs-${colSize} ${className}__column`}>
                            {row.map((choice, index)=> this.renderRadio(choice, index*columns + rowIndex))}
                        </div>
                    );
                })}
            </Row>
        );
    }

    renderInline(choices) {
        return (<div>
            {choices.map((choice, index)=> this.renderRadio(choice, index))}
        </div>);
    }

    render() {
        let {asTable, asDiv, inline, choices, columns} = this.props;
        asTable = asTable && !asDiv;

        let rows = chunk(choices, columns);

        if (inline) {
            return this.renderInline(choices);
        }

        if (asTable) {
            return this.renderTable(rows);
        }
        else {
            return this.renderDivs(rows);
        }
    }
}
