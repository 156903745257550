import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {Link} from 'react-router-dom';
import settings from 'airborne/settings';
import ngettext from 'airborne/gettext';

export default class ExpirationWarning extends React.Component {
    static propTypes = {
        containerStyles: PropTypes.object,
    };

    render() {
        const {containerStyles} = this.props;
        const {'days_to_pwd_exp': daysToPasswordExpire} = settings;

        if (!daysToPasswordExpire) return null;

        return (
            <div className="container" style={containerStyles}>
                <div className="alert alert-info clearfix">
                    <div className="pull-left">
                        <strong>
                            {ngettext('Your password is due to expire in {num} day', 'Your password is due to expire in {num} days', daysToPasswordExpire)}
                        </strong>
                        <br />
                        {gettext('Please change your password in the "My Settings" area.')}
                    </div>
                    <div className="pull-right" style={{padding: '7px 15px'}}>
                        <Link to={'/ui/profile-settings/'}><strong>{gettext('Change the password')}</strong></Link>
                    </div>
                </div>
            </div>
        );
    }
}
