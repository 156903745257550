import React, {useEffect, useContext} from 'react';
import {Redirect} from 'react-router';

import {MicroFrontEnd} from 'airborne/microFrontEnds/MicroFrontEnds';
import {MicroFrontEndsContext} from 'airborne/microFrontEnds/MicroFrontEnds';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

const PaymentManagerWrapper = () => {
    const isMidasEnabled = getFeatureFlag(null,'MIDAS_ENABLED');

    if (!isMidasEnabled) {
        return <Redirect to="/ui/homepage"/>;
    }

    const context = useContext(MicroFrontEndsContext);
    useEffect(() => {
        context.loadMicroFrontEnd('midas-fe');
    }, []);
    return <MicroFrontEnd name="midas-fe" />;
};

export default PaymentManagerWrapper;
