import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';
import gettext from 'airborne/gettext';

import Input from 'midoffice/newforms/widgets/Input';
import RadioWithCustomInput from 'midoffice/newforms/widgets/RadioWithCustomInput';
import Button from 'react-bootstrap/Button';
import Select from 'midoffice/newforms/widgets/Select';
import FieldError from 'midoffice/newforms/components/FieldError';
import Glyphicons from 'midoffice/components/Glyphicons';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import find from 'lodash/find';

export class AddMoreIDNumber extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        label: PropTypes.string,
    };

    render() {
        const {onClick, disabled, label} = this.props;

        if (disabled) { return null; }

        return (
            <div className="form-group__sub pull-right">
                <Button variant="link" size="sm" className="btn-link--narrow" onClick={onClick}>
                    {label}
                </Button>
            </div>
        );
    }
}


function getLabel(vendors, vendor, choices) {
    if (!vendor || !choices?.hasOwnProperty(vendor)) return '';
    const label = vendors.filter(([key])=> key === vendor)[0][1];
    return (<span className="input-group-label" style={{width: '40%'}}>{label}</span>);
}

@injectField
export default class NegotiatedIdNumbers extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        vendors: PropTypes.array.isRequired,
        errors: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]),
        choices: PropTypes.object.isRequired,
        inputPlaceholder: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onlyValue: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        customCd: PropTypes.bool,
        idCdMatrix: PropTypes.array,
        cdNumbers: PropTypes.array,

        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        handleIdCdChange: PropTypes.func,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cdNumbers, value: {vendor, idNumber}, idCdMatrix} = this.props;

        const entityWithSameVendor = find(cdNumbers, {vendor});
        const matchedEntity = find(idCdMatrix, {cdNumber: entityWithSameVendor?.number});

        if (entityWithSameVendor && matchedEntity?.isDefault && idNumber !== matchedEntity.idNumber) {
            this.handleChangeNumber(matchedEntity.idNumber, 'idNumber');
        }
    }

    handleChangeNumber = (number, name) => {
        const {value, onChange, handleIdCdChange} = this.props;
        const {vendor} = value;
        onChange({...value, [name]: number});

        if (handleIdCdChange) {
            handleIdCdChange(vendor, number, true);
        }
    };
    handleChangeCustom = (number) => {
        const {value, onChange} = this.props;
        onChange({...value, 'customIdInput': number});
    };
    renderEmpty = () => {
        const {value: {vendor, idNumber, customIdInput}, disabled, vendors, choices, errors, inputPlaceholder, onlyValue} = this.props;
        const className = classnames(
            'form-group__select-group',
            {'has-error': errors}
        );
        const choicesWithoutSelectedVendors = vendors?.filter(vendor => !choices?.hasOwnProperty([vendor[0]])) || [];
        return (<div className={className}>
            <div className="form-group__select-group__wrapper">
                <Select
                    name="vendor"
                    inputSize="max"
                    onChange={this.handleChangeNumber}
                    choices={choicesWithoutSelectedVendors}
                    value={vendor}
                    allowEmpty
                    placeholder={gettext('Vendor')}
                    disabled={disabled} />
                <div className="custom-input">
                    <Input
                        name="idNumber"
                        value={customIdInput || idNumber}
                        onChange={this.handleChangeNumber}
                        placeholder={inputPlaceholder}
                        disabled={disabled} />
                </div>
            </div>
            {errors && <FieldError position="bottom" errors={errors} />}
            {!onlyValue && this.renderRemove()}
        </div>);
    }

    renderNumber() {
        const {value: {vendor, idNumber: propsIdNumber, customIdInput: customInput}, choices, disabled, errors, idCdMatrix, cdNumbers} = this.props;
        const  {customInput: customInputError} = errors || {};
        if (isEmpty(choices) || !vendor || !choices?.hasOwnProperty(vendor)) {
            return this.renderEmpty();
        }

        if (choices[vendor] && choices[vendor].length === 1) {
            const [[, label]] = choices[vendor];
            return (
                <Input
                    name="idNumber"
                    value={label}
                    disabled />
            );
        }

        const idNumbers = choices[vendor].filter(cd => !cd[2]).map(([key, label]) => [key, label]);
        const inputValue = propsIdNumber || (idNumbers[0] && idNumbers[0][0]);

        const matchedIdEntity = find(idCdMatrix, {idNumber: inputValue});
        const matchedCdEntity = find(cdNumbers, {number: matchedIdEntity?.cdNumber});

        const disabledByDefault = matchedIdEntity?.isDefault && matchedCdEntity;

        return (<>
            <div className="multi-selector-body">
                {idNumbers.length > 0 && <>
                    <div className="multi-selector-body__title">{gettext('Company ID Number')}</div>
                    <div style={{marginLeft: '40px'}}>
                        <RadioWithCustomInput
                            withTravelerLabel
                            name={'idNumber'}
                            uniqueName={'idNumber' + vendor}
                            title={gettext('Traveler ID Number')}
                            block
                            disabled={disabled || disabledByDefault}
                            choices={idNumbers}
                            className={'radio-choice'}
                            onChange={this.handleChangeNumber}
                            onChangeCustom={this.handleChangeCustom}
                            customInput={customInput}
                            value={propsIdNumber || (idNumbers[0] && idNumbers[0][0])}
                            placeholderCustomInput={gettext('Traveler ID number')}
                            customInputError={customInputError}
                        />
                    </div>
                </>}
            </div>
        </>);

    }

    renderRemove() {
        const {onRemove} = this.props;
        return (
            <Button variant="link" className="highlight-red form-group__select-group__control" onClick={onRemove}>
                <Glyphicons glyph="remove" bsClass="glyphicon" />
            </Button>
        );
    }
    render() {
        const {value: {vendor}, vendors, choices} = this.props;
        return (
            <div className="form-group__select-group">
                <div className="form-group__select-group__wrapper">
                    {getLabel(vendors, vendor, choices)}
                    <div className="custom-input">
                        {this.renderNumber()}
                    </div>
                </div>
            </div>
        );
    }
}
