import React from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';

export class LabeledSection extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        children: PropTypes.any,
        className: PropTypes.string,
    };

    render() {
        const {label, children, className} = this.props;
        const childArray = React.Children.toArray(children).filter(identity);

        if (childArray.length === 0) {
            return null;
        }
        return (<div className={className}>
            <h5 className="step-section__content__title">
                {label}
            </h5>
            {children}
        </div>);
    }
}
