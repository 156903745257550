import settings from 'airborne/settings';
import React from 'react';

import PropTypes from 'prop-types';

import PreferencesEditor from './PreferencesEditor';
import {changeOptions, sendPreferenses} from 'airborne/store/modules/user_settings/actions/preferences';

import {connect} from 'react-redux';

import {createSchema} from 'midoffice/newforms/helpers';
import {
    NumberField,
    CharField,
} from 'midoffice/newforms/fields-stateless';
import {mapStateToProps} from './helpers/formHelpers';

const RegionalSettingsSchema = createSchema({
    fields: {
        'lang_code': CharField,
        'currency_code': CharField,
        'distance_units': CharField,
        'search_radius': NumberField,
        'car_search_radius': NumberField,
        'date_format': CharField,
    },
});

@connect((state) => mapStateToProps(state, 'preferences'), {changeOptions, sendPreferenses})
export default class Preferences extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        changeOptions: PropTypes.func,
        sendPreferenses: PropTypes.func,
    };

    componentDidMount() {
        this.props.changeOptions(settings.USER);
    }

    handleChange = data => {
        this.props.changeOptions(data.value);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.sendPreferenses();
    };

    render() {
        const {value, loading} = this.props;
        return (<PreferencesEditor
            loading={loading}
            schema={RegionalSettingsSchema}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            value={value}/>);
    }
}
