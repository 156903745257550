import React from 'react';

import Button from 'react-bootstrap/Button';
import BootstrapModal from 'react-bootstrap/Modal';
import Glyphicons from 'midoffice/components/Glyphicons';
import Alert from 'react-bootstrap/Alert';

type WarnModalWithTextProps = {
    onHide: void;
    onDone: null | void;
    title: string;
    message: string;
};
export const WarnModalWithText = ({onHide, title, message, onDone = null}: WarnModalWithTextProps) => {
    const onModalClose = onDone ? onDone : onHide;
    return (
        <BootstrapModal
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            // TODO: artem.pryanichnikov - Need to update react - bootstrap to current version
            onHide={onModalClose}
            show
        >
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{title}</BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body>
                <Alert variant="warning" closeLabel="Close alert">
                    <Glyphicons bsClass="glyphicon" glyph="exclamation-sign" />
                    <strong>{message}</strong>
                </Alert>
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
                <Button
                    variant="secondary"
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    // TODO: artem.pryanichnikov - Need to update react - bootstrap to current version
                    onClick={onModalClose}
                >
                    Done
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};
