import React from 'react';

export const urlRegExp = /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*))/;

export const wrapLinks = data => {
    return data.split(urlRegExp).map(part => (
        part.match(urlRegExp)
            ? React.createElement('a', {
                href: part,
                target: '_blank',
                rel: 'noopener',
                key: part,
            }, part)
            : part
    ));
};
