import React from 'react';
import DOMPurify from 'dompurify';

export default function parseRestrictedError(dirtyText) {
    const text = DOMPurify.sanitize(dirtyText);

    const reg = /(\[+[A-Za-z0-9_ ]+\] *\(+[A-Za-z0-9\/:&@#\/%?=~_|!,.;\-\?\.\=\@ ]+\))/g;
    let parts = text.split(reg);
    const htmlText = parts.reduce((acc, part) => {
        if (part[0] === '[') {
            const splitedText = part.split(']');
            const linkText = splitedText[0].slice(1);
            const link = splitedText[1].slice(1, -1);
            return acc + `<a target="_blank" href=${link}>${linkText}</a>`;
        } else {
            return acc + part;
        }
    }, '');

    return <div dangerouslySetInnerHTML={{__html: htmlText}}/>;
}