import {gettext} from 'airborne/gettext';

import formatAddress from 'airborne/search2/helpers/address';


export function formatAirport({terminals, code}) {
    if (terminals) {
        return gettext('{iataCode} airport, terminals: {terminals}', {
            iataCode: code,
            terminals: terminals.join(', '),
        });
    }
    return gettext('{iataCode} airport', {
        iataCode: code,
    });
}

export function formatCarAddress({streetAddress, city, state, postal, country}) {
    return formatAddress(
        streetAddress,
        city,
        state,
        postal,
        country
    );
}

export function formatLocation({airport, address}) {
    return airport
        ? formatAirport(airport)
        : formatCarAddress(address).toUpperCase();
}
