import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import forms from 'midoffice/newforms';
import Radios from 'midoffice/newforms/widgets/Radios';
import {EmailTagValidator, UniqueListValidator} from 'midoffice/newforms/validators';

import {getChoices} from 'midoffice/helpers/currencies';

import {ChainsAutocomplete} from 'midoffice/data/Codes';
import {OIDAutocomplete} from 'midoffice/data/OIDPCC';
import {destinationCountryAutocomplete} from 'midoffice/data/Destination';


import CountryTags from 'midoffice/newforms/widgets/CountryTags';
import Tags from 'midoffice/newforms/widgets/Tags';
import TreeSelect from 'midoffice/newforms/widgets/TreeSelect';
import InputRange from 'midoffice/newforms/widgets/InputRange';
import LabeledCheckbox from 'midoffice/newforms/widgets/LabeledCheckbox';
import FrequencyWidget from 'midoffice/components/reporting/FrequencyWidget';

import {
    CompanyField,
    DateRangeField,
    OIDTagsField,
    TagsField
} from 'midoffice/data/filterFields';

const STATUS_CHOICES = [
    ['active', gettext('Active')],
    ['canceled', gettext('Canceled')],
    ['past', gettext('Past')],
];

const CHANNEL_CHOICES = [
    ['aft', 'AFT - ' + gettext('Agent Facing Tool')],
    ['mobile', 'MOB - ' + gettext('Mobile Booking Tool')],
    ['obt', 'OBT - ' + gettext('Online Booking Tool')],
];


const COMMISSION_TYPE_CHOICES = [
    [true, 'Commissionable'],
    [false, 'Non-commissionable']
];

const FORMAT_CHOICES = [
    ['.csv', 'CSV'],
    ['.xlsx', 'XLSX'],
];

const FREQUENCY_CHOICES = [
    ['weekly', 'Weekly'],
    ['monthly', 'Monthly'],
    ['quarterly', 'Quarterly'],
];

const USER_SEGMENT_CHOICES = [
    ['Group_1', gettext('Control')],
    ['Group_2', gettext('Group 2')],
    ['Group_3', gettext('Group 3')],
    ['Group_4', gettext('Group 4')],
];


function priceWithCurrencyValidator(data) {
    if ('price' in data && !isEmpty(data.price) && isEmpty(data.currency)) {
        throw new forms.ValidationError({
            currency: 'You must select a currency when filtering by total price of booking.'
        });
    }
}


function commissionWithTypeValidator(data) {
    if ('commission_rate' in data && !isEmpty(data.commission_rate) && !isBoolean(data.commission_type)) {
        throw new forms.ValidationError({
            'commission_type': 'You must select commission type when filtering by commission rate.',
        });
    }
}

function getCurrencyChoices() {
    return getChoices(settings.ALL_CURRENCIES).filter(choice => choice[0] !== '...');
}


export class ReportingSchema extends forms.Schema {

    constructor(options={}) {
        const {validators=[]} = options;

        super({
            ...options,
            validators: [
                ...validators,
                priceWithCurrencyValidator,
                commissionWithTypeValidator,
            ]
        });
    }

    getIncomingValue(data={}) {
        const incomingValue = super.getIncomingValue(data);
        const shouldSave = Boolean(incomingValue.name);

        return {...incomingValue, shouldSave};
    }

    fields = {
        id: new forms.CharField({
            required: false,
            allowEmpty: true,
        }),

        // supplier info
        status: new forms.ChoiceField({
            label: 'PNR Status',
            required: false,
            allowEmpty: true,
            placeholder: 'Any PNR status',
            choices: STATUS_CHOICES,
        }),

        'created_date': new DateRangeField({
            label: 'Date PNR Created',
            required: false,
        }),

        // TODO (GG-28888): For backward compatibility with old bookings.
        // 'worldspan' will be deleted when in database will be absent
        // worldspan bookings.
        providers: new forms.MultipleChoiceField({
            label: 'Suppliers',
            required: false,
            choices: [...settings.ALL_PROVIDERS, ['worldspan', 'Worldspan']],
        }),

        'gds_office_id': new OIDTagsField({
            label: 'OID/PCC',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more OID/PCC',
            widget: Tags,
            props: {
                autocompleteSource: new OIDAutocomplete()
            },
        }),

        // booking info
        system: new forms.ChoiceField({
            label: 'Booking Channel',
            required: false,
            allowEmpty: true,
            placeholder: 'Any channel',
            choices: CHANNEL_CHOICES,
        }),

        country_of_origin: new TagsField({  // eslint-disable-line camelcase
            label: 'Country of Origin',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more countries',
            widget: CountryTags,
            props: {
                autocompleteSource: destinationCountryAutocomplete,
            }
        }),

        country_of_fulfillment: new TagsField({ // eslint-disable-line camelcase
            label: 'Country of Fulfillment',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more countries',
            widget: CountryTags,
            props: {
                autocompleteSource: destinationCountryAutocomplete,
            }
        }),

        agent_country: new TagsField({  // eslint-disable-line camelcase
            label: 'Agent Country',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more countries',
            widget: Tags,
            props: {
                autocompleteSource: destinationCountryAutocomplete,
            }
        }),

        agent_traveler: new TagsField({  // eslint-disable-line camelcase
            label: 'Booker',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more emails',
            widget: Tags,
        }),

        traveler_emails: new TagsField({  // eslint-disable-line camelcase
            label: 'Traveler',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more email addresses',
            widget: Tags,
        }),

        entity: new CompanyField({
            label: 'Company',
            required: false,
            placeholder: 'Enter one or more companies',
            widget: TreeSelect,
            props: {
                treeName: 'companies',
                linkText: 'Add Company from Hierarchy...',
                modalLabel: 'Filter by Companies',
            },
        }),

        currency: new forms.ChoiceField({
            label: 'Booking Currency',
            required: false,
            allowEmpty: true,
            placeholder: 'Any currency',
            choices: getCurrencyChoices(),
        }),

        'payment_method': new forms.MultipleChoiceField({
            label: 'Payment Method',
            required: false,
            choices: settings.ALLOWED_PAYMENT_OPTIONS,
        }),

        'user_segment': new forms.MultipleChoiceField({
            label: 'User Segment',
            required: false,
            choices: USER_SEGMENT_CHOICES,
        }),

        price: new forms.IntegerRangeField({
            label: 'Total Price of Booking',
            required: false,
            widget: InputRange,
            minValue: 0,
        }),

        // hotel info
        chains: new TagsField({
            label: 'Hotel Chains',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more chains',
            disabled: true,
            widget: Tags,
            props: {
                autocompleteSource: new ChainsAutocomplete('hotel-chains')
            }
        }),

        checkin: new DateRangeField({
            label: 'Check-In Dates',
            required: false,
        }),

        checkout: new DateRangeField({
            label: 'Check-Out Dates',
            required: false,
        }),

        preference: new forms.BooleanField({
            label: 'Hotel Preference',
            placeholder: 'BCD Travel preferred hotels only',
            required: false,
            widget: LabeledCheckbox,
        }),

        // commission
        'commission_type': new forms.ChoiceField({
            label: 'Commission Type',
            required: false,
            allowEmpty: true,
            placeholder: 'Any commission',
            choices: COMMISSION_TYPE_CHOICES,
        }),

        'commission_rate': new forms.IntegerRangeField({
            label: 'Commission Rate',
            required: false,
            minValue: 0,
            maxValue: 100,
            placeholder: '%',
            widget: InputRange,
        }),

        // email reporting
        name: new forms.CharField({
            label: 'Report Name',
            required: false,
        }),

        'auto_reporting': new forms.ChoiceField({
            label: false,
            defaultValue: false,
            widget: Radios,
            choices: [
                [false, 'Create a one time report'],
                [true, 'Create an automated report'],
            ],
        }),

        'report_format': new forms.ChoiceField({
            label: 'Report Format',
            required: false,
            choices: FORMAT_CHOICES,
            initial: '.csv',
            widget: Radios,
        }),

        'send_to': new TagsField({
            label: 'Recipients',
            required: false,
            allowEmpty: true,
            placeholder: 'Enter one or more email addresses',
            widget: Tags,
            validators: [new EmailTagValidator(), new UniqueListValidator()],
        }),

        frequency: new forms.ChoiceField({
            label: 'Delivery Frequency',
            required: false,
            allowEmpty: true,
            choices: FREQUENCY_CHOICES,
            initial: 'weekly',
            widget: FrequencyWidget,
        }),

        // saving
        shouldSave: new forms.BooleanField({
            label: 'Save this report for future use? (optional)',
            required: false,
        })
    }
}
