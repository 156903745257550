import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

export default class InputRange extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.shape({
            min: PropTypes.string,
            max: PropTypes.string
        }),
        type: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onChange: PropTypes.func
    };

    static defaultProps = {
        disabled: false,
        readOnly: false,
        className: '',
        type: 'text'
    };

    handleChange = (value, which) => {
        const {value: fullValue} = this.props;
        const newValue = /^[0-9]*$/.test(value) ? Number(value) : null;
        this.props.onChange({...fullValue, [which]: newValue});
    };

    render() {
        let {min = null, max = null} = this.props.value || {};
        let {readOnly, disabled, placeholder, className, type} = this.props;
        if (readOnly) {
            disabled = true;
        }

        return (
            <div className={`${className} input-group`}>
                <Input
                    name="min"
                    className="form-control"
                    ref="min"
                    value={min}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    inputSize=""
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                />
                <span className="input-group-addon">to</span>
                <Input
                    name="max"
                    className="form-control"
                    ref="max"
                    value={max}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    inputSize=""
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}
