import memoize from 'lodash/memoize';
import {createSchema, required} from 'midoffice/newforms/helpers';

import {validateExpiration} from 'airborne/checkout_air/helpers/checkoutForm';
import {getCardFields, getBillingFields, makeCvvField} from 'airborne/checkout2/PaymentSchema';
import {emptyMessage} from 'airborne/checkout2/fields';

const getCvvField = (cardType) => (
    {'credit_card_identifier': required(makeCvvField(cardType), emptyMessage)}
);

function createCardSchema(paymentCardsAccepted, cardType, withoutPhone = false) {
    return createSchema({
        fields: {
            ...getCardFields(paymentCardsAccepted, validateExpiration),
            ...getBillingFields({withoutPhone}),
            ...getCvvField(cardType),
        },
    });
};

export default memoize(
    (...args) => createCardSchema(...args),
    (...args) => JSON.stringify(args)
);
