import React from 'react';
import gettext from 'airborne/gettext';

import Input from 'midoffice/newforms/widgets/Input';
import CardExpiration from 'airborne/checkout2/payment/CardExpiration';
import CardName from 'airborne/checkout2/payment/CardName';
import BillingAddress from 'airborne/checkout2/payment/BillingAddress';
import {injectFormContext} from 'midoffice/newforms/decorators';

@injectFormContext
export default class UpdatePaymentInfoEditor extends React.Component {
    render() {
        return (
            <form className="form-horizontal">
                <Input.Field
                    label={gettext('Card Number')}
                    placeholder={gettext('Card Number')}
                    name="credit_card_number"
                    inputCol={7} />

                <CardExpiration.Field
                    className="no-margin-bottom"
                    name="credit_card_expiration"
                    label={gettext('Expiration')}
                    inputCol={7}
                    required />

                <Input.Field label={gettext('CVV/CSC')}
                    name="credit_card_identifier"
                    placeholder={gettext('CVV/CSC')}
                    inputCol={3} />

                <CardName inputCol={7} />

                <hr />
                <BillingAddress withPhone fieldInputCol={7} zipInputCol={4} />
            </form>
        );
    }
}
