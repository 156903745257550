'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {TabContent} from 'midoffice/components/TabsWithContent';
import gettext from 'airborne/gettext';
import {Line} from 'midoffice/components/RateDetails';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';

function getPaymentLabelNewDetails(code, cards) {
    const label = {
        'iata': gettext('IATA'),
        'vpa_guarantee': gettext('VPA Guarantee'),
        'vpa_payment': gettext('VPA Payment'),
        'invoice': gettext('Invoice'),
        'hotel_provider_pay': gettext('Hotel Provider Pay'),
    }[code];
    if ((code === 'card' || code === 'evoucher_card') && isEmpty(cards)) {
        return {label: gettext('Credit Card')};
    }
    return label ? {label} : null;
}

export default class BookingInfoTab extends React.Component {
    static propTypes = {
        'payment_details': PropTypes.string,
        'payment_options': PropTypes.array,
        'payment_cards_accepted': PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]),
        'hold_time': PropTypes.string,
        'min_stay': PropTypes.string,
        'max_stay': PropTypes.string,
    }

    getAcceptedPaymentMethods = () => {
        const {
            payment_options: paymentOptions=[],
            payment_cards_accepted: paymentCardsAccepted,
        } = this.props;

        const evoucher = paymentOptions && (paymentOptions.includes('evoucher_card') ||
            paymentOptions.includes('evoucher_iata') ||
            paymentOptions.includes('evoucher_vpa_guarantee')
        );
        const withPaymentCards = !isEmpty(paymentCardsAccepted) && !isEmpty(paymentOptions) && paymentOptions.includes('card');

        return [
            withPaymentCards && {
                label: gettext('Credit cards'),
                details: paymentCardsAccepted.map(card => card.code || String(card)).join(', '),
            },
            ...paymentOptions.map((payment) => getPaymentLabelNewDetails(payment, paymentCardsAccepted)),
            evoucher && {label: gettext('eVoucher')},
        ].filter(identity);
    }

    render() {
        const {
            payment_details: paymentDetails,
            payment_options: paymentOptions,
            hold_time: holdTime,
            min_stay: minStay,
            max_stay: maxStay,
        } = this.props;

        const noBookingInfo = !paymentDetails && isEmpty(paymentOptions) && !holdTime && !minStay && !maxStay;
        if (noBookingInfo) {
            return (<div>
                <h4>
                    <strong>{gettext('Booking information not available')}</strong>
                </h4>
            </div>);
        }

        const getAcceptedPaymentMethods = this.getAcceptedPaymentMethods();
        return (<TabContent>
            <div className="modal-rate-desc__wrap">
                <h4>
                    <strong>{gettext('Booking Requirements')}</strong>
                </h4>

                <div>
                    <div className="modal-rate-desc__wrap">
                        <p>
                            {paymentDetails}
                        </p>
                    </div>
                    {!isEmpty(getAcceptedPaymentMethods) && <>
                        <h5>
                            <strong>{gettext('Accepted payment methods')}: </strong>
                        </h5>

                        <ul>
                            {getAcceptedPaymentMethods.map(({label, details}) =>
                                (<li key={label}>{label}{details && <>: <i>{details}</i></>}</li>)
                            )}
                        </ul>
                    </>}

                    <Line label={gettext('Hold time')} italic>
                        {holdTime}
                    </Line>

                    <Line label={gettext('Min stay')} italic>
                        {minStay}
                    </Line>
                    <Line label={gettext('Max stay')} italic>
                        {maxStay}
                    </Line>
                </div>
            </div>
        </TabContent>);
    }
}
