import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';

import {getPnrByProduct, isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

import {showPnr} from 'airborne/store/modules/header/actions/pnr';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';
import Button from 'midoffice/components/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import {copyToClipboard} from 'airborne/search2/helpers/copyToClipboard';
import {showQuickAlert} from 'airborne/store/modules/homepage/actions';

function mapStateProps(state) {
    return {
        show: Boolean(getPnrByProduct(state) && !isMultiPnr(state)),
    };
}

@connect(mapStateProps, (dispatch) => ({
    onClick: ()=> dispatch(showPnr(0))
}))
export default class PNRButton extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        testId: PropTypes.string,
    };

    handlePnr = event => {
        event.preventDefault();
        this.props.onClick();
    };


    render() {
        const {show} = this.props;
        if (!show) {
            return null;
        }
        return (<a href="#" className="navbar__acc-link" onClick={this.handlePnr}>
            {gettext('PNR Information')}
        </a>);
    }
}


@connect(null, (dispatch) => ({
    onPnrClick: ()=> dispatch(showPnr(0)),
    showAlert: (message) => dispatch(showQuickAlert(message))
}))
export class PNRWidget extends React.Component {
    static propTypes = {
        pnr: PropTypes.string.isRequired,
        onPnrClick: PropTypes.func.isRequired,
        enablePnrLink: PropTypes.bool,
        enablePnrCopyBtn: PropTypes.bool,
        showAlert: PropTypes.func,
    };

    state = {clicked: false}

    onPnrClick = event => {
        event.preventDefault();
        this.props.onPnrClick();
    };

    onCopyBtnClick = () => {
        const {pnr, showAlert} = this.props;

        if (this.state.clicked) {
            return;
        }

        copyToClipboard(pnr);
        this.setState({clicked: true});
        // TODO: GG-33279 Add single constant of user action timeout
        setTimeout(() => this.setState({clicked: false}), 2000);

        showAlert(gettext('Successfully copied to clipboard.'));
    };

    renderPnrCopyBtn = () => {
        return (
            <AnnotateTooltip id="copy-pnr-to-clipboard">
                <Button bsStyle="light" onClick={this.onCopyBtnClick} disabled={this.state.clicked} data-heap="copy-pnr" className="btn-xs">
                    <span>
                        {!this.state.clicked ? <Glyphicons bsClass="glyphicon" glyph="copy" /> : <Glyphicons bsClass="glyphicon" glyph="ok" />}{' '}
                    </span>
                </Button>
                {gettext('Copy PNR to the clipboard.')}
            </AnnotateTooltip>
        );
    }

    render() {
        const {pnr, enablePnrLink, enablePnrCopyBtn, testId} = this.props;

        return (
            <>
                {enablePnrLink ?
                    <a href="#" className="c-confirm__header__pnr-link" onClick={this.onPnrClick} data-testid={testId}>
                        {pnr}
                    </a> :
                    pnr
                }
                {enablePnrCopyBtn && ' '}
                {enablePnrCopyBtn && this.renderPnrCopyBtn()}
            </>
        );
    }
}
