'process i18n';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import {Field, EmailField, CharField} from 'midoffice/newforms/fields-stateless';
import {createSchema, required} from 'midoffice/newforms/helpers';
import emailValidator from 'midoffice/newforms/validators/email';

const EmailsField = {
    ...Field,
    validate(emails) {
        if (!emails || !Array.isArray(emails)) { return null; }

        const errors = emails.map(emailValidator());
        return isEmpty(compact(errors)) ? null : [errors];
    }
};

const SendOffersSchema = createSchema({
    fields: {
        'to': required(EmailField),
        'sender': required(EmailField),
        'cc': EmailsField,
        'bcc': EmailsField,
        'custom_text': Field,
        'traveler_name': required(CharField),
        'pack_id': Field,
    }
});

export default SendOffersSchema;
