/*global global */

import React from 'react';
import PropTypes from 'prop-types';

import CollapsedText from 'airborne/checkout2/CollapsedText';

export default class OnelLineText extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    state = {
        shouldCollapse: false,
    }

    componentDidMount() {
        if (this.shouldToCollapse()) {
            this.setState({                 //eslint-disable-line react/no-did-mount-set-state
                shouldCollapse: true,
            });
        }
    }

    shouldToCollapse() {
        return this.getLineCount() > 1;
    }

    getLineCount() {
        const style = global.getComputedStyle(this.content, null);
        const lineHeight = parseInt(style.getPropertyValue('line-height'));
        return Math.ceil(this.content.offsetHeight / lineHeight);
    }

    handleRef = (content)=> {
        this.content = content; //eslint-disable-line immutable/no-mutation
    }

    render() {
        return this.state.shouldCollapse
            ? (
                <CollapsedText collapse>
                    {this.props.children}
                </CollapsedText>
            ) : (
                <div ref={this.handleRef}>
                    {this.props.children}
                </div>
            );
    }
}
