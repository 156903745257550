import React from 'react';

import PropTypes from 'prop-types';

import gettext from 'airborne/gettext';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import parseRestrictedError from 'airborne/helpers/parseRestrictedError';


export default class RestrictedDestinationModal extends React.Component {
    static propTypes = {
        kind: PropTypes.string.isRequired,
        message: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string
        ]),
        onHide: PropTypes.func.isRequired,
    };

    render() {
        const {onHide, message} = this.props;
        const messageWithGaps = message.replace(/\n/g, "<br>");
        const parsedMessage = parseRestrictedError(messageWithGaps);
        return (
            <Modal show keyboard={false} backdrop="static" onHide={onHide} >
                <div className="info-modal">
                    <Modal.Body>
                        {parsedMessage}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={onHide} variant="success">{gettext('OK')}</Button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}
