import React from 'react';
import SearchForm from './SearchForm';
import PNRSearch from 'airborne/homepage2/PNRSearch';

export default class RoutesModification extends React.Component {
    render() {
        return (
            <div className="ag-agent-wrapper clearfix">
                <div className="ag-left-block">
                    <PNRSearch />
                </div>
                <div className="ag-right-block">
                    <SearchForm />
                </div>
                <div className="ag-right-block-bg" />
            </div>
        );
    }
}
