import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {isBookable} from 'airborne/homepage2/helpers/pnr';
import Button from 'react-bootstrap/Button';
import bookRate from 'airborne/store/modules/search_hotels/actions/book';

import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';

const mapStateProps = createSelector([
    (state)=> getBooking(state, 0).loading,
    (state)=> isBookable(state, 0),
], (disabled, show)=> ({disabled, show})
);

@connect(
    mapStateProps,
    (dispatch, {hotelId, rateKey})=> ({
        onClick: ()=> dispatch(bookRate(hotelId, rateKey)),
    }),
    (stateProps, dispatchProps, ownProps)=> ({
        ...stateProps,
        ...dispatchProps,
        ...pick(ownProps, 'className', 'children', 'variant', 'data-booktype',
            'data-alternative', 'data-sc-cheapest', 'data-sc-best-rate', 'data-hotel-position',
            'data-non-sc', 'data-book-button', 'data-number-of-hotels', 'data-rate-position',
            'data-sc-best-non-loyalty', 'data-sc-best-loyalty', 'data-number-of-rates'),
    }),
)

export default class BookButton extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
    };

    render() {
        const {show, ...props} = this.props;
        if (!show) {
            return null;
        }
        return (<Button {...props} />);
    }
}
