import PropTypes from 'prop-types';

export const valueShape = PropTypes.shape({
    originDestinations: PropTypes.arrayOf(PropTypes.shape({
        pickUp: PropTypes.object,
        dropOff: PropTypes.object,
    })),
    directFlights: PropTypes.bool,
    baggageAllowed: PropTypes.bool,
    departureCabin: PropTypes.string,
    arrivalCabin: PropTypes.string,
    ftNumbers: PropTypes.array,
});
