import gettext from 'airborne/gettext';
import {createSchema, required, combineValidators} from 'midoffice/newforms/helpers';
import {CharField, EmailField} from 'midoffice/newforms/fields-stateless';
import email from "midoffice/newforms/validators/email";

const RESTRICTED_SYMBOLS = /[!-,\/:-\?\[-_\{-~]/u;
function nameValidator(value) {
    return RESTRICTED_SYMBOLS.test(value || '')
        ? gettext('Special characters are not allowed in the name field')
        : null;
}

const TSPMNameField = {
    ...CharField,
    validate: combineValidators(
        CharField.validate,
        nameValidator,
    ),

};

const TravelerSearchSchema = createSchema({
    fields: {
        email: EmailField,
        name: TSPMNameField,
    },
    validate(value, configurationId) {
        const fieldErrors = this.validateFields(value);

        if (value.email && !value.name){
            return {'email': EmailField.validate(value.email)}
        }
        if (!value.email && value.name && configurationId){
            return {'name': TSPMNameField.validate(value.name)}
        }
        if (!value.email && !value.name){
            return {
                'email': 'This field is required',
                'name': 'This field is required'
            }
        }
        return fieldErrors;
    },
});
export default TravelerSearchSchema;
