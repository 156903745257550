import find from 'lodash/find';
import memoize from 'lodash/memoize';
import compact from 'lodash/compact';
import settings from 'airborne/settings';


const countryName = memoize(function countryName(query) {
    const match = find(settings.COUNTRIES, ([code])=> code === query);
    if (!match) { return null; }
    const [,name] = match;
    return name;
}, function memoizeKey(query) {
    return [query, settings.LANGUAGE_CODE];
});

function cityAddress(address, city, stateProvinceCode, postalCode) {
    const rest = compact([
        city, stateProvinceCode, postalCode
    ]).join(' ');
    return address ? `${address}, ${rest}` : rest;
}

function withCountry(address, countryCode) {
    return countryCode
        ? `${address}, ${countryName(countryCode) || countryCode}`
        : address;
}

export default function formatAddress(address, city, stateProvinceCode, postalCode, countryCode) {
    return withCountry(
        cityAddress(address, city, stateProvinceCode, postalCode),
        countryCode
    );
}
