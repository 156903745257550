import normalize from 'airborne/search2/helpers/normalize';
import {register} from 'airborne/types';
import {dropEmptyDeep} from 'airborne/helpers/emptyDeep';
import {FIELDS} from './BookingSearchSchema';
import {SEARCH_TYPES} from 'midoffice/external-bookings/searchTypes';

const airUrl = '/internal/air/retrieve_external_reservations/';
const hotelsUrl = '/internal/hotels/retrieve_external_reservations/';

const getFetchCollectionBy = (searchType) => {
    const searchParams = {
        [SEARCH_TYPES.FLIGHTS]: ['POST', airUrl],
        [SEARCH_TYPES.BOOKINGCOM]: ['POST', hotelsUrl, {provider: 'bookingcom'}],
        [SEARCH_TYPES.HRS_DIRECT]: ['POST', hotelsUrl, {provider: 'hrsd'}],
        [SEARCH_TYPES.EPS_BOOKINGS]: ['POST', hotelsUrl, {provider: 'eps'}],
    }[searchType];

    return function fetchCollection() {
        return searchParams;
    };
};

const getSerializer = (searchType) => {
    return function serialize(data) {
        const {page, 'page_size': pageSize, ...rest} = data;
        const offset = ((page - 1) * pageSize);

        const {
            [FIELDS.TRAVELER_FIRST_NAME]: travelerFirstName,
            [FIELDS.TRAVELER_LAST_NAME]: travelerLastName,
            ...filters
        } = rest;

        const traveler = {
            'traveler_name': {
                'first_name': travelerFirstName,
                'last_name': travelerLastName,
            }
        };

        return dropEmptyDeep({
            limit: pageSize,
            offset,
            filters: {
                ...filters,
                ...(searchType !== SEARCH_TYPES.BOOKINGCOM && traveler),
            }
        });
    };
};

function parseAir(originalBooking) {
    const booking = normalize(originalBooking);
    const originDestinations = booking?.fareGroup?.originDestinations?.map(destination => {
        const {departure} = destination.segments[0];
        const {arrival} = destination.segments[destination.segments.length - 1];

        return {
            arrival,
            departure
        };
    });

    return {
        ...booking,
        originDestinations,
        id: booking?.uid,
    };
}

function parseHotels(originalBooking, id) {
    return {
        ...normalize(originalBooking),
        id: originalBooking?.uid || id,
    };
}

export const dynamicRegister = (searchType) =>
    register('externalBookings', {
        parse: (searchType === SEARCH_TYPES.FLIGHTS ? parseAir : parseHotels),
        fetchCollection: getFetchCollectionBy(searchType),
        serialize: getSerializer(searchType),
    });
