import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import {searchSingleHotel} from 'airborne/search2/helpers/hotels';
import {canShowAlternativeHotel} from 'airborne/search2/helpers/hotelSelector';


function ready(state) {
    const {hotels: {hotels, avail, rates}, dest: {view}} = state;
    if (hotels.loading && view !== 'map') { return false; }

    if (searchSingleHotel(state)) {
        if (canShowAlternativeHotel(state)) {
            return isEmpty(avail.loading) || !isEmpty(avail.data);
        }
        else {
            const id = get(hotels, 'data[0].id');
            return !rates.loading[id] || !isEmpty(rates.data[id]);
        }

    }
    return isEmpty(avail.loading) || !hotels.loading || view === 'map';
}


@connect((state)=> ({
    ready: ready(state),
}))
export default class HotelsLoader extends React.Component {
    static propTypes = {
        ready: PropTypes.bool,
        children: PropTypes.any,
    };

    renderLoader() {
        return (<div className="container">
            <div className="search-preloader">
                <div className="search-preloader__title">
                    {gettext('Loading hotels, please wait…')}
                </div>

                <div className="search-preloader__text-wrapper">
                    <ul className="search-preloader__text">
                        <li>{gettext('Searching rates and availability')}</li>
                        <li>{gettext('Checking additional sources')}</li>
                        <li>{gettext('Almost there')}</li>
                        <li>{gettext('We appreciate your patience')}</li>
                    </ul>
                </div>

                <div className="search-preloader__bar">
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                </div>
            </div>
        </div>);
    }

    render() {
        const {children, ready} = this.props;
        return (children && ready) ? children : this.renderLoader();
    }
}
