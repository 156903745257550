import {
    UPDATE_PAYMENT_PATCH_FORM,
    UPDATE_PAYMENT_CLEANUP_FORM,
} from '../actionTypes';

export function patchUPaymentForm({value, errors}) {
    return {type: UPDATE_PAYMENT_PATCH_FORM, value, errors};
}

export function cleanupUPaymentForm() {
    return {type: UPDATE_PAYMENT_CLEANUP_FORM};
}
