import realGettext from 'airborne/gettext';

function gettext(text) {
    return text;
}

const AMENITY_CODES = {
    'AIRSH': gettext('Airport shuttle'),
    'ACND': gettext('Air conditioning'),
    'BAR': gettext('Bar'),
    'BFST': gettext('Breakfast'),
    'BCNT': gettext('Business center'),
    'CCNT': gettext('Conference center'),
    'CLNG': gettext('Dry cleaning'),
    'LIFT': gettext('Elevator'),
    'GOLF': gettext('Golf'),
    'GYM': gettext('Gym'),
    'INTT': gettext('Internet available'),
    'KIDAC': gettext('Kids activities'),
    'KTCH': gettext('Kitchen / Kitchenette'),
    'LNDR': gettext('Laundry'),
    'MTNG': gettext('Meeting rooms'),
    'MBAR': gettext('Mini-bar'),
    'NSMK': gettext('Non-smoking room'),
    'PARK': gettext('Parking'),
    'PETS': gettext('Pets allowed'),
    'RFRG': gettext('Refrigerator'),
    'RSTR': gettext('Restaurant'),
    'RSVC': gettext('Room service'),
    'SAFE': gettext('Safe in room'),
    'SAUNA': gettext('Sauna'),
    'POOL': gettext('Swimming pool'),
    'TENN': gettext('Tennis court'),
    'TV': gettext('TV'),
    'WCHR': gettext('Wheelchair accessible'),
    'WIFI': gettext('Wifi'),
    'FINTT': gettext('Free internet'),
    'FBFST': gettext('Free breakfast'),
    'FPARK': gettext('Free parking'),
    'FWIFI': gettext('Free Wifi'),
    'CHKHL': gettext('Guest health is checked'),
    'DIST': gettext('Physical distancing enforced'),
    'ENHCL': gettext('Enhanced cleaning options'),
    'ECHRG': gettext('Electric car charging station'),
};

export default function format(code) {
    return realGettext(AMENITY_CODES[code]);
}
