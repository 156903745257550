import WaitBooking from 'airborne/common/WaitBooking';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';

import {getProduct} from 'airborne/store/modules/homepage/selectors/product';
import {getBooking} from 'airborne/store/modules/checkout_hotels/selectors/booking';
import {isLoadingBookCarsForDestination} from 'airborne/store/modules/homepage/selectors/homepage';


export default connect(
    (state, {show})=> ({
        show: getBooking(state, 0).loading || show,
        product: getProduct(state),
        message: isLoadingBookCarsForDestination(state) ? gettext('Please wait...') : undefined, //eslint-disable-line no-undefined
    })
)(WaitBooking);
