import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import Alert from 'react-bootstrap/Alert';

import gettext from 'airborne/gettext';
import markup from 'airborne/markup';
import browserHistory from 'airborne/browserHistory';


export default class NoAcceptedCardsModal extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
    };

    onBack = ()=> {
        browserHistory.goBack();
    }

    render() {
        const {onHide} = this.props;

        return (<Modal show>
            <div className="info-modal">
                <Modal.Body>
                    <Alert color="danger" closeLabel="Close alert" className="alert-danger">
                        <Glyphicons bsClass="glyphicon" glyph="exclamation-sign" />
                        {markup(gettext('**There was a problem identifying the accepted credit cards for this rate.**'))}
                    </Alert>
                    <hr />
                    <p>{markup(gettext('You may proceed with the booking, but the booking **may not succeed due to this error**.'))}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onBack} variant="link" className="btn-link--narrow pull-left">
                        <strong>{gettext('« Select a different rate')}</strong>
                    </Button>
                    <Button onClick={onHide} color="primary">{gettext('Proceed')}</Button>
                </Modal.Footer>
            </div>
        </Modal>);
    }
}
