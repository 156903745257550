import React from 'react';
import cx from 'classnames';
import {injectField, injectFormContext} from 'midoffice/newforms/decorators';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import AirDestinationAutocomplete from 'airborne/homepage2/autocomplete/AirDestinationAutocomplete';
import gettext from 'airborne/gettext';
import {getDateLimits} from 'airborne/homepage2/helpers/dateLimits';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import DestinationSelect from 'airborne/homepage2/DestinationSelect';
import DateRange from 'midoffice/newforms/widgets/DateRange';
import settings from 'airborne/settings';
import Select from 'midoffice/newforms/widgets/Select';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import AirTimeRestrictionField from './AirTimeRestrictionField';
import PseudoDateRange from './AirPseudoDateRange';
import {valueShape} from './shapes';
import Glyphicons from 'midoffice/components/Glyphicons';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {getCabinClassChoices, isOneWayMode} from 'airborne/homepage2/AirDestinationSearch/helpers';

const SABRE_EXCHANGE_PRESERVE_DEST_CHOICES = [
    ['Flights', gettext('Flights')],
    ['FlightsAndFares', gettext('Flights And Fares')],
    ['Connections', gettext('Connections')],
];

@injectFormContext
class AirDestinationEditor extends React.Component {
    static propTypes = {
        value: valueShape,
        errors: PropTypes.any,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        companyId: PropTypes.number.isRequired,
        originAutocomplete: PropTypes.any.isRequired,
        destinationAutocomplete: PropTypes.any.isRequired,
        tripType: PropTypes.string,
        disableRecentSearches: PropTypes.bool,
        noCabin: PropTypes.bool,
        originDestinations: PropTypes.array,
        index: PropTypes.number,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
        isSabreExchange: PropTypes.bool,
    };

    render() {
        const {
            companyId, disabled, value, tripType, isSabreExchange,
            originAutocomplete, destinationAutocomplete,
            disableRecentSearches, noCabin, originDestinations,
            index, isFirstExchangeOriginDestinationFlown,
        } = this.props;

        const oneWay = isOneWayMode(tripType);
        const roundTrip = tripType === AIR_TRIP_TYPES.ROUND_TRIP;
        const {noCabinPreference} = value;
        const autocompleteExtra = {companyId, product: 'air'};

        const cabinProps = noCabinPreference
            ? {
                disabled: true,
                value: null,
                allowEmpty: true,
                placeholder: gettext('Any'),
            }
            : {};

        const {min: minDate, max: maxDate} = getDateLimits();
        const departDate = moment(value?.dateRange?.min, 'YYYY-MM-DD', true);

        return (<>
            <Row>
                <DestinationSelect.Field
                    label={gettext('Origin')}
                    labelSuffix=""
                    inputCol={9}
                    name="pickUp"
                    placeholder={gettext('Enter origin city or airport')}
                    autocompleteSource={originAutocomplete}
                    autocompleteExtra={{...autocompleteExtra, recentSearches: !disableRecentSearches}}
                    className="ag-destination form-group--no--bottom"
                    required={false}
                    disabled={disabled}
                    withAirDestinationInfo
                    dropdownTestId="air-homepage-origin-dropdown"
                />
            </Row>

            {isSabreExchange && roundTrip && (
                <Row>
                    <Select.Field
                        name="preserveInboundDest"
                        label={gettext('Preserve Origin')}
                        labelCol={2}
                        inputCol={9}
                        allowEmpty
                        choices={SABRE_EXCHANGE_PRESERVE_DEST_CHOICES} />
                </Row>
            )}

            <Row>
                <DestinationSelect.Field
                    label={gettext('Destination')}
                    labelSuffix=""
                    inputCol={9}
                    name="dropOff"
                    placeholder={gettext('Enter destination city or airport')}
                    autocompleteSource={destinationAutocomplete}
                    autocompleteExtra={{...autocompleteExtra, recentSearches: false}}
                    className="ag-destination form-group--no--bottom"
                    required={false}
                    disabled={disabled}
                    withAirDestinationInfo
                    dropdownTestId="air-homepage-destination-dropdown"
                />
            </Row>

            {isSabreExchange && roundTrip && (
                <Row>
                    <Select.Field
                        name="preserveOutboundDest"
                        label={gettext('Preserve Destination')}
                        labelCol={2}
                        inputCol={9}
                        allowEmpty
                        choices={SABRE_EXCHANGE_PRESERVE_DEST_CHOICES} />
                </Row>
            )}

            <hr className="ag-home-form__hr"/>
            <Row>
                {!oneWay && !isFirstExchangeOriginDestinationFlown && (
                    <div className="ag-home-form__title">
                        <div className="ag-home-form__title__item">{gettext('Depart Flight')}</div>
                        <div className="ag-home-form__title__item">{gettext('Return Flight')}</div>
                    </div>
                )}
            </Row>
            <Row>
                {oneWay || isFirstExchangeOriginDestinationFlown
                    ? <PseudoDateRange.Field
                        name="dateRange"
                        label={gettext('Dates')}
                        labelSuffix=""
                        className="ag-home-daterange"
                        labelCol={2}
                        inputCol={5}
                        originDestinations={originDestinations}
                        index={index}
                    />
                    : <DateRange.Field
                        name="dateRange"
                        label={gettext('Dates')}
                        labelSuffix=""
                        required={false}
                        className="ag-home-daterange"
                        inputCol={9}

                        minTestId="homepage-departure-date"
                        maxTestId="homepage-return-date"


                        inputFormat={settings.SHORT_DATE_FORMAT}
                        placeholder={gettext(settings.SHORT_DATE_FORMAT.toLowerCase())}
                        minFrom={minDate}
                        minTo={maxDate}
                        maxFrom={departDate.isValid() ? departDate : minDate}
                        maxTo={maxDate}
                        plain
                    />
                }
            </Row>
            <Row>
                <div className="ag-form-group__split">
                    <AirTimeRestrictionField.Field
                        label={gettext('Departure Time')}
                        hint={'Leave empty for any time'}
                        labelSuffix=""
                        name="timeRestrictionOutbound"
                        inputCol={5}
                        data-testid="homepage-departure-time-restriction-time"
                    />
                    {!(oneWay || isFirstExchangeOriginDestinationFlown) && (
                        <AirTimeRestrictionField.Field
                            name="timeRestrictionInbound"
                            inputCol={5}
                            data-testid="homepage-return-time-restriction-time"
                        />
                    )}
                </div>
            </Row>
            {!noCabin && (
                <>
                    <Row>
                        <div className="ag-form-group__split">
                            <Select.Field
                                label={gettext('Cabin')}
                                labelSuffix=""
                                choices={getCabinClassChoices()}
                                required={false}
                                name="departureCabin"
                                inputCol={5}
                                {...cabinProps}
                            />

                            {!oneWay && <Select.Field
                                name="arrivalCabin"
                                choices={[
                                    ...getCabinClassChoices(),
                                    ['same', gettext('Same as Depart Class')],
                                ]}
                                required={false}
                                inputCol={5}
                                {...cabinProps}
                                {...(oneWay
                                    ? {
                                        value: null,
                                        allowEmpty: false,
                                    }
                                    : {})}
                            />}
                        </div>
                    </Row>
                    <Row>
                        <div className="ag-form-group__split">
                            <div className="ag-home__label-offset">
                                <Checkbox.Field
                                    name="noCabinPreference"
                                    label={gettext('No Cabin Preference')}
                                    inputCol={5}
                                />
                            </div>
                        </div>
                    </Row>
                </>
            )}
        </>);
    }
}

@injectField
export default class AirDestinationField extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onReuseDestination: PropTypes.func.isRequired,
        value: PropTypes.object,
        errors: PropTypes.object,
        index: PropTypes.number,
        child: PropTypes.object,
        companyId: PropTypes.number,
        name: PropTypes.string,
        tripType: PropTypes.string,
        disableRecentSearches: PropTypes.bool,
        noCabin: PropTypes.bool,
        originDestinations: PropTypes.array,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
        isSabreExchange: PropTypes.bool,
    };

    originAutocomplete = new AirDestinationAutocomplete('air_destination_lookup_1');
    destinationAutocomplete = new AirDestinationAutocomplete('air_destination_lookup_2');

    handleChange = (selected) => {
        const {onChange, onReuseDestination, name} = this.props;
        const selectedValuePickup = selected?.value?.pickUp || {};

        // Check if its comes from the Recent Searches
        // In this case 'selected.pickUp' will contain the Full Form Value
        if (selectedValuePickup.hasOwnProperty('expired')) {
            const {value: {pickUp, ...rest}} = selected;
            const filledForm = {
                ...rest,
                ...pickUp,
            };
            onReuseDestination(filledForm);
        }
        else {
            onChange(selected, name);
        }
    };

    renderRemove() {
        const {onRemove, tripType, index, isFirstExchangeOriginDestinationFlown} = this.props;

        if ((!index || tripType !== AIR_TRIP_TYPES.MULTI_CITY) && !isFirstExchangeOriginDestinationFlown) {
            return null;
        }

        return (
            <Button
                className={'highlight-red ag-mode__control'}
                variant="link"
                onClick={onRemove}
            >
                <Glyphicons bsClass="glyphicon" glyph="remove"/>
            </Button>
        );
    }

    render() {
        const {
            value,
            errors,
            child,
            index,
            companyId,
            tripType,
            disableRecentSearches,
            isFirstExchangeOriginDestinationFlown,
        } = this.props;

        if (value.flownIndicator) {
            return null;
        }

        const classNames = cx({'ag-mode__multi-wrapper': tripType === 'multiCity' || isFirstExchangeOriginDestinationFlown});
        return (
            <div className={classNames}>
                {this.renderRemove()}
                <AirDestinationEditor
                    {...this.props}
                    schema={child}
                    onChange={this.handleChange}
                    value={value}
                    errors={errors}
                    originAutocomplete={this.originAutocomplete}
                    destinationAutocomplete={this.destinationAutocomplete}
                    companyId={companyId}
                    disableRecentSearches={disableRecentSearches || index !== 0}
                />
            </div>
        );
    }
}
