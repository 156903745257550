import modals from 'midoffice/modals';

'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/Modal';

import NewDetails from 'midoffice/components/NewDetails';
import {getBooking} from 'midoffice/bookings/selectors';
import Loader from 'airborne/search2/Loader';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {genericError} from 'airborne/search2/helpers/apiError';


@connect((state, props)=> {
    return {
        booking: getBooking(state, props),
    };
})
export default class BookingRateDetailsModal extends React.Component {
    static propTypes = {
        booking: PropTypes.object.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    state = {
        booking: null,
        loading: true
    }

    componentDidMount() {
        this.getBookingData()
            .catch(() => {
                this.props.onHide();
                modals.alert(genericError(), 'danger');
            });
    }

    async getBookingData() {
        const {booking} = this.props;
        const bookingData = await air(
            'GET',
            '/hotels/get_reservation_details/',
            {
                'booking_uid': booking.id,
            },
            'aft'
        );
        this.setState({
            booking: bookingData,
            loading: false
        });
    }

    render() {
        const {onHide} = this.props;
        const {loading, booking} = this.state;
        return (<Modal show onHide={onHide} className="modal--rate">
            <Modal.Header closeButton>
                <Modal.Title>
                    {gettext('Rate Details')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {loading
                    ? <Loader light wrapped/>
                    : <NewDetails {...booking.rate_details} />
                }
            </Modal.Body>
        </Modal>);
    }
}
