import sortBy from 'lodash/sortBy';
import memoize from 'lodash/memoize';
import api from 'midoffice/helpers/api';
import settings from 'airborne/settings';

const fetchCodes = memoize(function fetchCodes(endpoint) {
    const params = {id: `generic_code_autocomplete_${endpoint}`};
    return api('GET', `/api/${endpoint}`, params);
});

export class CodesAutocomplete {
    constructor(endpoint) {
        this.endpoint = endpoint; // eslint-disable-line immutable/no-mutation
    }

    filterCode(query, el) {
        return el.value.indexOf(query) === 0;
    }

    ignoreSelected(selectedValues, codes) {
        return codes && codes.filter(el => !selectedValues.includes(el.value));
    }

    query(query, autocompleteExtra = {}) {
        let {selectedValues} = autocompleteExtra;

        selectedValues = selectedValues || [];
        const filterFn = this.filterCode.bind(null, query.toUpperCase());
        return fetchCodes(this.endpoint)
            .then(this.parseResponse, () => Promise.reject('Server error'))
            .then(codes => this.ignoreSelected(selectedValues, codes))
            .then(codes => codes.filter(filterFn))
            .then(codes => (codes.length ? this.sort(codes, query) : Promise.reject('No results.')));
    }

    sort(codes) {
        return codes;
    }

    parseResponse(data) {
        return data.items.map(el => {
            return {value: el.value, label: el.value};
        });
    }
}

export const IataAutocomplete = new CodesAutocomplete('iata-codes/');

export class ChainsAutocomplete extends CodesAutocomplete {
    filterCode(query, el) {
        const label = el.label.toLowerCase();
        const code = el.value.toLowerCase();
        const queryString = query.toLowerCase();
        return label.includes(queryString) || code === queryString;
    }

    parseResponse(data) {
        return data.items.map(el => {
            return {value: el.id, label: `${el.id} - ${el.name}`};
        });
    }

    sort(codes, query) {
        const queryString = query.toUpperCase();
        return sortBy(codes, ({value, label}) => [!(value === queryString), label]);
    }
}

export class TSPMAutocomplete extends CodesAutocomplete {
    filterCode(query, el) {
        return el.value.toUpperCase().indexOf(query) !== -1;
    }

    parseResponse(data) {
        return data.items.map(el => {
            return {value: el, label: el};
        });
    }
}

export class OverridesTSPMAutocomplete extends CodesAutocomplete {
    filterCode(query, el) {
        return el.value.toUpperCase().indexOf(query) !== -1;
    }

    ignoreSelected(selectedValues, codes) {
        return codes && codes.filter(el => !selectedValues.includes(el.value));
    }

    parseResponse(data) {
        return data.items.map(el => {
            return {value: el, label: el};
        });
    }

    query(query, autocompleteExtra = {}) {
        let {selectedValues} = autocompleteExtra;

        selectedValues = selectedValues.map(value => value.value);
        const filterFn = this.filterCode.bind(null, query.toUpperCase());
        return fetchCodes(this.endpoint)
            .then(this.parseResponse, () => Promise.reject('Server error'))
            .then(codes => this.ignoreSelected(selectedValues, codes))
            .then(codes => codes.filter(filterFn))
            .then(codes => (codes.length ? this.sort(codes, query) : Promise.reject('No results.')));

    }
}

function reportEmpty(result) {
    return (result && result.length > 0)
        ? result
        : 'No results.';
}

export function parseBrands(data) {
    const {
        match_data: {suggestions = []},
    } = data;
    return suggestions.map(brand => {
        return {value: brand.id, label: brand.name};
    });
}

export class BrandsAutocomplete {
    async query(term, {entityId}) {
        const url = '/api/agencies/brands/get_autocomplete/';
        const data = {term, entity_id: entityId, locale: settings.USER_LOCALE};

        try {
            const response = await api('GET', url, {data, id: 'brands_autocomplete'});
            return reportEmpty(parseBrands(response));
        }
        catch ({request}) {
            if (request.isAborted) {
                return null;
            }
            throw String('Server error');
        }
    }
}
