import React from 'react';
import moment from 'moment';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';

const FlownFlights = ({originDestinations}) => {
    const {pickUp: origin, dropOff: destination, departureDate} = originDestinations[0];

    return (<>
        <h5 className="text-right">
            <strong>{gettext('Already Flown Flight(s)')}</strong>
            <hr className="ag-home-form__hr" />
        </h5>
        <table className="table talbe-condensed">
            <tbody>
                <tr>
                    <th>{gettext('Origin')}</th>
                    <td>{origin.label}</td>
                </tr>
                <tr>
                    <th>{gettext('Destination')}</th>
                    <td>{destination.label}</td>
                </tr>
                <tr>
                    <th>{gettext('Depart Date/Time')}</th>
                    <td>{moment(departureDate, settings.DEFAULT_DATE_FORMAT).format(settings.SHORT_DATE_FORMAT)}</td>
                </tr>
            </tbody>
        </table>
    </>);
};

FlownFlights.propTypes = {
    originDestinations: PropTypes.array
};

export default FlownFlights;
