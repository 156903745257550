import settings from 'airborne/settings';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function showRates(provider) {
    return provider === 'hrs' || settings.GDS_LIKE_PROVIDERS.includes(provider);
}

export default class RateCode extends React.Component {
    static propTypes = {
        rate: PropTypes.string,
        room: PropTypes.string,
        client: PropTypes.bool,
        tmc: PropTypes.bool,
        membersOnly: PropTypes.bool,
        inline: PropTypes.bool,
        provider: PropTypes.string.isRequired,
    }

    render() {
        const {rate, room, client, tmc, membersOnly, inline, provider} = this.props;
        if (!showRates(provider)) {
            return null;
        }
        const cls = classnames({
            'highlight-purple': membersOnly,
            'h-rate__code--blue': tmc,
            'h-rate__code--green': client,
        });
        const Wrap = inline ? 'span' : 'div';
        return (<Wrap className="h-rate__code">
            {room}
            <span className={cls}>{rate}</span>
        </Wrap>);

    }
}

