import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React from 'react';
import gettext from 'airborne/gettext';
import {providerCode} from 'midoffice/helpers/format';

import AnnotateTooltip from './AnnotateTooltip';


class Wrapper extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.element,
    };

    render() {
        const {className, children} = this.props;
        return className
            ? <div className={className}>{children}</div>
            : children;
    }
}

export default class ProviderLabel extends React.Component {
    static propTypes = {
        id: PropTypes.any,
        provider: PropTypes.string.isRequired,
        show: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        const {show, className, id, provider} = this.props;
        if (!show) { return null;}

        return (<Wrapper className={className}>
            <AnnotateTooltip id="provider">
                <span className="hotel-rate__source">
                    {providerCode(provider)}
                </span>
                <span>
                    {gettext('Source: {provider}', {provider: capitalize(provider)})}
                    <br/>
                    {id && gettext('Code: {id}', {id})}
                </span>
            </AnnotateTooltip>
        </Wrapper>);
    }
}
