import axios from 'axios';

export const makeGposRequest = async (bookingUid) => {
    try {
        const localApplicationUrl = 'http://localhost:9999/GPoS/api/punchout';
        const requestBody = {
            // Constants: check GG-30292 for documentation and details
            'ShowMainMenu': 'Y',
            'Method': 'Command.TripSourceAir',
            // Market and & GDS are setting defaults: GPOS will deduce the correct
            // values from our booking, but for now if we don't send them it will ask
            // the agent and we want to avoid that.
            'Market': 'AGM',
            'GDS': 1,

            'BookingUID': bookingUid,
        };

        await axios.post(localApplicationUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};
