import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import format  from 'midoffice/helpers/format';

class RateRow extends React.Component {
    static propTypes = {
        rate: PropTypes.object,
    };
    render() {
        const {rate} = this.props;
        return (<tr>
            <td>{rate['rate_data'].currency} {rate['rate_data'].total}</td>
            <td>
                <div className="table__item-sub-header">
                    <strong>{gettext(rate['hotel_data'].name)}</strong>
                </div>
                <div>
                    <strong>{gettext(rate['rate_data'].rate_promoline)}</strong>
                </div>
                <p>
                    {gettext(rate['rate_data'].rate_description)}
                </p>
            </td>
        </tr>);
    }
}

class Offer extends React.Component {
    static propTypes = {
        offer: PropTypes.object,
        pnr: PropTypes.string,
        onChange: PropTypes.func,
    };
    checkData (data) {
        return data ? gettext(data) : '-';
    }

    resend = () => {
        const {onChange, offer, pnr} = this.props;
        onChange(offer, offer.rates, pnr);
    }

    render() {
        const {offer} = this.props;
        return (
            <tr>
                <td>
                    {offer.date_sent ? format.date(offer.date_sent, 'localeLong') : '-'}
                </td>
                <td className="text-sm">
                    <div>
                        <strong>{gettext('Recipient Email:')}</strong> {this.checkData(offer.traveler_email)}
                    </div>
                    <div>
                        <strong>{gettext('Sender Email:')}</strong>  {this.checkData(offer.agent_email)}
                    </div>
                    <div>
                        <strong>{gettext('Traveler Name:')}</strong> {this.checkData(offer.traveler_name)}
                    </div>
                    <div>
                        <strong>{gettext('CC:')}</strong> {offer.cc ? offer.cc.join(' ') : '-'}
                    </div>
                    <div>
                        <strong>{gettext('BCC:')}</strong> {offer.bcc ? offer.bcc.join(' ') : '-'}
                    </div>
                    <div>
                        <strong>{gettext('Note:')}</strong> {this.checkData(offer.custom_text)}
                    </div>
                    {offer['landing_page_url'] && <div>
                        <strong>{gettext('Offers Link:')}</strong>
                        <a href={offer['landing_page_url']} target="_blank">
                            {gettext(' Open ')}
                            <span className="glyphicons glyphicons-new-window" />
                        </a>
                        {offer.ttl && <em> ({format.getTimeUntilExpiration(offer.ttl)}{gettext(' until expiration')})</em>}
                    </div>}
                </td>
                <td>
                    <table className="table table--light">
                        <thead>
                            <tr>
                                <th style={{'width': '80px'}}>{gettext('Price')}</th>
                                <th>{gettext('Hotel / Rate Description')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {offer.rates && offer.rates.map((rate) => {
                                return (<RateRow  key={rate.rate_data.rate_key} rate={rate} />);
                            })}
                        </tbody>
                    </table>
                </td>
                <td className="text-center">
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={this.resend}
                    >
                        {gettext('Resend')}
                    </Button>
                </td>
            </tr>
        );
    }
}

export default Offer;
