import React from 'react';

import PropTypes from 'prop-types';
import ChangePasswordEditor from './ChangePasswordEditor';
import {noErrors} from 'midoffice/newforms/helpers';

import {connect} from 'react-redux';

import {changePasswordValue, sendNewPassword} from 'airborne/store/modules/user_settings/actions/changePassword';

import changePasswordSchema from './ChangePasswordSchema';
import {mapStateToProps} from './helpers/formHelpers';

@connect((state) => mapStateToProps(state, 'changePassword'), {changePasswordValue, sendNewPassword})
class ChangePassword extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        errors: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        changeOptions: PropTypes.func,
        changePasswordValue: PropTypes.func,
        sendNewPassword: PropTypes.func,
    };

    handleChange = ({value, errors}) => {
        const newErrors = changePasswordSchema.fields.password2.validate(value.password1, value.password2);
        this.props.changePasswordValue(value, Object.assign(errors, newErrors));
    };

    handleSubmit = event => {
        event.preventDefault();
        const {changePasswordValue, value} = this.props;
        const formErrors = changePasswordSchema.validate(
            this.props.value
        );
        const password2 = changePasswordSchema.fields.password2.validate(value.password1, value.password2);
        const errors = {...formErrors, ...password2};
        if (noErrors(errors)) {
            this.props.sendNewPassword();
        }
        else {
            changePasswordValue(this.props.value, errors);
        }
    };

    render() {
        const {loading, value, errors} = this.props;
        return (
            <ChangePasswordEditor
                value={value}
                errors={errors}
                schema={changePasswordSchema}
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                loading={loading}/>
        );
    }
}

export default ChangePassword;
