import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import {wrapLinks} from './helpers/fareRules';

import TabsWithContent, {Content, MenuItem, TabsMenu} from 'midoffice/components/TabsWithContent';

class FareComponents extends React.Component {
    static propTypes = {
        components: PropTypes.arrayOf(PropTypes.shape({
            fareBasisCode: PropTypes.string.isRequired,
            data: PropTypes.string,
        })),
    }
    render() {
        const {components} = this.props;

        return components.map(({fareBasisCode, data}) => (<div key={fareBasisCode}>
            <h5><strong>{fareBasisCode}</strong></h5>
            {data
                ? <pre>{wrapLinks(data)}</pre>
                : <div>
                    {gettext('We couldn’t load rules for this fare component')}
                </div>}
        </div>));
    }
}

export default class FareRules extends React.Component {
    static propTypes = {
        // vertical tabs
        tabs: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            // array of fareBasisCodes in array of possible origin destinations
            fareBasisCodesByOD: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
            // array of Fare Rules in array of possible origin destinations
            dataByOD: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
                data: PropTypes.string,
                fareBasisCode: PropTypes.string,
            }))),
        })),
        // horizontal tabs
        ODTabs: PropTypes.arrayOf(PropTypes.string),
    };

    render() {
        const {tabs, ODTabs} = this.props;
        return (<>
            <TabsWithContent
                className="modal-body__tabs-list"
                activeClass="modal-body__tabs-list--active"
                noDefaultClasses
                isList>
                <TabsMenu className="modal-body__tabs">
                    {tabs.map(({name, key}) => (<MenuItem key={key}>
                        {name}
                    </MenuItem>))}
                </TabsMenu>
                <Content className="modal-body__tabs-content">
                    {tabs.map(tab => (<div key={tab.key}>
                        {tab.dataByOD.length > 1
                            ? (<TabsWithContent
                                className="tabs-inner"
                                noDefaultClasses
                                menuItemClassName="tabs-inner__item"
                                activeClass="tabs-inner__item--active"
                            >
                                <TabsMenu>
                                    {ODTabs.map((ODTab, index) => (
                                        <MenuItem key={index}>
                                            {ODTab}
                                            <span className="a-flight-code__item">
                                                {tab.dataByOD[index].map(({fareBasisCode}) => fareBasisCode)}
                                            </span>
                                        </MenuItem>))}
                                </TabsMenu>
                                <Content>
                                    {ODTabs.map((ODTab, index) =>
                                        <FareComponents
                                            components={tab.dataByOD[index]}
                                            key={index}
                                        />)}
                                </Content>
                            </TabsWithContent>)
                            : <FareComponents components={tab.dataByOD[0]}/>}
                    </div>))}
                </Content>
            </TabsWithContent>
        </>);
    }
}
