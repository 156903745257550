import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hideModal} from 'airborne/store/modules/header/actions/modal';
import {getModal} from 'airborne/store/modules/header/selectors/modal';

export default function makeModals(modals, getModalSelector=getModal) {

    function mapStateProps(state) {
        const {data={}, kind} = getModalSelector(state);
        return data.data ? {...data.data, kind} : {...data, kind};
    }

    class ModalContainer extends React.Component {
        static propTypes = {
            kind: PropTypes.string,
            onHide: PropTypes.func.isRequired,
        };

        render() {
            const {kind} = this.props;
            if (!kind) {
                return null;
            }
            const Modal = modals[kind];
            return (<Modal {...this.props} />);
        }
    }
    return connect(mapStateProps, {onHide: hideModal})(ModalContainer);
}
