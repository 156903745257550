import settings from 'airborne/settings';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {getCarModificationFlowBooking} from 'midoffice/car-bookings/selectors';


export function cancelForModifyBooking() {
    return async function cancelBookingD(dispatch, getState) {
        const modifiedBooking = getCarModificationFlowBooking(getState());
        try {
            const response = await air('POST', '/cars/cancel_reservation/', {
                'booking_uid': modifiedBooking.uid,
                'email': settings.USER.email,
            });
            const {
                booking_uid: bookingUid,
                cancellation_status: cancellationStatus
            } = response;
            dispatch({
                type: 'CARS_MODIFY_CANCEL',
                cancellationNumber: bookingUid,
                cancellationStatus,
            });
        }
        catch (response) {
            dispatch({type: 'CARS_MODIFY_CANCEL_FAIL', cancellationNumber: modifiedBooking.uid});
        }
    };
}

export default cancelForModifyBooking;
