import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Modal from 'react-bootstrap/Modal';
import Button from 'midoffice/components/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import PNRSearchResult from 'airborne/homepage2/PNRSearchResult/index';


import gettext from 'airborne/gettext';
import markup from 'airborne/markup';

import {isLoading} from 'airborne/search2/helpers/hotels';
import {getAllPnrs, isMultiPnr, isNoPnr, getEditablePnrIndex, isMaxPnrCount, isModification, getPnrErrors} from '../store/modules/homepage/selectors/pnrProfile';
import {getCompanyName, getCompanyOID} from 'airborne/store/modules/homepage/selectors/homepage';
import {discardPNR, openAddPnr, toPnrList, deletePnr} from 'airborne/store/modules/homepage/actions/pnr';
import {isAirExchangeFlow} from 'airborne/store/modules/exchange_air/selectors';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import {isCars} from 'airborne/store/modules/homepage/selectors/product';
import {resetSid} from 'airborne/store/modules/login/actions/login';
import {modifyCarBookingFlowQuit} from 'midoffice/car-bookings/modify/modifyActions';

//TODO GG-22305
class RetriveErrors extends React.Component {
    static propTypes = {
        errors: PropTypes.arrayOf(
            PropTypes.shape({
                pnr: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
            }),
        ),
    };
    state = {
        open: false,
    };

    getPnrs () {
        return this.props.errors.map(({pnr})=> pnr).join(', ');
    }

    handleClick = () => {
        this.setState({open: !this.state.open});
    };

    renderButton() {
        const {open} = this.state;
        return (<Button name="errorDetails" bsStyle="link" className="alert__btn-toggle" onClick={this.handleClick}>
            {open ? gettext('Hide details') : gettext('Show details')}
            <span className={open ? 'icon-double-arrow-up' : 'icon-double-arrow-down'} />
        </Button>);
    }

    render () {
        const {errors} = this.props;
        if (!errors || isEmpty(errors)) {
            return null;
        }
        return (
            <div className="alert alert-warning">
                <Glyphicons bsClass="glyphicon" glyph="info-sign" />
                {markup(gettext('Unable to retrieve PNR\'s **{pnrs}**. These PNR\'s has not been added to Travelers List.', {pnrs: this.getPnrs()}))}

                {this.renderButton()}

                <div className="alert__toggle">
                    <div className={classnames('well', {'hide': !this.state.open})}>
                        {errors.map(({pnr, message})=> (<div key={pnr}><strong>{pnr}:</strong> {message}</div>))}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        errors: get(getPnrErrors(state), 'form'),
        pnrs: getAllPnrs(state),
        editablePnrIndex: getEditablePnrIndex(state),
        isMultiPnr: isMultiPnr(state),
        isNoPnr: isNoPnr(state),
        canAddMore: !isMaxPnrCount(state),
        canMultiPnr: !isMultiPnr(state) && !isNoPnr(state) && !isModification(state) && !isAirExchangeFlow(state) && !isCars(state),
        canClose: !isLoading(state),
        noCloseButton: isAirExchangeFlow(state),
        companyName: getCompanyName(state),
        oid: getCompanyOID(state),
    };
}

@connect(mapStateToProps, function (dispatch, {isModal, onHide}) {
    return {
        onClearAll: () => {
            dispatch(discardPNR());
            dispatch(modifyCarBookingFlowQuit(false));
            return dispatch(resetSid());
        },
        onAdd: ()=> dispatch(openAddPnr(isModal)),
        onBackToPnrs: () => dispatch(toPnrList()),
        onRemovePnr: () => dispatch(deletePnr(0)),
        onHide: () => {
            dispatch(toPnrList());
            onHide();
        },
    };
})
export default class MultiPnrSearchResult extends React.Component {
    static propTypes = {
        editablePnrIndex: PropTypes.number,
        pnrs: PropTypes.array.isRequired,
        isMultiPnr: PropTypes.bool.isRequired,
        isModal: PropTypes.bool,
        isNoPnr: PropTypes.bool.isRequired,
        canAddMore: PropTypes.bool.isRequired,
        canMultiPnr: PropTypes.bool.isRequired,
        canClose: PropTypes.bool.isRequired,
        noCloseButton: PropTypes.bool,
        companyName: PropTypes.string.isRequired,
        oid: PropTypes.string,
        errors: PropTypes.array,
        value: PropTypes.object,

        onClearAll: PropTypes.func.isRequired,
        onBackToPnrs: PropTypes.func.isRequired,
        onAdd:  PropTypes.func.isRequired,
        onHide: PropTypes.func,
        onRemovePnr: PropTypes.func.isRequired,
        onCompanyChange: PropTypes.func.isRequired,
    };

    handleRemovePnr = () => {
        this.props.onRemovePnr();
        this.props.onHide();
    };

    handleClearAll = () => {
        const {onClearAll, onCompanyChange, value, errors} = this.props;
        onClearAll();
        onCompanyChange({value, errors});
    };

    renderHeader() {
        const {onBackToPnrs, editablePnrIndex, isMultiPnr} = this.props;

        if (!isMultiPnr || editablePnrIndex == null) {
            return null;
        }

        return (<div className="ag-form__header">
            <Button name="backToPrns" bsStyle="link"
                className="btn-link--narrow"
                onClick={onBackToPnrs}
            >
                <strong>{gettext('« Back to PNR List')}</strong>
            </Button>
        </div>);

    }

    renderContent() {
        const {editablePnrIndex, isMultiPnr} = this.props;
        return !isMultiPnr || editablePnrIndex !== null
            ? <PNRSearchResult />
            : this.renderList();
    }

    renderFooter() {
        const {
            onAdd,
            isMultiPnr,
            editablePnrIndex,
            canMultiPnr,
            canClose,
            isModal,
            noCloseButton,
        } = this.props;

        if (editablePnrIndex !== null) {
            return null;
        }

        return (<div className="ag-row">
            <div className="item">
                {!isModal && !noCloseButton && (<Button bsStyle="light" name="close" onClick={this.handleClearAll} disabled={!canClose}>
                    <Shortcut label={isMultiPnr ? gettext('Clear All') : gettext('Close')} shortcut={SHORTCUTS_LIST.Q} action={this.handleClearAll} hideShortcut={!canClose}/>
                </Button>)}
                {canMultiPnr && (<Button name="addPnr" bsStyle="link" onClick={onAdd} className="btn-link--narrow pull-right">
                    + {gettext('Add a traveler')}
                </Button>)}
            </div>
        </div>);
    }

    renderList() {
        const {onAdd, pnrs, companyName, oid, canAddMore} = this.props;
        return (
            <div className="ag-block-list">
                <header className="ag-block-list__header">
                    <table className="table ag-table">
                        <tbody>
                            <tr>
                                <th>{gettext('Travelers Company:')}</th>
                                <td>{companyName}</td>
                            </tr>
                            <tr>
                                <th>{gettext('OID/PCC:')}</th>
                                <td>{oid || '—'}</td>
                            </tr>
                        </tbody>
                    </table>
                </header>
                <h3 className="ag-block-list__title">
                    {gettext('Travelers List:')}
                    <Button bsSize="sm" bsStyle="secondary" className="pull-right" onClick={onAdd} name="addPnr" disabled={!canAddMore}>
                    + {gettext('Add a traveler')}
                    </Button>
                </h3>
                {pnrs.map((pnr, idx)=> <PNRSearchResult
                    key={pnr || idx}
                    index={idx}
                    preview
                />)}
            </div>
        );
    }

    renderModal() {
        const {onHide, pnrs} = this.props;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        return (<Modal onHide={onHide} backdrop="static" show animation={!isIE11}>
            <Modal.Header closeButton><h4 className="modal-title">{gettext('Add a traveler')}</h4></Modal.Header>
            <Modal.Body>
                <div className="ag-pnr-modal">
                    {this.renderHeader()}
                    {this.renderContent()}
                    {this.renderFooter()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="ag-pnr-modal__footer">
                    {pnrs.length === 1 && (
                        <Button bsStyle="link" name="removePnr" className="btn-link--narrow highlight-red pull-left text-sm" onClick={this.handleRemovePnr}>{gettext('Remove this traveler')}</Button>
                    )}
                    <Button bsStyle="light" name="close" onClick={onHide}>{gettext('Close')}</Button>
                </div>
            </Modal.Footer>
        </Modal>);
    }

    render() {
        const {isModal, errors} = this.props;
        if (isModal) {
            return this.renderModal();
        }

        return (
            <div>
                <RetriveErrors errors={errors}/>
                {this.renderHeader()}
                {this.renderContent()}
                {this.renderFooter()}
            </div>
        );
    }
}
