import partial from 'lodash/partial';
import {changeFormValue, sendChanges} from './userSettings';
import {setLang} from 'airborne/store/modules/header/actions/i18n';
import settings from 'airborne/settings';

export function sendPreferenses() {
    return function sendPreferensesD(dispatch, getState) {
        const preferences = getState().preferences.value;
        return dispatch(sendChanges('preferences', null, null, true))
            .then((success) => {
                settings.set({
                    USER: preferences,
                });
                success && dispatch(setLang(preferences['lang_code'], true));
            });
    };
}

export const changeOptions = partial(changeFormValue, 'preferences');
