import * as types from '../actionTypes';
import {
    prefillAndCallWithDefaults,
    resetSeparatedTickets,
    toDefaultAirPricing
} from 'airborne/store/modules/pricing_air/actions';
import {toAvailabilitySearch} from './navigation';
import {
    getAirAvailabilityFilters,
    getAirlineFilters,
    getAllianceFilters,
    getCurrentFlightOptions,
    getCurrentODStep,
    getFlightOptionsSelection
} from '../selectors';
import {
    applyAirAvailabilityFilters,
    extractAirlines,
    extractAlliances
} from "airborne/availability_air/helpers/filters";

import difference from "lodash/difference";
import intersection from "lodash/intersection";
import {setAirAvailabilityFilters} from "airborne/store/modules/availability_air/actions/filters";
import omit from "lodash/omit";

const setCurrentStep = (OD) => ({
    type: types.AIR_AVAILABILITY_SET_CURRENT_OD,
    OD,
});

export const setDefaultFlightOptionSelection = (length) => ({
    type: types.AIR_AVAILABILITY_SET_DEFAULT_FLIGHT_OPTIONS_SELECTION,
    length,
});

export const setFlightOptionsSelection = (selection) => ({
    type: types.AIR_AVAILABILITY_SET_FLIGHT_OPTIONS_SELECTION,
    selection,
});

export const resetFlightOptionsSelection = () => ({
    type: types.AIR_AVAILABILITY_RESET_FLIGHT_OPTIONS_SELECTION,
});

const addFlightOptionSelection = (flightOptionId, ODIndex) => ({
    type: types.AIR_AVAILABILITY_ADD_FLIGHT_OPTION_SELECTION,
    flightOptionId,
    ODIndex,
});

export const selectFlightOption = (flightOptionId, ODIndex) => (dispatch, getState) => {
    dispatch(addFlightOptionSelection(flightOptionId, ODIndex));

    const selection = getFlightOptionsSelection(getState());
    if (selection.every(Boolean)) {
        dispatch(toDefaultAirPricing());
        return dispatch(prefillAndCallWithDefaults());
    }

    dispatch(setCurrentStep(selection.findIndex(select => !select)));

    resetMismatchedFilters(dispatch, getState());
};

/**
 * Clears the filters applied on previous OD, that do not exist on current OD.
 * Thus, we can avoid the case when all flight options are hidden due to e.g. airline filter,
 * because none of another origin destination flight options belong to that airline.
 *
 * @param dispatch
 * @param state
 */
export const resetMismatchedFilters = (dispatch, state) => {
    const currentOD = getCurrentODStep(state);
    const flightOptions = getCurrentFlightOptions(state);
    const filtersValue = getAirAvailabilityFilters(state);

    let filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'airlines'), currentOD);
    const currentFlightOptionsAirlines = extractAirlines(filteredFlightOptions);
    const selectedAirlineFilters = getAirlineFilters(state);
    const mismatchedAirlinesExist = difference(selectedAirlineFilters, currentFlightOptionsAirlines).length;

    filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'alliances'), currentOD);
    const currentFlightOptionsAlliances = extractAlliances(filteredFlightOptions);
    const selectedAllianceFilters = getAllianceFilters(state);
    const mismatchedAlliancesExist = difference(selectedAllianceFilters, currentFlightOptionsAlliances).length;

    const mismatch = mismatchedAirlinesExist || mismatchedAlliancesExist;

    if (mismatch) {
        dispatch(setAirAvailabilityFilters({
            value: {
                ...filtersValue,
                ...(mismatchedAirlinesExist ? {airlines: intersection(selectedAirlineFilters, currentFlightOptionsAirlines)} : {}),
                ...(mismatchedAlliancesExist ? {airlines: intersection(selectedAllianceFilters, currentFlightOptionsAlliances)} : {}),
            }
        }));
    }
};


export const unselectFlightOption = (ODIndex) => (dispatch) => {
    dispatch(addFlightOptionSelection(null, ODIndex));
    dispatch(setCurrentStep(ODIndex));
};

export const changeFlightOptionSelection = (ODIndex) => (dispatch, getState) => {
    dispatch(toAvailabilitySearch());
    dispatch(unselectFlightOption(ODIndex));
    dispatch(resetSeparatedTickets());
    resetMismatchedFilters(dispatch, getState());
};

export const setCurrentPage = page => ({
    type: types.AIR_AVAILABILITY_SET_CURRENT_PAGE,
    page,
});
