/* global module */
import PropTypes from 'prop-types';
import React from 'react';
import {hot} from 'react-hot-loader';
import {connect, Provider} from 'react-redux';
import {Router} from 'react-router-dom';

import browserHistory from 'airborne/browserHistory';
import AppLoader from 'airborne/homepage2/AppLoader';
import {HighlightProvider} from 'airborne/homepage2/Highlight';
import Homepage from 'airborne/homepage2/Homepage';
import {onKeyUpSlash} from 'airborne/homepage2/helpers/autocomplete';
import Auth from 'airborne/login/Auth';
import {getStepLogin} from 'airborne/login/authSelectors';
import {MicroFrontEndsProvider} from 'airborne/microFrontEnds/MicroFrontEnds';
import StreamService from 'airborne/services/Stream';
import settings from 'airborne/settings';
import store from 'airborne/store';
import {isFeatureFlagsLoaded} from 'airborne/store/modules/featureFlags/selector';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getUserData, shouldShowTerms} from 'airborne/store/modules/login/actions/login';
import {setShortcutOptionKeyUp, setShortcutOptionKeyDown} from 'airborne/store/modules/navigation_shortcuts/actions';
import {bindOptionDefaultShortcut} from 'midoffice/components/shortcut/helper';

@connect(
    state => ({
        step: getStepLogin(state),
        stepLogin: state.auth.stepLogin,
        settings: state.globalSettings,
        isLoading: !isFeatureFlagsLoaded(state),
    }),
    {
        onKeyDown: setShortcutOptionKeyDown,
        onKeyUp: setShortcutOptionKeyUp,
        getUserData,
        showModal,
    }
)
class InitComponent extends React.Component {
    static propTypes = {
        step: PropTypes.string,
        settings: PropTypes.object,
        isLoading: PropTypes.bool.isRequired,
        onKeyDown: PropTypes.func.isRequired,
        onKeyUp: PropTypes.func.isRequired,
        showModal: PropTypes.func.isRequired,
        getUserData: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {onKeyDown, onKeyUp} = this.props;
        bindOptionDefaultShortcut(onKeyDown, onKeyUp);
        this.subscribeToUserDataChange();
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    subscribeToUserDataChange() {
        this.unsubscribe = StreamService.subscribe(async event => {
            if (event && event.type === 'IDENTITY_CHANGE') {
                try {
                    await this.props.getUserData(true);
                }
                catch (isPermissionsChanged) {
                    if (isPermissionsChanged) {
                        this.props.showModal('warningWithText', {
                            title: 'Notification',
                            message: 'Your permissions have been updated',
                            onDone: () => {location.reload();}
                        });
                        this.forceUpdate();
                    }
                }
            }
        });
    }

    unsubscribe = null;

    render() {
        const {isLoading} = this.props;
        if (isLoading) {
            return <AppLoader />;
        }

        const shouldRenderHome = Boolean(!shouldShowTerms() && settings.USER && settings.USER.id);

        return shouldRenderHome ? <Homepage /> : <Auth />;
    }
}

@hot(module)
export default class App extends React.Component {
    componentDidMount() {
        document.addEventListener('keyup', onKeyUpSlash, false);
    }
    componentWillUnmount() {
        document.removeEventListener('keyup', onKeyUpSlash, false);
    }

    render() {
        return (
            <Provider store={store}>
                <HighlightProvider>
                    <Router history={browserHistory}>
                        <MicroFrontEndsProvider
                            browserHistory={browserHistory}
                            getState={store.getState}
                            subscribe={store.subscribe}
                            settings={settings}
                        >
                            <InitComponent />
                        </MicroFrontEndsProvider>
                    </Router>
                </HighlightProvider>
            </Provider>
        );
    }
}
