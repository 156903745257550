import React from 'react';
import gettext from 'airborne/gettext';
import SelectTags from 'midoffice/newforms/widgets/SelectTags';
import StatelessAutocomplete from 'midoffice/data/StatelessAutocomplete';
import {getAirlineChoices} from './helpers';

export const airlinesAutocomplete = {
    ...StatelessAutocomplete,
    async query(query, ...rest) {
        const results = await StatelessAutocomplete.query(query, ...rest);

        const isAirlineCodeQuery = query.length === 2;

        if (isAirlineCodeQuery) {
            // Bring matched airline code to the top of the list
            return results.sort((a, b) => {
                const airlineCode = query.toUpperCase();
                if (a.value === airlineCode) {
                    return -1;
                }
                if (b.value === airlineCode) {
                    return 1;
                }

                return 0;
            });
        }

        return results;
    },
};

export default class AirAirlineCodesField extends React.Component {

    airlineChoices = getAirlineChoices();

    render() {
        return (
            <SelectTags.Field
                label={gettext('Airline codes')}
                labelCol={3}
                choices={this.airlineChoices}
                name="airlineCodes"
                autocompleteSource={airlinesAutocomplete}
            />
        );
    }
}
