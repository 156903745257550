import {createSchema} from 'midoffice/newforms/helpers';
import {ArrayField, CharField, BooleanField, NumberField} from 'midoffice/newforms/fields-stateless';
import {required} from 'midoffice/newforms/helpers';

const TaxesField = {
    ...ArrayField,
    child: createSchema({
        fields: {
            designator: CharField,
            refundable: BooleanField,
            newPrice: NumberField,
            oldPrice: NumberField,
            ticketPrice: NumberField,
        },
    }),
};

export default function createManualExchangeSchema() {
    return createSchema({
        fields: {
            taxes: TaxesField,
            newBaseFarePrice: NumberField,
            originalBaseFarePrice: required(NumberField),
            baseFareDifference: NumberField,
            residualTaxes: NumberField,
            addCollectTaxes: NumberField,
            penalty: required(NumberField),
            commission: NumberField,
            totalCollect: NumberField,
            totalRefund: NumberField,
        },
    });
}
