import React from 'react';
import PT from 'prop-types';
import Mousetrap from 'mousetrap';

export default class ShortcutWrapper extends React.Component {
    static propTypes = {
        children: PT.any,
        shortcuts: PT.arrayOf(PT.shape({
            shortcut: PT.oneOfType([
                PT.arrayOf(PT.string),
                PT.string,
            ]),
            action: PT.func,
        })),
    };

    componentDidMount() {
        this.bindShortcut();
        Mousetrap.prototype.stopCallback = () => false;
    }

    componentWillUnmount() {
        const {shortcuts} = this.props;
        if (shortcuts) {
            shortcuts.forEach(({shortcut}) => Mousetrap.unbind(shortcut));
        }
    }

    bindShortcut() {
        const {shortcuts} = this.props;
        if (shortcuts.length) {
            shortcuts.forEach(({shortcut, action}) => Mousetrap.bind(shortcut, action));
        }
    }

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>{children}</React.Fragment>
        );
    }
}
