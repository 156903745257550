import React from 'react';


export default function hasGrandChildren(children) {
    if (!children) {
        return false;
    }

    const grandChildrenCount = React.Children.map(
        children,
        (child)=> child ? React.Children.count(child.props.children) : 0
    ).reduce((a, b)=> (a + b), 0);

    return grandChildrenCount > 0;
}
