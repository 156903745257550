import React from 'react';
import PropTypes from 'prop-types';
import wrap from 'airborne/search2/LazyComponent';
import {loadHotelSearch} from 'airborne/search2/lazyloader';
import WaitBooking from 'airborne/search2/WaitBooking';

class Loader extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };
    render() {
        const {children} = this.props;
        return children || (<WaitBooking show />);
    }
}

function loadCheckout() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./index');
            resolve(loaded);
        }, 'checkout2');
    });
}

function load() {
    return Promise.all([loadCheckout(), loadHotelSearch()])
        .then(([checkout])=> checkout);
}

const Checkout = wrap(load, 'default', Loader);
export default Checkout;
