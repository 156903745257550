import {
    UPDATE_PAYMENT_REQUEST,
    UPDATE_PAYMENT_REQUEST_SUCCESS,
    UPDATE_PAYMENT_REQUEST_FAILUIRE
} from '../actionTypes';

export function updatePaymentRequest() {
    return {type: UPDATE_PAYMENT_REQUEST, message: null, loading: true};
}

export function updatePaymentSuccess() {
    return {type: UPDATE_PAYMENT_REQUEST_SUCCESS, message: null, loading: false};
}

export function updatePaymentFailure({message}) {
    return {type: UPDATE_PAYMENT_REQUEST_FAILUIRE, message, loading: false};
}
