import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {gettext} from 'airborne/gettext';

import Button from 'react-bootstrap/Button';

export default class CollapsedText extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        collapse: PropTypes.bool,
    }

    static defaultProps = {
        collapse: false,
    }

    state = {
        isOpen: false,
    };

    getContentClassName() {
        const {isOpen} = this.state;
        const {collapse} = this.props;
        return classnames({
            ['checkout__info__toggle']: collapse,
            ['checkout__info__toggle--full']: collapse && isOpen,
        });
    }

    handleClick = ()=> {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const {isOpen} = this.state;
        const {collapse} = this.props;

        return (<div>
            <div className={this.getContentClassName()}>
                {this.props.children}
            </div>
            {collapse && (
                <Button className="btn-link--narrow" variant="link" size="sm" onClick={this.handleClick}>
                    {isOpen ? gettext('Show Less') : gettext('Show More')}
                    {isOpen ? (
                        <span className="icon-double-arrow-up" />
                    ) : (
                        <span className="icon-double-arrow-down" />
                    )}
                </Button>
            )}
        </div>);
    }
}
