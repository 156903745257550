import React from 'react';
import PropTypes from 'prop-types';

function getComponent(module, name) {
    return module && module[name];
}

export default function wrap(asyncRequire, name='default', wrapper='div') {
    class LoadWrapper extends React.Component {
        static propTypes = {
            children: PropTypes.any,
        };

        componentDidMount() {
            asyncRequire().then((module)=> {
                this.module = module; // eslint-disable-line immutable/no-mutation
                this.forceUpdate();
            });
        }

        module = null;

        render() {
            const Component = getComponent(this.module, name);
            return React.createElement(
                wrapper,
                {},
                Component ? (<Component {...this.props} />) : React.Children.map(
                    this.props.children,
                    (child)=> React.cloneElement(child, {progress: true})
                )
            );
        }
    }
    return LoadWrapper;
}
