'process i18n';
import partial from 'lodash/partial';
import gettext from 'airborne/gettext';
import api from 'midoffice/helpers/api';

import '../types';

import {showError, showInfo, showLoader, hideLoader, hideModal} from 'midoffice/data/actions/modals';
import {fetchEntry} from 'midoffice/data/actions/collection';

const endpoint = 'carBookings';
export const fetchBooking = partial(fetchEntry, endpoint);


function informSuccess() {
    return showInfo(
        gettext('Booking canceled'), 'success'
    );
}

function informError() {
    return showError(
        gettext('Cancelation failed.'),
    );
}

export function cancelBooking(params) {
    return function cancelBookingD(dispatch) {
        dispatch({type: 'LOADING', endpoint: 'carBookingModification', id: params.booking_uid});
        dispatch(showLoader(
            gettext('Canceling reservation')
        ));

        return api('POST', '/latest/cars/cancel_reservation/', {data: params})
            .then((data)=> {
                dispatch(fetchBooking(params.booking_uid));
                dispatch({type: 'LOADED', endpoint: 'carBookingModification'});
                return data;
            }, (response)=> {
                dispatch({type: 'NET_FAIL', endpoint: 'carBookingModification'});
                return Promise.reject(response);
            })
            .then(informSuccess, informError)
            .then(dispatch)
            .then(()=> dispatch(hideModal()))
            .then(()=> dispatch(hideLoader()));
    };
};
