const getExternalExtra = state => state.externalExtra;
const getExternalExtraForm = state => getExternalExtra(state).form;
const getExternalExtraDataModal = state => getExternalExtra(state).dataModal;

export const isBookingTableDisplaying = state => getExternalExtraForm(state).showBookingTable;
export const getFormErrorMessage = state => getExternalExtraForm(state).formErrorMessage;

export const isBookingDataLoading = state => getExternalExtraDataModal(state).loading;
export const getDataModalErrorMessage = state => getExternalExtraDataModal(state).errorMessage;
export const getSearchType = state => getExternalExtraDataModal(state).searchType;

export const getExternalRawBookingData = (state, uid) => {
    return getExternalExtraDataModal(state).data[uid];
};
export const getExternalBookingData = (state, uid) => {
    return getExternalExtraDataModal(state).externalBookingData[uid];
};
