import React, {lazy, Suspense} from 'react';
import {Spinner} from 'airborne/common/WaitBooking';

const Pricing = lazy(() => import('./Pricing'));

const Loader = () => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner product="air"/>
    </div>
);

const PricingPage = () => {
    return (
        <Suspense
            fallback={<Loader />}
        >
            <Pricing />
        </Suspense>
    );
};

export default PricingPage;
