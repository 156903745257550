import generateUUID from 'airborne/checkout2/helpers/uuid';
import {getRateDetails} from './rateDetails';
import {loadCheckoutData} from 'airborne/store/modules/checkout_cars/actions/checkoutData';
import browserHistory from 'airborne/browserHistory';


export function toCheckout() {
    return {type: 'CARS_TO_CHECKOUT', uuid: generateUUID()};
}

export function bookRate(rateId) {
    return async function bookRateD(dispatch) {
        dispatch({type: 'CARS_CHECKOUT_LOADING', rateId});
        try {
            const checkoutData = dispatch(loadCheckoutData(rateId));
            const rateDetails = dispatch(getRateDetails(rateId, true));
            await Promise.all([checkoutData, rateDetails]);
            browserHistory.push('/ui/cars/checkout/');

            dispatch(toCheckout());
        }
        catch (error) {
            dispatch({type: 'CARS_CHECKOUT_FAIL'});
        }
    };
}
