const newRoutesList = [
    '/bookings/',
    '/ui/profile-settings/',
    '/ui/terms-conditions/',
    '/ui/my-bookings/',
    '/ui/homepage/',
    '/ui/hotels/search/',
    '/ui/cars/search/',
    '/ui/air/search/',
    '/ui/email_offers/',
    '/ui/air_availability/',
    '/ui/new-terms/',
    '/ui/rail/',
];

const searchesList = [
    '/ui/hotels/search/',
    '/ui/cars/search/',
    '/ui/air/search/',
    '/ui/air_availability/',
];

export function isNewRoute() {
    //in the old routing system we need to replace url. After complete removing old system
    // need to remove function
    return newRoutesList.some((route) => {
        return (location.pathname || '').indexOf(route) !== -1;
    });
}

export function isNeedHomeRedirect(profile) {
    return !profile.company && searchesList.some((route) => {
        return (location.pathname || '').indexOf(route) !== -1;
    });
}
