import React from 'react';
import PropTypes from 'prop-types';

import Glyphicons from 'midoffice/components/Glyphicons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


export default class Hint extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.any,
        question: PropTypes.bool,
    };

    renderTooltip() {
        return (
            <Tooltip id={this.props.id} >
                <div className="text-left">
                    {this.props.children}
                </div>
            </Tooltip>
        );
    }
    render() {
        const {question=false} = this.props;
        return (
            <OverlayTrigger placement="right" overlay={this.renderTooltip()}>
                <Glyphicons bsClass="glyphicon" glyph={question ? 'question-sign' : 'info-sign'} />
            </OverlayTrigger>
        );
    }

}
