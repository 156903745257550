/*global global */
import moment from 'moment';
import settings from 'airborne/settings';

export function configure(store, restart) {
    settings.set(global.formats);
    moment.locale(settings.LANGUAGE_CODE);

    const {i18n: oldI18N} = store.getState();
    const unsubscribe = store.subscribe(() => {
        const {i18n} = store.getState();

        if (i18n.lang !== oldI18N.lang) {
            unsubscribe();
            restart();
        }
    });
}
