'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';

import Loader from 'airborne/search2/Loader';

import Input from 'midoffice/newforms/widgets/Input';
import Tags from 'midoffice/newforms/widgets/Tags';
import Textarea from 'midoffice/newforms/widgets/Textarea';

import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {resendEmailOffer} from 'airborne/email_offers/actions/emailOffers';

import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';

import SendOffersSchema from './EmailOffersSchema';
import {format as formatPrice} from 'airborne/utils/price';
import gettext from 'airborne/gettext';

import {connect} from 'react-redux';


@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func,
        failed: PropTypes.bool,
        sent: PropTypes.bool,
    };

    render() {
        const {onSubmit, loading, sent, failed} = this.props;
        const extra = {inputCol: 7};

        return (
            <form className="form-horizontal" onSubmit={onSubmit}>
                <Input.Field name="to" label={gettext('Recipient Email')} {...extra} autoFocus />
                <Input.Field name="sender" label={gettext('Sender Email')} {...extra} />

                <Input.Field name="traveler_name" label={gettext('Traveler name')} {...extra} />
                <Tags.Field name="cc" label={gettext('CC')} {...extra} freeText />
                <Tags.Field name="bcc" label={gettext('BCC')} {...extra} freeText />
                <Textarea.Field name="custom_text" label={gettext('Note')} {...extra} />
                {failed && (
                    <p className="modal-body__form-group__error">
                        {gettext('We\'re sorry, our system encountered an error.')}
                        <br/>
                        {gettext('Your offer was not sent. Please try again in a few minutes.')}
                    </p>
                )}
                <div className="form-group__label-offset">
                    <Button variant="success" onClick={onSubmit} disabled={loading}>{gettext('Send Offers')}</Button>
                    {sent && (<span className="form-group__label-message">
                        <Glyphicons bsClass="glyphicon" glyph="ok"/> {gettext('Offers have been sent')}
                    </span>)}
                </div>
            </form>
        );
    }
}


function makeName(first, last) {
    return [first, last].filter((n)=> n).join(' ');
}

@connect(null, {resendEmailOffer})
export class ControlledEmailOffersModal extends React.Component {
    static propTypes = {
        offers: PropTypes.array,
        value: PropTypes.object,
        to: PropTypes.string,
        pnr: PropTypes.string,
        sender: PropTypes.string,
        'traveler_name': PropTypes.string,
        loading: PropTypes.bool,
        onSubmit: PropTypes.func,
        resendEmailOffer: PropTypes.func,
        onHide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { // eslint-disable-line immutable/no-mutation
            value: {
                ...props.value,
                to: props.to || '',
                sender: props.sender || '',
                'traveler_name': props['traveler_name'] || '',
            },
            errors: null,
            loading: false,
        };
    }

    sendNewOffer = (value) => {
        this.setState({loading: true, sent: false, failed: false});
        this.props.onSubmit(value).then(
            ()=> this.setState({sent: true}),
            ({body})=> {
                const validationErrors = pick(body, 'to', 'sender', 'cc', 'bcc', 'custom_text');
                if (!isEmpty(validationErrors)) {
                    return this.setState({errors: validationErrors});
                }
                this.setState({failed: true});
            }
        ).then(()=> this.setState({loading: false}));
    }

    handleChange = ({value, errors})=> {
        this.setState({value, errors});
    };

    handleSubmit = ()=> {
        const {resendEmailOffer, onSubmit, pnr} = this.props;
        const {value, errors} = SendOffersSchema.toRepresentation(this.state.value);
        if (noErrors(errors)) {
            onSubmit ? this.sendNewOffer(value) : resendEmailOffer(value, pnr);
        }
        else {
            this.setState({value, errors});
        }
    };

    renderOffers(offers, loading) {
        if (loading) { return <Loader/>; }

        return (
            <div>
                <hr/>
                <h5 className="form-secondary-title">{gettext('Offers List')}</h5>

                <table className="table table--light">
                    <thead>
                        <tr>
                            <th style={{width: '80px'}}>{gettext('Price')}</th>
                            <th>{gettext('Hotel / Rate Description')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {offers && offers.map((offer)=> {
                            const rate = offer.rate || offer['rate_data'];
                            const hotel = offer.hotel || offer['hotel_data'];
                            return (
                                <tr key={`${hotel.id}:${rate.rateKey}`}>
                                    <td>{formatPrice(rate.average, rate.currency)}</td>
                                    <td>
                                        <div className="float-panel__item__title">{hotel.name}</div>
                                        <div className="no-margin"><strong>{rate['rate_promoline'] || rate.ratePromoline}</strong></div>
                                        <small>{rate['rate_description'] || rate.rateDescription}</small>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        const {value, errors, loading: formLoading, sent, failed} = this.state;
        const {onHide, offers, loading} = this.props;
        return (
            <Modal show onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{gettext('Email Offers')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Editor
                        schema={SendOffersSchema}
                        value={value}
                        errors={errors}
                        loading={formLoading}
                        sent={sent}
                        failed={failed}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit} />
                    {this.renderOffers(offers, loading)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>{gettext('Close')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export function mapStateProps(state) {
    const profile = getPnrProfile(state, 0);
    const {'pnr_name_first': firstName, 'pnr_name_last': lastName} = profile || {};
    const name = makeName(firstName, lastName);

    return {
        'to': profile && profile.email,
        'traveler_name': name,
    };
}


export default connect(mapStateProps)(ControlledEmailOffersModal);
