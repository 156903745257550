import reduce from 'lodash/reduce';


function isDataKey(key) {
    return key.startsWith('data-');
}

function dataKeyName(key) {
    return key.replace('data-', '');
}

export default function dataset(node) {
    /* IE9, IE10 are not supporting HTML5 dataset. This function handles pure attributes for IE */
    if (node.dataset) { return node.dataset; }
    return reduce(node.attributes, (acc, attr)=> {
        const key = attr.nodeName;
        const value = attr.nodeValue;

        if (isDataKey(key)) {
            acc[dataKeyName(key)] = value;
        }

        return acc;
    }, {});
}
