import {createSelector} from 'reselect';

export const getExternalBookings = state => state.externalBookings;
export const getExternalBookingFilters = state => state.externalBookingFilters;

export const isExternalBookingsLoading = state => getExternalBookings(state).loading;
export const isExternalBookingError = state => getExternalBookings(state).error;
export const getExternalBookingsPageBy = state => getExternalBookings(state).pageBy;

export const getExternalBookingItems = createSelector(
    getExternalBookings,
    bookings => bookings.paged.map((id)=> bookings.hash[id])
);
export const isAnyExternalBookings = state =>
    Boolean(getExternalBookingItems(state).length);

export const getExternalErrorMessages = state =>
    getExternalBookings(state)
        .errors?.errors.map(error => error.message);


export const getExternalWarnings = state =>
    getExternalBookings(state).warnings;
