import {
    COUNTER_OFFER_REQUEST,
    COUNTER_OFFER_REQUEST_SUCCESS,
    COUNTER_OFFER_REQUEST_FAILUIRE,
    COUNTER_OFFER_CLEANUP_STATE, COUNTER_OFFER_BACKUP_BOOKING
} from '../actionTypes';

export const counterofferRequest = (requestType) => ({
    type: COUNTER_OFFER_REQUEST,
    requestType,
});

export const counterofferRequestSuccess = () => ({
    type: COUNTER_OFFER_REQUEST_SUCCESS,
});

export const counterofferRequestFailure = (error) => ({
    type: COUNTER_OFFER_REQUEST_FAILUIRE,
    error,
});

export const cleanupCounteofferState = () => ({
    type: COUNTER_OFFER_CLEANUP_STATE,
});

export const saveBookingsBackup = (booking) => ({
    type: COUNTER_OFFER_BACKUP_BOOKING,
    booking,
});
