import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import Button from 'midoffice/components/Button';


export default class ButtonWithModal extends React.Component {
    static propTypes = {
        modal: PropTypes.node.isRequired,
        progress: PropTypes.bool,
        children: PropTypes.any,
    };
    state = {
        showModal: false,
    };

    handleClick = ()=> {
        this.setState({showModal: true});
    };

    handleHide = ()=> {
        this.setState({showModal: false});
    };

    render() {
        const {showModal} = this.state;
        const {modal, ...props} = this.props;
        return (<Fragment>
            {showModal && React.cloneElement(modal, {'onHide': this.handleHide})}
            <Button {...omit(props, ['index', 'dispatch'])} onClick={this.handleClick} />
        </Fragment>);

    }
};
