import * as types from '../actionTypes';
import {setCurrentPage} from './selection';

export const setAirAvailabilityFilters = ({value}) => (dispatch) => {
    dispatch(setCurrentPage(1));
    dispatch({
        type: types.AIR_AVAILABILITY_SET_FILTERS,
        value,
    });
};
