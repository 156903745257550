'process i18n';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import gettext from 'airborne/gettext';

import {mapEmailOfferButton} from 'airborne/search2/helpers/offers';

import Button from 'midoffice/components/Button';
import Glyphicons from 'midoffice/components/Glyphicons';

import {addOffer, removeOffer} from 'airborne/store/modules/search_hotels/actions/offers';


export class ControlledEmailOffer extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        addOffer: PropTypes.func.isRequired,
        removeOffer: PropTypes.func.isRequired,
        selected: PropTypes.bool,
    };

    handleClick = () => {
        const {hotelId, rateKey, selected, addOffer, removeOffer} = this.props;

        if (selected) {
            removeOffer(hotelId, rateKey);
        }
        else {
            addOffer(hotelId, rateKey);
        }
    }

    render() {
        const {selected, show} = this.props;
        const className = classnames('border-lnk', {
            'border-lnk--selected': selected
        });

        if (!show) { return null; }
        return (
            <Button
                bsStyle={null}
                name="offer-button"
                className={className}
                onClick={this.handleClick}
            >
                <Glyphicons bsClass="glyphicon" glyph="envelope" /> {gettext('Add to Offer')}
            </Button>
        );
    }
}


const EmailOffer = connect(mapEmailOfferButton, {addOffer, removeOffer})(ControlledEmailOffer);
export default EmailOffer;
