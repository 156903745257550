import 'airborne/search2/type';
import settings from 'airborne/settings';
import get from 'lodash/get';
import defer from 'lodash/defer';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {getRate} from 'airborne/search2/helpers/rates';
import {currentCompany} from 'airborne/homepage2/helpers/search';

import {rateRecommendation} from 'airborne/search2/helpers/hyatt';
import {parse} from 'airborne/types';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {checkoutForm} from 'airborne/checkout2/helpers/checkoutForm';

function settingEnabled(state) {
    return rateRecommendation(state);
}

function recommendEnabled(state, hotelId, rateKey) {
    if (!state || !settingEnabled(state)) {
        return false;
    }
    const book = getRate(state, hotelId, rateKey, false);
    return (
        settings.FAKE_RATE_RECOMMENDATIONS || !get(book, 'commission.commissionable')
    ) && book;
}

function fakeResponse(book) {
    return new Promise((resolve)=> {
        defer(()=> resolve({rates: [book], book}), 2000);
    });
}

function parseRates(rates, checkInInstructions) {
    return rates.map(rate => ({...rate, 'check_in_instructions': checkInInstructions}));
}

export function recommendedRate(state, extraParams, hotelId, rateKey) {
    if (!recommendEnabled(state, hotelId, rateKey)) {
        return Promise.reject(null);
    }
    const {hotels} = state;
    const destination = getDestination(state);
    const book = getRate(state, hotelId, rateKey, false);
    if (settings.FAKE_RATE_RECOMMENDATIONS) {
        return fakeResponse(book);
    }
    const params = {
        'hotel_id': hotelId,
        'guest_count': destination.guests,
        'rate_key': rateKey,
        'configuration_id': getSelectedConfiguration(state) || currentCompany(state),
        'search_id': hotels.ratesSid[hotelId],
        'date_format_type': settings.USER['date_format'],
    };
    return air('GET', '/hotels/get_recommended_rate/', {
        ...params,
        ...extraParams,
    }).then((data)=> {
        const {rates, check_in_instructions: checkInInstructions} = data;
        return {rates: parseRates(rates, checkInInstructions), book};
    });
}


function loaded(hotelId, response, lcard, LCardChosen) {
    if (!response.rates.length) {
        throw new Error();
    }
    const rate = parse('rateDetails', response);
    return {type: 'RATE_RECOMMENDATION_LOADED', data: rate, id: hotelId, rateKey: rate['rate_key'], lcard, ...(LCardChosen ? {LCardChosen} : {})};
}

function dispatchIndexes(dispatch, indexes) {
    return function dispatchIndexes(action) {
        indexes.forEach((index)=> {
            dispatch({...action, index});
        });
    };
}

export function getMultiRecommendations(hotelId, rateKey, indexes, singleIndex) {
    return function getMultiRecommendationsD(dispatch, getState) {
        const dispatchAll = dispatchIndexes(dispatch, indexes);
        dispatchAll({type: 'RATE_RECOMMENDATION_LOADING'});
        const checkoutFormData = singleIndex ? checkoutForm(getState(), indexes[0]) : {};
        const LCardChosen = singleIndex && checkoutFormData?.value['loyalty_card'];

        return Promise.all(
            [true, false].map((lcard)=>
                recommendedRate(getState(), {'loyalty_card_accepted': lcard}, hotelId, rateKey)
                    .then((response)=> dispatchAll(loaded(hotelId, response, lcard, LCardChosen)))
                    .catch(()=> dispatchAll({type: 'RATE_RECOMMENDATION_FAILED', lcard})),
            )
        );
    };
}

export function getRecommendations(hotelId, rateKey, index, singleIndex = false) {
    return getMultiRecommendations(hotelId, rateKey, [index], singleIndex);
}

export function rejectRecommendation(index) {
    return {type: 'RECOMMENDED_REJECT', index};
}

export function acceptRecommendation(index) {
    return {type: 'RECOMMENDED_ACCEPT', index};
}

export function rejectExcuse(value, index) {
    return {type: 'RECOMMENDED_EXCUSE', value, index};
}

export function recommendAgain(index) {
    return {type: 'RECOMMENDED_AGAIN', index};
}
