import PropTypes from 'prop-types';

export const bookingShape = PropTypes.shape({
    status: PropTypes.string.isRequired,
    'createdAt': PropTypes.string.isRequired,
    'cancelledAt': PropTypes.string,
    'bookerUser': PropTypes.shape({
        'email': PropTypes.string,
        'name': PropTypes.string
    }).isRequired,
    travelers: PropTypes.arrayOf(
        PropTypes.shape({
            'lastName': PropTypes.string.isRequired
        })
    ).isRequired,
    'gdsProfile': PropTypes.shape({
        'gdsName': PropTypes.string
    }).isRequired,
    pnr: PropTypes.shape({
        id: PropTypes.string.isRequired
    }),
    locator: PropTypes.string,
    provider: PropTypes.string.isRequired,
    system: PropTypes.string,
    company: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    originDestinations: PropTypes.arrayOf(
        PropTypes.shape({
            arrival: PropTypes.shape({
                date: PropTypes.string,
                time: PropTypes.string,
                airport: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired
            }).isRequired,
            departure: PropTypes.shape({
                date: PropTypes.string,
                time: PropTypes.string,
                airport: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        })
    ).isRequired,
    'fareGroup': PropTypes.shape({
        'originalTotal': PropTypes.number,
        'originalCurrency': PropTypes.string,
        'originDestinations':PropTypes.arrayOf(
            PropTypes.shape({
                'duration': PropTypes.object,
                'segments': PropTypes.arrayOf(PropTypes.shape(
                    {
                        'airportChange': PropTypes.bool,
                        'stopoverIndicator': PropTypes.string,
                        'departure': PropTypes.object,
                        'carrier': PropTypes.shape({
                            'code': PropTypes.string,
                            'name': PropTypes.string,
                            'flightNumber': PropTypes.string,
                        }),
                        'arrival': PropTypes.object,
                        'baggageAllowance': PropTypes.object,
                        'baggagePolicies': PropTypes.array,
                        'operatingCarrier': PropTypes.shape({
                            'code': PropTypes.string,
                            'name': PropTypes.string,
                            'flightNumber': PropTypes.string,
                        }),
                        'cabin': PropTypes.string,
                        'segmentIdRef': PropTypes.oneOfType([
                            PropTypes.number,
                            PropTypes.string,
                        ]),
                        'classOfService': PropTypes.string
                    }
                )),
                'includedServices': PropTypes.array,
                'optionalServices': PropTypes.array
            }).isRequired
        ).isRequired
    }).isRequired,
    payment: PropTypes.shape({
        'paymentMethod': PropTypes.string.isRequired,
        'creditCard': PropTypes.shape({
            number: PropTypes.string.isRequired,
        }),
    }),
    ticketingStatus: PropTypes.oneOf(['NOT_TICKETED', 'TICKETED', 'FAILED']),
});

export const bookingDetailsShape = PropTypes.shape({
    'pnr': PropTypes.shape({
        'id': PropTypes.string,
    }),
    'travelers': PropTypes.arrayOf(PropTypes.shape({
        'firstName': PropTypes.string,
        'lastName': PropTypes.string,
    })).isRequired,
    'tickets': PropTypes.arrayOf(PropTypes.shape({
        'createdAt': PropTypes.string.isRequired,
        'iata': PropTypes.string.isRequired,
        'number': PropTypes.string.isRequired,
        'status': PropTypes.string.isRequired,
        'travelerType': PropTypes.string,
        'ticketTotal': PropTypes.shape({
            'baseTotalAmount': PropTypes.number.isRequired,
            'currency': PropTypes.string.isRequired,
            'feeTotalAmount': PropTypes.number,
            'refundData': PropTypes.shape({
                'currency': PropTypes.string.isRequired,
                'refundDate': PropTypes.string.isRequired,
                'feesAndPenalties': PropTypes.shape({
                    'amount': PropTypes.number.isRequired,
                    'cancellationPenalty': PropTypes.number,
                }).isRequired,
                'totalRefund': PropTypes.shape({
                    'refund': PropTypes.number.isRequired,
                    'baseFareAmount': PropTypes.number.isRequired,
                    'taxes': PropTypes.arrayOf(PropTypes.shape({
                        'amount': PropTypes.number.isRequired,
                        'designator': PropTypes.string.isRequired,
                        'currency': PropTypes.string.isRequired,
                    })).isRequired
                }).isRequired
            })
        }).isRequired,
        'fareGroup': PropTypes.shape({
            'travelerGroup': PropTypes.shape({
                'travelerPrice': PropTypes.shape({
                    'totalAfterTaxes': PropTypes.number.isRequired,
                    'taxes': PropTypes.arrayOf(PropTypes.shape({
                        'amount': PropTypes.number.isRequired,
                        'designator': PropTypes.string.isRequired,
                    })),
                }).isRequired,
            }).isRequired,
        }).isRequired,
    })).isRequired,
    ticketingStatus: PropTypes.oneOf(['NOT_TICKETED', 'TICKETED', 'FAILED']),
});
