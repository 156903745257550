import React from 'react';

import PropTypes from 'prop-types';

import {injectFormContext} from 'midoffice/newforms/decorators';

import Input from 'midoffice/newforms/widgets/Input';
import Button from 'midoffice/components/Button';
import ErrorsBlock from './ErrorsBlock';

import gettext from 'airborne/gettext';

@injectFormContext
export default class ChangePasswordEditor extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const {onSubmit, loading, value} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 6,
            inputSize: '',
        };
        return (
            <div>
                <h5 className="page-section-title">
                    {gettext('Change Password')}
                    <span className="required-note">
                        <span className="highlight-red">*</span> {gettext('Required Information')}
                    </span>
                </h5>

                <form className="form-horizontal" onSubmit={onSubmit}>
                    <fieldset>
                        <Input.Field
                            name="old_password"
                            type="password"
                            label={gettext('Current Password')}
                            placeholder={gettext('Current Password')}
                            {...extra} />
                    </fieldset>
                    <fieldset>
                        <Input.Field
                            name="password1"
                            type="password"
                            label={gettext('New Password')}
                            placeholder={gettext('New Password')}
                            {...extra} />
                        <Input.Field
                            name="password2"
                            type="password"
                            label={gettext('Repeat Password')}
                            placeholder={gettext('Repeat Password')}
                            {...extra} />
                        {<ErrorsBlock value={value.password1}/>}
                    </fieldset>
                    <div className="page-nav__footer">
                        <Button progress={loading} type="submit" className="btn btn-lg" bsStyle="primary"> {gettext('Save Settings')}</Button>
                    </div>

                </form>
            </div>
        );
    }
}
