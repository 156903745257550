import {SET_NGS_DATA, RESET_NGS_DATA} from '../actionTypes';
import {
    SetNgsFareSearchDataAction,
    SetNgsAvailabilityDataAction,
    ResetNgsDataAction
} from './types';

export const setNgsFareSearchData = (
    fareSearchResponse: any
): SetNgsFareSearchDataAction => {
    return {
        type: SET_NGS_DATA,
        payload: {fareSearchResponse},
    };
};

export const setNgsAvailabilityData = (
    availabilityResponse: any
): SetNgsAvailabilityDataAction => {
    return {
        type: SET_NGS_DATA,
        payload: {availabilityResponse},
    };
};

export const resetNgsData = (): ResetNgsDataAction => {
    return {
        type: RESET_NGS_DATA
    };
};
