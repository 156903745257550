'process i18n';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';

import Modal from 'react-bootstrap/Modal';
import Button from 'midoffice/components/Button';

import {getBooking} from 'midoffice/bookings/selectors';
import {patchResolutionForm, hideResolutionModal, markAsResolved} from 'airborne/store/modules/actionable_booking/actions';
import {
    getActionableBookingError, getActionableBookingFormErrors,
    getActionableBookingFormValue, getActionableBookingLoading,
} from 'airborne/store/modules/actionable_booking/selectors';

import Textarea from 'midoffice/newforms/widgets/Textarea';
import ShortcutWrapper from 'midoffice/newforms/components/ShortcutWrapper';
import {injectFormContext} from 'midoffice/newforms/decorators';
import createSchema from './BookingResolveSchema';
import {noErrors} from 'midoffice/newforms/helpers';
import {TEXT_AREA_SUBMIT_SHORTCUTS, getTextAreaSubmitHandler} from 'midoffice/helpers/textareaShortcutConfig';

@injectFormContext
class Editor extends React.Component {
    render() {
        return (
            <form className="form-horizontal">
                <Textarea.Field
                    label={gettext('Your comment')}
                    name="comment"
                    inputCol={7}
                />
            </form>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        loading: getActionableBookingLoading(state),
        booking: getBooking(state, props),
        errorMessage: getActionableBookingError(state),
        value: getActionableBookingFormValue(state),
        errors: getActionableBookingFormErrors(state),
        schema: createSchema(),
    };
}

@connect(mapStateToProps, {
    onSubmit: markAsResolved,
    onChange: patchResolutionForm,
    onHide: hideResolutionModal,
})
export default class BookingResolveModal extends React.Component {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        errorMessage: PropTypes.arrayOf(PropTypes.string),
        value: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        schema: PropTypes.object.isRequired,
        booking: PropTypes.object.isRequired,
    };

    handleResolve = () => {
        const {schema, value, onChange, booking, onSubmit} = this.props;
        const errors = schema.validate(value, {strict: true});
        if (noErrors(errors)) {
            onSubmit(booking);
        }
        else {
            onChange({value, errors});
        }
    }

    renderErrorMessage(message) {
        return (
            <div className="alert alert-danger">
                {message}
            </div>
        );
    }

    render() {
        const {
            loading,
            onHide,
            errorMessage,
            value,
            errors,
            schema,
            onChange,
        } = this.props;

        return (
            <ShortcutWrapper shortcuts={[
                {shortcut: TEXT_AREA_SUBMIT_SHORTCUTS, action: getTextAreaSubmitHandler(this.handleResolve)}
            ]}>
                <Modal show onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {gettext('Mark as resolved')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {errorMessage && this.renderErrorMessage(errorMessage)}
                        <div>{gettext('Thank you for following up in this reservation.')}</div>
                        <div style={{marginTop: 10}}><strong>{gettext('Please provide a few words explaining what was done in order to resolve it.')}</strong></div>
                        <hr className="hr--dotted" />
                        <Editor
                            schema={schema}
                            value={value}
                            errors={errors}
                            onChange={onChange}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            progress={loading}
                            disabled={loading}
                            bsStyle="success"
                            onClick={this.handleResolve}
                        >
                            {gettext('Save & Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ShortcutWrapper>
        );
    }
}
