import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RowWrapper from './RowWrapper';


export class Tab extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        active: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
        children: PropTypes.node,
        noDefaultClasses: PropTypes.bool,
        className: PropTypes.string,
        activeClass: PropTypes.string,
        isList: PropTypes.bool,
    };

    static defaultProps = {
        href: '#'
    };

    onClick = (event)=> {
        if (!this.props.href || this.props.href === '#') {
            event.preventDefault();
            this.props.onClick(this.props.id);
        }
    };

    render() {
        const className = classnames({
            'inner-tabs__item': !this.props.noDefaultClasses,
            'tabs__nav--active': this.props.active && !this.props.noDefaultClasses,
        }, this.props.active && this.props.activeClass, this.props.className);
        const props = {
            className,
            onClick: this.onClick,
        };
        const content = this.props.label || this.props.children;

        return this.props.isList ?
            <li {...props}>{content}</li>:
            <a {...props} href={this.props.href}>{content}</a>;
    }
}


export default class Tabs extends React.Component {

    static Tab = Tab;  // eslint-disable-line

    static propTypes = {
        title: PropTypes.string,
        onSelect: PropTypes.func,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.element),
            PropTypes.element
        ]),
        className: PropTypes.string,
        activeClass: PropTypes.string,
        noDefaultClasses: PropTypes.bool,
        isList: PropTypes.bool,
        inline: PropTypes.bool,
        disableRowWrapper: PropTypes.bool,
    };

    renderTabs() {
        let {
            onSelect,
            children,
            title=null,
            className,
            noDefaultClasses,
            isList,
            activeClass
        } = this.props;
        if (onSelect) {
            children = React.Children.map(children, (tab)=>
                React.cloneElement(tab,
                    {onClick: onSelect, noDefaultClasses, activeClass, isList}));
        }
        className = classnames({
            'tabs__nav tabs__nav--small': !noDefaultClasses,
        }, className);
        const content =
            (<Fragment>
                {title &&
                    <p className="form-secondary-title"> {title} </p>
                }
                {children}
            </Fragment>);

        return isList ?
            <ul className={className}>{content}</ul>:
            <div className={className}>{content}</div>;
    }

    render() {
        const {inline=false, disableRowWrapper} = this.props;

        if (disableRowWrapper) {
            return this.renderTabs();
        }
        return (<RowWrapper highlighted inline={inline}>
            {this.renderTabs()}
        </RowWrapper>);
    }
}
