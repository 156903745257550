'process i18n';
import gettext from 'airborne/gettext';
import {createSchema, required} from 'midoffice/newforms/helpers';
import {CharField, EmailField} from 'midoffice/newforms/fields-stateless';
import {FirstNameCapitalizedField, LastNameCapitalizedField} from 'airborne/checkout2/fields';

export const FORM_ERRORS = {
    DEFAULT: gettext('Something went wrong.'),
    NO_BOOKINGS: gettext('No booking records found.'),
    NO_FIELD: gettext('At least one field is required.'),
};

export const FIELDS = {
    SOURCE_ID: 'source_id',
    TRAVELER_EMAIL: 'traveler_email',
    TRAVELER_FIRST_NAME: 'traveler_name:first_name',
    TRAVELER_LAST_NAME: 'traveler_name:last_name',
    CONFIRMATION_NUMBER: 'confirmation_number',
};

const firstNameEmptyMessage = gettext('This field is required with Last Name');
const lastNameEmptyMessage = gettext('This field is required with First Name');
const sourceIdEmptyMessage = gettext('This field is required');
export const oneOfRequiredMessage = gettext('Confirmation No. or Affiliate Reference is required');

const EmailFieldFormatted = {
    ...EmailField,
    toRepresentation(formValue, options) {
        const {value: oldValue, errors} = EmailField.toRepresentation.call(this, formValue, options);

        const value = oldValue?.replace('\u200B', '');
        return {value, errors};
    }
};

export const isEmptyForm = value =>
    !Object.values(value).some(Boolean);

export const getSchemaSettings = value => {

    const {
        TRAVELER_FIRST_NAME,
        TRAVELER_LAST_NAME,
        CONFIRMATION_NUMBER,
        SOURCE_ID,
    } = FIELDS;

    return {
        firstNameRequired: !!value[TRAVELER_LAST_NAME],
        lastNameRequired: !!value[TRAVELER_FIRST_NAME],
        oneOfRequired: !value[CONFIRMATION_NUMBER] && !value[SOURCE_ID],
    };
};

const generateSchema = (
    {
        firstNameRequired,
        lastNameRequired,
        oneOfRequired,
        isBookingCom,
        isEpsBookings,
    } = {isBookingCom: false, isEpsBookings: false}
) => createSchema({
    fields: {
        ...(!isEpsBookings && {
            [FIELDS.SOURCE_ID]: isBookingCom ? required(CharField, sourceIdEmptyMessage) : CharField
        }),
        ...(!isBookingCom && {
            [FIELDS.TRAVELER_EMAIL]: EmailFieldFormatted,
            [FIELDS.TRAVELER_FIRST_NAME]: firstNameRequired ?
                required(FirstNameCapitalizedField, firstNameEmptyMessage) : FirstNameCapitalizedField,
            [FIELDS.TRAVELER_LAST_NAME]: lastNameRequired ?
                required(LastNameCapitalizedField, lastNameEmptyMessage) : LastNameCapitalizedField,
        }),
        ...(isEpsBookings && {
            [FIELDS.TRAVELER_EMAIL]: required(EmailFieldFormatted, sourceIdEmptyMessage),
            [FIELDS.SOURCE_ID]: oneOfRequired ? required(CharField, oneOfRequiredMessage) : CharField,
            [FIELDS.CONFIRMATION_NUMBER]: oneOfRequired ? required(CharField, oneOfRequiredMessage) : CharField,
        }),
    }
});

export default generateSchema;
