import React from 'react';
import gettext from 'airborne/gettext';
import Input from 'midoffice/newforms/widgets/Input';
import propTypes from 'prop-types';

import InputTable from 'airborne/checkout2/InputTable';

export default class CardName extends React.Component {
    static propTypes = {
        inputCol: propTypes.number,
        isRequired: propTypes.bool,
    }

    static defaultProps = {
        inputCol: 6,
        isRequired: true,
    }

    render() {
        const {inputCol, isRequired} = this.props;
        return (<InputTable required={isRequired} label={gettext('Name On Card')} inputCol={inputCol}>
            <Input.Field name="credit_card_first_name" nowrap placeholder={gettext('First Name')} />
            <Input.Field name="credit_card_last_name" nowrap placeholder={gettext('Last Name')} />
        </InputTable>);
    }
}
