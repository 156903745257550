import * as types from '../actionTypes';

export function addToCheckoutSkip(fields, screens) {
    return {
        type: types.AIR_CHECKOUT_SKIP_ADD,
        fields,
        screens
    };
}

export function removeFromCheckoutSkip(fields, screens) {
    return {
        type: types.AIR_CHECKOUT_SKIP_REMOVE,
        fields,
        screens
    };
}

export function addPassportFieldsToCheckoutSkip() {
    return addToCheckoutSkip([
        'passportNumber',
        'nationality',
        'passportCountryOfIssue',
        'passportExpiryDate',
    ]);
}
