import api from 'midoffice/helpers/api';
import {hideModal, showModal} from 'airborne/store/modules/header/actions/modal';

export function getOffers (pnr) {
    return function onSubmitD(dispatch) {
        dispatch({type: 'SET_OFFERS', items: []});
        return api('GET', '/hotels/email_offers/pnr/' + pnr)
            .then((response) => {
                dispatch({type: 'SET_OFFERS', items: response.email_offers});
            }, (response)=> {
                dispatch({type: 'FAILED', errors: response.body});
            });
    };
}

export function resendEmailOffer(value, pnr) {
    return function onSubmitD(dispatch) {
        return api('POST', '/hotels/email_offers/resend', {data: value})
            .then(()=> {
                dispatch(getOffers(pnr));
                dispatch(hideModal());
            })
            .catch((response)=> {
                dispatch({type: 'FAILED', errors: response.body});
            });
    };
}

export function showOfferModal(offer, offers, pnr) {
    return showModal('emailOffer', {
        value: offer,
        pnr,
        offers,
        'traveler_name': offer['traveler_name'],
        to: offer['traveler_email'] || '',
        sender: offer['agent_email'] || '',
    });
}

export function sortOffers(sortBy) {
    return {
        sortBy,
        type: 'SORT_OFFERS',
    };
}
