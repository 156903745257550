import {createSchema, required} from 'midoffice/newforms/helpers';

import {Field} from 'midoffice/newforms/fields-stateless';
import {PasswordField, RepeatPassword} from 'midoffice/newforms/validators/password';

const сhangePasswordSchema = createSchema({
    fields: {
        'old_password': required({...Field, minLength: 1}),
        'password1': PasswordField,
        'password2': RepeatPassword,
    },
});


export default сhangePasswordSchema;
