import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Preferences from './Preferences';
import ChangePassword from './ChangePassword';
import gettext from 'airborne/gettext';
import ProfileDetails from './ProfileDetails';

class UserSettings extends React.Component {
    render() {
        return (
            <div className="container clearfix">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="clearfix">
                        <Col xs={3}>
                            <Nav as="ul" className="page-nav flex-column nav-stacked" variant="pills">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="first">
                                        {gettext('Default Display Preferences')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="second">
                                        {gettext('Change Password')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <ProfileDetails/>
                        </Col>

                        <Col xs={9}>
                            <div className="page-wrapper">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Preferences/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ChangePassword />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default UserSettings;
