import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import gettext from 'airborne/gettext';
import Select from 'midoffice/newforms/widgets/Select';
import {injectField} from 'midoffice/newforms/decorators';

import InputTable from 'airborne/checkout2/InputTable';

import {pad} from './helpers';


function getYears() {
    const year = moment().year();
    return range(year, year + 11).map((year)=> ([year, year]));
}

function getMonths() {
    return range(1, 13).map((month)=> ([month, `${pad(month)} - ${moment().month(month - 1).format('MMMM')}`]));
}

@injectField
export default class CardExpiration extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.object,
        errors: PropTypes.any,
    }

    handleMonthChange = (month)=> {
        const {onChange, value} = this.props;
        onChange({
            ...value,
            month,
        });
    }

    handleYearChange = (year)=> {
        const {onChange, value} = this.props;
        onChange({
            ...value,
            year,
        });
    }

    render() {
        const {value} = this.props;
        return (<InputTable inputCol={12}>
            <Select allowEmpty
                value={value && value.month}
                placeholder={gettext('Month')}
                choices={getMonths()}
                name="month"
                onChange={this.handleMonthChange}
                nowrap />
            <Select allowEmpty
                value={value && value.year}
                placeholder={gettext('Year')}
                choices={getYears()}
                name="year"
                tdWidth="33%"
                onChange={this.handleYearChange}
                nowrap />
        </InputTable>);
    }
}
