import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import amenityFormat from 'airborne/search2/amenities';

function wrap(content, flag) {
    return flag
        ? <div className="amenities__stencil-wrapper">
            <span className="icon-am-internet stencil" />
            <span className="icon-am-wifi stencil" />
            <span className="icon-am-breakfast stencil" />
            <span className="icon-am-free-parking stencil" />
            {content}</div>
        : content;
}

export default class AmenitiesIcons extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        withWrapper: PropTypes.bool,
        codes: PropTypes.arrayOf(PropTypes.string),
        freeBreakfast: PropTypes.bool,
        freePark: PropTypes.bool,
        freeWifi: PropTypes.bool,
        freeInternet: PropTypes.bool,
    };

    renderFree(icon, text) {
        const {className} = this.props;
        const cls = classnames(icon, className);
        return (
            <OverlayTrigger placement="top"
                overlay={<Tooltip id={icon}>{text}</Tooltip>}>
                <span className={cls} data-print={text} />
            </OverlayTrigger>
        );
    }
    render() {
        const {withWrapper, freeWifi, freeBreakfast, freePark, freeInternet, codes=[]} = this.props;
        return wrap(<div>
            {(freeInternet || codes.includes('FINTT')) && this.renderFree('icon-am-internet', amenityFormat('FINTT'))}
            {(freeWifi || codes.includes('FWIFI')) && this.renderFree('icon-am-wifi', amenityFormat('FWIFI'))}
            {(freeBreakfast || codes.includes('FBFST')) && this.renderFree('icon-am-breakfast', amenityFormat('FBFST'))}
            {(freePark || codes.includes('FPARK')) && this.renderFree('icon-am-free-parking', amenityFormat('FPARK'))}
        </div>, withWrapper);
    }
}
