import WaitBooking from 'airborne/common/WaitBooking';

import {connect} from 'react-redux';
import {isLoadingBookCarsForDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import gettext from 'airborne/gettext';

export default connect(
    (state, {show})=> ({
        show: state.carBooking.checkoutLoading || show,
        product: 'cars',
        message: isLoadingBookCarsForDestination(state) ? gettext('Please wait...') : undefined, //eslint-disable-line no-undefined
    })
)(WaitBooking);
