import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

export default class AddMore extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        testId: PropTypes.string,
    }

    render() {
        const {onClick, disabled, label, testId} = this.props;

        return (
            <footer className="ag-mode__footer">
                <Button
                    onClick={onClick}
                    variant="link"
                    disabled={disabled}
                    data-testid={testId}
                >{label}</Button>
            </footer>
        );
    }
}
