import React from 'react';
import {injectField} from 'midoffice/newforms/decorators';
import MaskInput from 'midoffice/newforms/widgets/MaskInput';
import Select from 'midoffice/newforms/widgets/Select';
import PropTypes from 'prop-types';
import range from 'lodash/range';

const TIME_WINDOWS = range(2, 13).map(i => [i, `± ${i}h`]);

@injectField
export default class AirTimeRestrictionField extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.shape({
            time: PropTypes.string,
            timeWindow: PropTypes.number,
        }),
        disabled: PropTypes.bool,
        'data-testid': PropTypes.string,
    };

    handleInputChange = time => {
        this.props.onChange({...this.props.value, time});
    };

    handleSelectChange = timeWindow => {
        this.props.onChange({...this.props.value, timeWindow});
    };

    render() {
        const {value, disabled, 'data-testid': testId} = this.props;
        const selectTestId = testId && testId + '-window';

        return (
            <div className="ag-form-control__split">
                <MaskInput
                    mask={'99:99'}
                    maskChar={'_'}
                    placeholder={'__:__'}
                    onChange={this.handleInputChange}
                    name={'time'}
                    value={value?.time || ''}
                    disabled={disabled}
                    data-testid={testId}
                />
                <Select
                    name={'timeWindow'}
                    value={value?.timeWindow || TIME_WINDOWS[0][0]}
                    onChange={this.handleSelectChange}
                    choices={TIME_WINDOWS}
                    disabled={disabled}
                    data-testid={selectTestId}
                />
            </div>
        );
    }
}
