import React, {Fragment} from 'react';

import Tabs from 'midoffice/components/Tabs';
import PropTypes from 'prop-types';

export class TabsMenu extends React.Component {
    static propTypes = {
        children: PropTypes.array,
    };
    render() {
        return (
            <div> {this.props.children}</div>
        );
    }
}

export class TabContent extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };
    render() {
        return (
            <div> {this.props.children}</div>
        );
    }
}

export class MenuItem extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };
    render() {
        return (
            <div> {this.props.children}</div>
        );
    }
}

export class Content extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };
    render() {
        return (
            <div className={this.props.className}> {this.props.children}</div>
        );
    }
}

export class AfterTabs extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };
    render() {
        return (
            <div className={this.props.className}>{this.props.children}</div>
        );
    }
}

export default class TabsWithContent extends React.Component {
    static propTypes = {
        children: PropTypes.array,
        // below props used for <Tabs>
        title: PropTypes.string,
        className: PropTypes.string,
        isList: PropTypes.bool,
        noDefaultClasses: PropTypes.bool,
        menuItemClassName: PropTypes.string,
    };
    state = {
        active: 0,
    };

    handleSelect = (tab) => {
        this.setState({active: tab});
    };

    render() {
        const {active} = this.state;
        const [tabs, content, afterTabs] = this.props.children;

        return (
            <Fragment>
                <div className={tabs.props.className}>
                    <Tabs {...this.props} disableRowWrapper onSelect={this.handleSelect}>
                        {React.Children.map(tabs.props.children, (elem, index) => {
                            return (
                                elem && <Tabs.Tab
                                    key={index}
                                    id={index.toString()}
                                    active={parseInt(active) === index}
                                    className={this.props.menuItemClassName}
                                >
                                    {elem && elem.props.children}
                                </Tabs.Tab>);
                        })
                        }
                    </Tabs>
                    {afterTabs && React.cloneElement(afterTabs)}
                </div>
                {React.cloneElement(content, {}, content.props.children[active])}

            </Fragment>
        );
    }
}
