import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import settings from 'airborne/settings';
import get from 'lodash/get';


import {getCurrency, getLang} from 'airborne/store/modules/header/selectors/i18n';


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import find from 'lodash/find';

function getNormalDescription({amount, currency, template}) {
    return gettext(template, {
        'rate_cap_amount': amount,
        'rate_cap_currency': currency
    });
}

function getApproxDescription({amount, currency, template, amountApprox, currencyApprox}) {
    return gettext(template, {
        'rate_cap_amount': amount,
        'rate_cap_currency': currency,
        'display_rate_cap_amount': amountApprox,
        'display_currency': currencyApprox
    });
}

function formatter(props) {
    const {reason, currency, templates, language, templatesCurrency, defaultPolicyLang} = props;
    if (!reason.parameters) {
        return reason.description;
    }
    const isLocalCurrency = currency === '___';
    const isCompanyCurrency = currency === '...';
    const localCurrency = isLocalCurrency ? reason.parameters.localCurrency : currency;
    const displayCurrency = isCompanyCurrency ? reason.parameters.currency : localCurrency;

    const templateMatch = templates.find(template => template.language === language)?.template;
    const templateMatchDefaultLang = templates.find(template => template.language === defaultPolicyLang)?.template;

    const templateMismatch = templatesCurrency.find(template => template.language === language)?.template;
    const templateMismatchDefaultLang = templatesCurrency.find(template => template.language === defaultPolicyLang)?.template;

    const [fxRate] = find(settings.FX_RATES, ([, code]) => code === displayCurrency);
    const [srcFxRate] = find(settings.FX_RATES, ([, code]) => code === reason.parameters.originalCurrency);

    const amountApprox = (reason.parameters['originalMaxValue'] * fxRate / srcFxRate).toFixed(2);
    if (displayCurrency !== reason.parameters.originalCurrency) {
        return getApproxDescription({
            amount: reason.parameters['originalMaxValue'],
            currency: reason.parameters['originalCurrency'],
            template: templateMismatch || templateMismatchDefaultLang,
            amountApprox: displayCurrency === reason.parameters.localCurrency ?  reason.parameters.localMaxValue: amountApprox,
            currencyApprox: displayCurrency
        });
    }
    else {
        return getNormalDescription({
            amount: amountApprox, currency: displayCurrency, template: templateMatch || templateMatchDefaultLang
        });
    }
}

class Reason extends React.Component {
    static propTypes = {
        description: PropTypes.string
    };

    render() {
        return (<span>{formatter(this.props)}<br /></span>);
    }
}

function mapStateProps(state) {
    return {
        currency: getCurrency(state),
        language: getLang(state),
        templates: get(state,'dest.options.rate_caps_text_template'),
        templatesCurrency: get(state,'dest.options.rate_caps_text_template_display_currency'),
        defaultPolicyLang: get(state,'dest.options.default_display_language')
    };
}

@connect(mapStateProps)
export default class OutOfPolicy extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        icon: PropTypes.bool,
        currency: PropTypes.string,
        reason: PropTypes.shape({
            description: PropTypes.string
        }),
        children: PropTypes.any
    };

    renderTooltip(reason) {
        return (<Tooltip id="out_of_policy">
            <div>
                <Reason {...reason} />
            </div>
        </Tooltip>);
    }

    render() {
        const {show, icon} = this.props;
        if (!show) {
            return null;
        }
        return (
            <OverlayTrigger placement="right" overlay={this.renderTooltip(this.props)}>
                {icon
                    ? (<span className="ico-out-of-policy" />)
                    : (<span>{gettext('Out of policy')}</span>)}
            </OverlayTrigger>
        );
    }
}
