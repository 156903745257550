import {isCloseCheckin} from 'airborne/homepage2/helpers/dateLimits';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import React from 'react';

import gettext from 'airborne/gettext';

export const CloseCheckinWarning = ({dates}) => {
    if (!isCloseCheckin(dates)) {
        return null;
    }

    return (<Row className="form-warning__wrapper">
        <div className="form-warning">
            <strong>{gettext('Selected checkin is very close or in the past.')}</strong>
            <div>{gettext('Please verify hotel\'s local date and time before confirming the booking.')}</div>
        </div>
    </Row>);
};

CloseCheckinWarning.propTypes = {
    dates: PropTypes.object,
};
