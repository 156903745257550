/* global IS_PROD,global */
import wrap from 'airborne/search2/LazyComponent';
import HotelsLoader from './HotelsLoader';

export function loadHotelSearch() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./Search');
            resolve(loaded);
        }, 'search2');
    });
}


const HotelSearch = wrap(loadHotelSearch, 'default', HotelsLoader);
export default HotelSearch;

global.loadHotelSearch = IS_PROD ? null : loadHotelSearch; // eslint-disable-line immutable/no-mutation
