import {getStepStatus, getStepText} from 'airborne/checkout_air/helpers/bookProgress';
import {getBookingUid} from 'airborne/store/modules/checkout_air/selectors/checkout';
import {isAirSeparatedTicketsMode} from "airborne/store/modules/pricing_air/selectors";
import {getTicketIndexes} from "airborne/pricing_air/helpers/separatedTickets";

const getBookProgress = (state) => state.airBooking.bookProgress;
const getBookProgressData = (state) => getBookProgress(state).data;
const getBookInProgressUid = (state) => getBookProgress(state).inProgressUid;

export const isBookInProgress = (state) => {
    const inProgressUid = getBookInProgressUid(state);
    const bookingUid = getBookingUid(state);

    return inProgressUid && inProgressUid === bookingUid;
};

export const getBookProgressItemsByUid = (state, bookingUid) => {
    return getBookProgressData(state)[bookingUid]?.items;
};

export const isBookProgressCompletedByUid = (state, bookingUid) => {
    return getBookProgressData(state)[bookingUid]?.isCompleted;
};

const getBookProgressItemsToDisplayByUid = (state, bookingUid) => {
    return getBookProgressItemsByUid(state, bookingUid)?.map(item => ({
        step: getStepText(item.step),
        status: getStepStatus(item.status)
    }));
};

export const getBookProgressItemsToDisplay = (state) => {
    const bookingUid = getBookingUid(state);

    return getBookProgressItemsToDisplayByUid(state, bookingUid);
};
