export const getActionableBooking = (state) => (
    state['actionableBooking']
);

export const getActionableBookingLoading = (state) => (
    getActionableBooking(state)['loading']
);

export const getActionableBookingError = (state) => (
    getActionableBooking(state)['errorMessage']
);

export const getActionableBookingFormValue = (state) => (
    getActionableBooking(state)['value']
);

export const getActionableBookingFormErrors = (state) => (
    getActionableBooking(state)['errors']
);

export const getActionableBookingResolveComment = (state) => (
    getActionableBookingFormValue(state)['comment']
);
