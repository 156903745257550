import gettext from 'airborne/gettext';
import api from 'midoffice/helpers/api';
import air from 'airborne/store/modules/search_hotels/actions/air';
import externalExtraActionTypes from './actionTypes';
import {searchBookings} from 'midoffice/external-bookings/actions';
import {
    isAnyExternalBookings,
    isExternalBookingError,
    getExternalErrorMessages,
} from 'midoffice/external-bookings/selectors';
import {externalExtraSelectors} from 'midoffice/store/modules/externalExtra';

import {FORM_ERRORS} from 'midoffice/external-bookings/BookingSearchSchema';
import {parse} from 'airborne/types';
import {
    hideLoader,
    hideModal,
    showError,
    showInfo,
    showLoader,
    showModal,
} from 'midoffice/data/actions';
import {userMessages} from 'airborne/search2/helpers/apiError';
import {apiError} from 'airborne/search_cars/helpers/apiError';
import {isManualRefundError} from 'midoffice/air-bookings/modify/helpers';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {dynamicRegister} from 'midoffice/external-bookings/types';

export const setBookingTableDisplaying = display => ({
    type: externalExtraActionTypes.SET_BOOKING_TABLE_DISPLAYING,
    display,
});

export const setSearchType = (searchType) => ({
    type: externalExtraActionTypes.SET_EXTERNAL_SEARCH_TYPE,
    searchType,
});

export const setSearchFormErrorMessage = message => ({
    type: externalExtraActionTypes.SET_SEARCH_FORM_ERROR_MESSAGE,
    message,
});

export const clearSearchFormErrorMessage = () => ({
    type: externalExtraActionTypes.SET_SEARCH_FORM_ERROR_MESSAGE,
    message: '',
});

export const makeExternalBookingsSearch = () => async (dispatch, getState) => {
    dynamicRegister(
        externalExtraSelectors.getSearchType(getState())
    );

    await dispatch(searchBookings());

    if (isExternalBookingError(getState())) {
        const errors = getExternalErrorMessages(getState());

        return dispatch(
            setSearchFormErrorMessage(
                errors?.length ? errors.join(' ') : FORM_ERRORS.DEFAULT
            )
        );
    }
    if (!isAnyExternalBookings(getState())) {
        return dispatch(setSearchFormErrorMessage(FORM_ERRORS.NO_BOOKINGS));
    }

    return dispatch(setBookingTableDisplaying(true));
};

const setDataModalLoading = loading => ({
    type: externalExtraActionTypes.SET_DATA_MODAL_LOADING,
    loading,
});
const setDataModalErrorMessage = errorMessage => ({
    type: externalExtraActionTypes.SET_DATA_MODAL_ERROR_MESSAGE,
    errorMessage,
});

const setRawBookingData = (data, uid) => {
    return {type: externalExtraActionTypes.SET_RAW_MODAL_DATA, data, uid};
};
const setExternalBookingData = (externalBookingData, uid) => {
    return {
        type: externalExtraActionTypes.SET_EXTERNAL_BOOKING_DATA,
        externalBookingData,
        uid,
    };
};

export const getRawBookingData = uid => async (dispatch, getState) => {
    const data = externalExtraSelectors.getExternalRawBookingData(
        getState(),
        uid
    );

    if (data) return Promise.resolve();

    dispatch(setDataModalLoading(true));

    try {
        const {booking} = await api(
            'GET',
            '/internal/get_raw_external_reservation/',
            {data: {'booking_uid': uid}}
        );
        dispatch(
            setRawBookingData(
                booking ? JSON.stringify(booking, null, 2) : null,
                uid
            )
        );
    }
    catch (response) {
        dispatch(setDataModalErrorMessage('Something went wrong'));
    }

    dispatch(setDataModalLoading(false));
};

export const getBookingData = (externalReservationKey, uid) => async (
    dispatch,
    getState = () => null
) => {
    const data = externalExtraSelectors.getExternalBookingData(
        getState(),
        uid
    );
    if (data) return Promise.resolve();

    dispatch(setDataModalLoading(true));

    try {
        const {booking} = await api(
            'POST',
            '/internal/air/get_external_reservation_details',
            {
                data: {
                    'external_reservation_key': externalReservationKey,
                },
            }
        );
        dispatch(setExternalBookingData(parse('airBookings', booking), uid));
    }
    catch (response) {
        dispatch(setDataModalErrorMessage('Something went wrong'));
    }

    dispatch(setDataModalLoading(false));
};

function informSuccess() {
    return showInfo(
        [
            gettext('Booking canceled.'),
            gettext('Status of the booking going to be updated in a few minutes.'),
        ], 'success'
    );
}

function informError(error) {
    return showError([
        gettext('Cancelation failed.'),
        ...userMessages(error),
        gettext('Status of the booking could be updated with some delay.'),
    ]);
}

export const cancelHotelsBooking =
    (externalReservationKey, id) =>
        async dispatch => {
            dispatch({type: 'LOADING', endpoint: 'airBookingModification', id});
            dispatch(showLoader(gettext('Canceling external reservation')));
            try {
                await api(
                    'POST',
                    '/internal/hotels/cancel_external_reservation/',
                    {data:{
                        'external_reservation_key': externalReservationKey,
                    }});
                dispatch({type: 'LOADED', endpoint: 'airBookingModification'});
                dispatch(informSuccess());
                dispatch(hideModal());
            }
            catch (response) {
                const error = apiError(response);
                dispatch({type: 'NET_FAIL', endpoint: 'airBookingModification'});
                dispatch(informError(error));
                dispatch(hideModal());
            }
            finally {
                dispatch(hideLoader());
            }
        };


export const cancelBooking = (
    externalReservationKey,
    uid,
    system,
    manualRefundData,
) => async dispatch => {
    dispatch({type: 'LOADING', endpoint: 'airBookingModification', id: uid});
    dispatch(showLoader(gettext('Canceling external reservation')));

    try {
        await air(
            'POST',
            '/air/cancel_reservation/',
            dropEmpty({
                'external_reservation_key': externalReservationKey,
                'ticket_refund_data': manualRefundData,
            }),
            system
        );
        dispatch({type: 'LOADED', endpoint: 'airBookingModification'});
        dispatch(informSuccess());
        dispatch(hideModal());

        if (manualRefundData) {
            dispatch(showModal('airExternalRefundData', {uid, externalReservationKey}));
        }
    }
    catch (response) {
        const error = apiError(response);
        dispatch({type: 'NET_FAIL', endpoint: 'airBookingModification'});
        dispatch(informError(error));
        dispatch(hideModal());

        if (isManualRefundError(response)) {
            dispatch(
                showModal('airExternalManualRefund', {
                    uid,
                    externalReservationKey,
                })
            );
        }
    }
    finally {
        dispatch(hideLoader());
    }
};
