import api from 'midoffice/helpers/api';
import aio from 'midoffice/helpers/aio';
import {apiError} from 'airborne/search2/helpers/apiError';
import {searchBookings} from 'midoffice/bookings/actions';
import gettext from 'airborne/gettext';

import {
    counterofferRequest,
    counterofferRequestSuccess,
    counterofferRequestFailure, saveBookingsBackup,
} from './actions';

function requestFailure(response) {
    return function requestFailureD(dispatch) {
        const message = apiError(response) || 'EUNKNOWN';
        return dispatch(counterofferRequestFailure(message));
    };
}

const ACCEPT_REQUEST_TYPE = 'ACCEPT';
const REJECT_REQUEST_TYPE = 'REJECT';

const counterOfferEvents = {
    [ACCEPT_REQUEST_TYPE]: 'counter_offer_accept',
    [REJECT_REQUEST_TYPE]: 'counter_offer_reject',
};

const BOOKING_REQUEST_PATH = '/latest/hotels/get_reservation_details';
const requestFn = async (uid, system) => await api('GET', BOOKING_REQUEST_PATH, {data: {'booking_uid': uid, system}});

const pendingStatuses = ['in-progress', 'pending'];
const checkStatusFn = ({booking_status: bookingStatus}) => pendingStatuses.every(e => e !== bookingStatus);

const timeoutErrorText = () => gettext(
    'Something went wrong. There is a chance that the transaction went through behind the scene. Please try again in few minutes, but only after making sure that the booking is not updated.'
);

const checkCounterofferStatus = aio.retry(
    requestFn,
    checkStatusFn,
    2,
    30,
    false,
    timeoutErrorText()
);

function makeCounterofferRequest(booking, requestType) {
    return function makeCounterofferRequestD(dispatch) {
        const COUNTER_OFFER_PATH = '/latest/hotels/update_reservation_details';
        const {'id': bookingUID} = booking;
        const system = 'aft';
        const params = {
            'booking_uid': bookingUID,
            'booking_event': counterOfferEvents[requestType],
            system
        };
        // In this case need to save booking in other variable in the store, because after search the booking is removed
        // from the bookings list
        // We can't just close modal because of design, but in the same situation you need to close modal window.
        dispatch(saveBookingsBackup(booking));
        dispatch(counterofferRequest(requestType));
        return api('POST', COUNTER_OFFER_PATH, {data: params})
            .then(() => new Promise((resolve, reject) => {
                checkCounterofferStatus(bookingUID, system)
                    .then((response) => resolve(response))
                    .catch((response) => reject(response));
            }))
            .then(() => dispatch(counterofferRequestSuccess(requestType)))
            .then(() => dispatch(searchBookings()))
            .catch((response) => dispatch(requestFailure(response)));
    };
}

export const counterOfferAccept = (booking) => (
    makeCounterofferRequest(booking, ACCEPT_REQUEST_TYPE)
);

export const counterOfferReject = (booking) => (
    makeCounterofferRequest(booking, REJECT_REQUEST_TYPE)
);
