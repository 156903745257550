import React from 'react';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Loader from 'airborne/search2/Loader';
import Button from 'midoffice/components/Button';
import FareRules from './FareRules';

import {
    getIsFareRulesLoading,
    getFareRulesTabs,
    getODTabs,
} from 'airborne/store/modules/search_air/selectors';
import {getFareRules} from 'airborne/store/modules/search_air/actions/fareRules';
import Alert from 'react-bootstrap/Alert';

export class ControlledFareRulesModal extends React.Component {
    static propTypes = {
        isCheckoutFlow: PropTypes.bool,
        // from mapStateToProps
        loading: PropTypes.bool,
        tabs: PropTypes.array,
        ODTabs: PropTypes.array,
        // from mapDispatchToProps
        preload: PropTypes.func,
        // from bootstrap component
        onHide: PropTypes.func.isRequired,
    }
    componentDidMount() {
        this.props.preload && this.props.preload();
    }

    renderBody() {
        const {loading, tabs, ODTabs} = this.props;

        if (loading) {
            return <Loader light wrapped/>;
        }

        if (!tabs.length) {
            return (
                <Alert variant="warning" closeLabel="Close alert">
                    {gettext('No Fare Rules information for this reservation just yet. Please try again later.')}
                </Alert>
            );
        }

        return <FareRules tabs={tabs} ODTabs={ODTabs}/>;
    }

    render() {
        const {onHide} = this.props;
        return (<Modal onHide={onHide} show className="modal--rate">
            <Modal.Header closeButton>
                <Modal.Title>
                    {gettext('Fare Rules')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.renderBody()}
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="light" onClick={onHide}>
                    {gettext('Close')}
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default connect(
    (state, {fareGroupKey}) => ({
        loading: getIsFareRulesLoading(state),
        tabs: getFareRulesTabs(state, fareGroupKey),
        ODTabs: getODTabs(state),
    }),
    (dispatch, {onHide, fareGroupKey, isCheckoutFlow, ticketIndex}) => ({
        preload: () => dispatch(getFareRules(fareGroupKey, isCheckoutFlow, ticketIndex)).catch(onHide)
    }),
)(ControlledFareRulesModal);
