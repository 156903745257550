import * as actionTypes from '../actionTypes';

export function changeAssignee() {
    return {type: actionTypes.CHANGE_ASSIGNEE_REQUEST};
}

export function changeAssigneeSuccess() {
    return {type: actionTypes.CHANGE_ASSIGNEE_REQUEST_SUCCESS};
}

export function changeAssigneeFailure() {
    return {type: actionTypes.CHANGE_ASSIGNEE_REQUEST_FAILURE};
}

export function changeResolution() {
    return {type: actionTypes.CHANGE_RESOLUTION_REQUEST};
}

export function changeResolutionSuccess() {
    return {type: actionTypes.CHANGE_RESOLUTION_REQUEST_SUCCESS};
}

export function changeResolutionFailure(errorMessage) {
    return {type: actionTypes.CHANGE_RESOLUTION_REQUEST_FAILURE, errorMessage};
}

export function patchResolutionForm({value, errors}) {
    return {type: actionTypes.UPDATE_RESOLUTION_FORM, value, errors};
}

export function cleanupResolutionForm() {
    return {type: actionTypes.CLEANUP_RESOLUTION_FORM};
}
