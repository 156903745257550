import {dropEmpty} from 'midoffice/helpers/urlParams';
import {trimSpacesFromNames} from 'airborne/checkout2/helpers/names';
import api from 'midoffice/helpers/api';
import {apiError} from 'airborne/search2/helpers/apiError';
import pick from 'lodash/pick';
import {fixCard, PAYMENT_FIELDS} from 'airborne/store/modules/checkout_hotels/actions/book';
import {getUpdatePaymentState} from 'airborne/store/modules/update_payment/selectors';
import {searchBookings} from 'midoffice/bookings/actions';

import {
    updatePaymentRequest,
    updatePaymentSuccess,
    updatePaymentFailure,
} from './checkout';
import {hideUPaymentModal} from './modalHelper';

export function serializePayment(form) {
    return pick(fixCard(form), PAYMENT_FIELDS['card']);
}

function requestFailure(response) {
    return function requestFailureD(dispatch) {
        const message = apiError(response) || 'EUNKNOWN';
        return dispatch(updatePaymentFailure({message}));
    };
}

export function makeUPaymentRequest(bookingUID) {
    return function makeUPaymentRequestD(dispatch, getState) {
        const {value: form} = getUpdatePaymentState(getState());
        const UPDATE_RESERVATION_DETAILS = '/latest/hotels/update_reservation_details';

        const paymentData = dropEmpty(serializePayment(form));
        const params = dropEmpty(trimSpacesFromNames({
            'booking_uid': bookingUID,
            'system': 'aft',
            'booking_event': 'update_payment',
            'booking_data': {
                'payment_valid': true,
                'payment_data': paymentData,
            }
        }));

        dispatch(updatePaymentRequest());

        return api('POST', UPDATE_RESERVATION_DETAILS, {data: params})
            .then((response) => dispatch(updatePaymentSuccess(response)))
            .then(() => dispatch(hideUPaymentModal()))
            .then(() => dispatch(searchBookings()))
            .catch((response) => dispatch(requestFailure(response)));
    };
}
