import api from 'midoffice/helpers/api';
import {apiError} from 'airborne/search2/helpers/apiError';
import {searchBookings} from 'midoffice/bookings/actions';
import {
    showError,
    showInfo,
    showLoader,
    hideLoader,
} from 'midoffice/data/actions/modals';
import {
    changeAssignee,
    changeAssigneeSuccess,
    changeAssigneeFailure,
} from './actions';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';

function requestFailure(response) {
    return function requestFailureD(dispatch) {
        const message = apiError(response) || 'ERROR UNKNOWN';
        dispatch(showError([
            gettext(
                'Update failed. Check Error Details for more information.'
            ),
            message
        ]));
        return dispatch(changeAssigneeFailure());
    };
}

export function makeChangeAssigneeRequest(bookingUID, assignTo) {
    return function makeChangeAssigneeRequestD(dispatch) {
        dispatch(changeAssignee());
        dispatch(showLoader(gettext('Update booking...')));

        const UPDATE_RESERVATION_DETAILS = '/latest/hotels/update_reservation_details';
        const data = {
            'booking_uid': bookingUID,
            'system': 'aft',
            'booking_event': 'change_assignee_user',
            'assignee_user_email': assignTo,
        };

        return api('POST', UPDATE_RESERVATION_DETAILS, {data})
            .then((response) => {
                dispatch(changeAssigneeSuccess(response));
                const message = assignTo
                    ? gettext('Booking successfully assigned to ') + assignTo
                    : gettext('Booking successfully unassigned');
                dispatch(showInfo(message, 'success'));
            })
            .then(() => dispatch(searchBookings()))
            .catch((response) => dispatch(requestFailure(response)))
            .finally(() => dispatch(hideLoader()));
    };
}

export function unassignRequest(bookingUID) {
    return makeChangeAssigneeRequest(bookingUID, null);
}

export function selfAssignRequest(bookingUID) {
    return makeChangeAssigneeRequest(bookingUID, settings.USER.email);
}
