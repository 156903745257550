import {getErrorCodes} from 'airborne/helpers/apiError';

export function isManualRefundError(response) {
    const errorCodes = getErrorCodes(response);

    return errorCodes?.includes(1063000);
}

export function isVoidedBooking(booking) {
    const {tickets} = booking;

    if (!tickets) {
        return true;
    }

    return tickets.every(ticket => ticket.status === 'VOIDED');
}

