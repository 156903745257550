import wrap from 'airborne/search2/LazyComponent';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import {isFaresLoading} from 'airborne/store/modules/search_air/selectors';
import Loader from 'airborne/common/Loader';

export function loadAirFareSearch() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./FareSearch');
            resolve(loaded);
        }, 'exchange_fare_search_air');
    });
}

const AirFareSearchLoader = connect(state => ({
    ready: !isFaresLoading(state),
    title: gettext('Loading fares, please wait…'),
}))(Loader);

const FareSearch = wrap(loadAirFareSearch, 'default', AirFareSearchLoader);
export default FareSearch;
