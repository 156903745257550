import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import gettext from 'airborne/gettext';
import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class RadioWithCustomInput extends React.Component {

    static propTypes = {
        choices: PropTypes.array,
        customInput: PropTypes.string,
        defaultValue: PropTypes.any,
        customInputError: PropTypes.any,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        title: PropTypes.string,
        placeholderCustomInput: PropTypes.string,
        uniqueName: PropTypes.string,
        value: PropTypes.any,

        block: PropTypes.bool,
        rawLabel: PropTypes.bool,
        withTravelerLabel: PropTypes.bool,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onChangeCustom: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
    };

    static defaultProps = {
        block: false,
        choices: [],
        defaultValue: null,
        className: '',
        disabled: false,
        onBlur: noop,
        onFocus: noop,
        rawLabel: false,
        uniqueName: '',
        withTravelerLabel: false,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    checkValue(value, defaultValue) {
        if (defaultValue !== null && value !== false && !value) {
            return defaultValue;
        }
        return value;
    }

    getLabel(rawLabel, label) {
        if (label === 'custom') return null;
        return rawLabel ? <span dangerouslySetInnerHTML={{__html: label}} /> : label;
    }

    handleChange({target: {value}}) {
        const {choices, defaultValue} = this.props;
        value = this.checkValue(value, defaultValue);
        const choice = choices[value];
        if (choice) {
            this.props.onChange(choice[0], this.props.name);
        }
    }

    handleChangeCustomInput = ({target: {value}}) => {
        this.props.onChangeCustom(value, this.props.name);
    }

    handleFocus = () => {
        const {choices} = this.props;
        const customIdx = choices.reduce((acc, choice, idx) => {
            const [value] = choice;
            return value === 'custom' ? idx : acc;
        }, null);

        if (customIdx) {
            this.handleChange({target: {value: customIdx}});
        }
    }

    render() {
        let {block, choices, defaultValue, className, disabled,
            name, rawLabel, value, uniqueName,
            customInput, placeholderCustomInput, customInputError, withTravelerLabel,
            title
        } = this.props;

        let inputClassName = classnames('radio', 'radio-rev', {
            'radio-inline': !block,
            'disabled': disabled,
        });
        let customInputClassName = classnames({
            'has-error': customInputError,
        });

        value = this.checkValue(value, defaultValue);

        return (
            <div className={className}>
                {choices.map(([choiceValue, label, helpText = ''], index)=>
                    <React.Fragment key={`fragment-${index}`}>
                        {withTravelerLabel && choiceValue === 'custom' && (
                            <div className="multi-selector-body__title" style={{marginLeft: '-36px', marginTop: '8px'}}>
                                {title}
                            </div>
                        )}

                        <label key={`radio-label-${name}-${uniqueName}-${choiceValue}`}
                            className={inputClassName}>
                            <input type="radio"
                                name={name+uniqueName}
                                key={`radio-${name}-${choiceValue}`}
                                value={index}
                                disabled={disabled}
                                onChange={this.handleChange}
                                checked={isEqual(value, choiceValue)}
                            />

                            <span className="radio__icon" />
                            {choiceValue !== 'custom' && this.getLabel(rawLabel, label)}
                            {choiceValue === 'custom' && <div className={customInputClassName}>
                                <input
                                    name="customInput"
                                    type="text"
                                    disabled={disabled}
                                    className="form-control"
                                    placeholder={placeholderCustomInput}
                                    onChange={this.handleChangeCustomInput}
                                    onFocus={this.handleFocus}
                                    value={customInput}
                                />
                                {customInputError && <div className="form-group__error form-group__error--top">{customInputError}</div>}
                            </div>}
                            <div className="muted"> {helpText} </div>
                        </label>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
