import gettext from 'airborne/gettext';
import pick from 'lodash/pick';

import compact from 'lodash/compact';
import noop from 'lodash/noop';
import classnames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import BookButton from './BookButton';
import ProceedMultiCheckout from './ProceedMultiCheckout';
import Glyphicons from 'midoffice/components/Glyphicons';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

import {getAllProfiles, isMultiPnr, getProfileName} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getHotelById} from 'airborne/search2/helpers/hotelSelector';

import {getSelectedRateKey} from 'airborne/search2/helpers/rates';
import {getBookingUID} from 'airborne/store/modules/confirm_hotels/selectors/details';
import {isBookable} from 'airborne/homepage2/helpers/pnr';

import {selectRate, selectRemainingRate} from 'airborne/store/modules/checkout_hotels/actions/multicheckout';


function hasAnyTravelerWithoutRates(profiles) {
    return compact(profiles).length > 0;
}

function hasAnyBookableTraveler(profiles) {
    return compact(profiles).filter(({isBookable}) => isBookable).length > 0;
}

export const BOOK_ATTR_VALUES = {
    default: 'Non-SC',
    cheapest: 'SC-cheapest',
    'best_overall_rate': 'SC-best-rate',
    'best_non_loyalty_rate': 'SC-best-non-loyalty',
    'best_loyalty_rate': 'SC-best-loyalty',
};

function NotAllowed() {
    return (<span className="h-rate__multi-dropdown__item">
        <strong className="h-rate__multi-dropdown__title">Status:</strong>
        <span className="highlight-red">
            {gettext('Booking is not allowed.')}
            <AnnotateTooltip id={'not_allowed'}>
                <Glyphicons bsClass="glyphicon" glyph="info-sign" />
                {gettext('Booking is not allowed for this traveler. In order to proceed, select  TSPM profile or remove this traveler.')}
            </AnnotateTooltip>
        </span>
    </span>);
}

function mapStateProps(state, props) {
    const {isAlternative=false} = getHotelById(state, props.hotelId) || {};
    const totalHotels = state.hotels.hotels.total;

    return {
        profiles: getAllProfiles(state)
            .map((profile, idx)=> (
                (getSelectedRateKey(state, idx) || getBookingUID(state, idx))
                    ? null : {...profile, isBookable: isBookable(state, idx)}
            )),
        isMulti: isMultiPnr(state),
        isAlternative,
        totalHotels
    };
}

@connect(mapStateProps, (dispatch, {hotelId, rateKey})=> ({
    onSelect: (index) => () => index === 'checkout' ? noop : dispatch(
        index === 'remaining'
            ? selectRemainingRate(hotelId, rateKey)
            : selectRate(hotelId, rateKey, index)
    ),
}))
export default class MultiBookButton extends React.Component {
    static propTypes = {
        profiles: PropTypes.arrayOf(
            PropTypes.shape({
                'pnr': PropTypes.string,
                'pnr_name_title': PropTypes.string,
                'pnr_name_first': PropTypes.string,
                'pnr_name_last': PropTypes.string,
                'tspm_salutation': PropTypes.string,
                'tspm_first_name': PropTypes.string,
                'tspm_last_name': PropTypes.string,
            })
        ),
        isMulti: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        multiBookLabel: PropTypes.string.isRequired,
        remainingBookLabel: PropTypes.string.isRequired,
        'data-booktype': PropTypes.string,
        isAlternative: PropTypes.bool,
        variant: PropTypes.string,
        totalHotels: PropTypes.number,
    };

    static defaultProps = {
        'data-booktype': BOOK_ATTR_VALUES.default,
        isAlternative: false,
        variant: 'primary',
    };

    getItemClass({isBookable}) {
        return classnames('h-rate__dropdown-menu__multi-li', {
            'h-rate__dropdown-menu__multi-li--restricted': !isBookable,
        });
    }

    render() {
        const {profiles, isMulti, onSelect, multiBookLabel, remainingBookLabel, 'data-booktype': bookType,  isAlternative, totalHotels, ...other} = this.props;
        if (!isMulti) {
            return <BookButton {...other} data-number-of-hotels={totalHotels} data-booktype={bookType} data-alternative={isAlternative} />;
        }
        const bookBtnDataAttributes = pick(other, ['data-number-of-rates', 'data-rate-position', 'data-hotel-position']);

        return (<DropdownButton id="book-for" title={multiBookLabel} variant={this.props.variant}>
            {profiles.map((profile, idx)=> profile && (
                <Dropdown.Item eventKey={idx} key={idx}
                    {...bookBtnDataAttributes}
                    data-number-of-hotels={totalHotels}
                    className={this.getItemClass(profile)}
                    disabled={!profile.isBookable}
                    data-booktype={bookType}
                    data-alternative={isAlternative}
                    onClick={onSelect(idx)}
                >
                    <span className="h-rate__multi-dropdown__item">
                        <strong className="h-rate__multi-dropdown__title">{gettext('PNR:')}</strong> {profile.pnr}
                    </span>
                    <span className="h-rate__multi-dropdown__item">
                        <strong className="h-rate__multi-dropdown__title">{gettext('Traveler:')}</strong> {getProfileName(profile)}
                    </span>
                    {!profile.isBookable && (<NotAllowed />)}
                </Dropdown.Item>))}

            {!hasAnyTravelerWithoutRates(profiles) && (<Dropdown.Item
                eventKey="checkout"
                className="h-rate__multi-dropdown__text-block"
                onClick={onSelect('checkout')}
            >
                <p>
                    <strong>{gettext('Rates for all travelers has been selected.')}</strong>
                </p>

                <p>{gettext('You can proceed to checkout page.')}</p>

                <ProceedMultiCheckout />
            </Dropdown.Item>)}

            {hasAnyBookableTraveler(profiles) && (<Dropdown.Item
                {...bookBtnDataAttributes}
                data-number-of-hotels={totalHotels}
                eventKey="remaining"
                data-booktype={bookType}
                onClick={onSelect('remaining')}
            >
                <span className="h-rate__col__dropdown-lnk">
                    {remainingBookLabel}
                </span>
            </Dropdown.Item>)}
        </DropdownButton>);
    }
}
