import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

export const getCdIdMatchedValue = (vendor, number, idNumbers, cdNumbers, idCdMatrix, isCdUpdate = false) => {
    const searchKey = isCdUpdate ? 'idNumber' : 'cdNumber';

    const cdIdx = findIndex(cdNumbers, {vendor});
    const idIdx = findIndex(idNumbers, {vendor});
    const matrixEntity = find(idCdMatrix, {[searchKey]: number});

    if (matrixEntity) {
        cdNumbers[cdIdx] = {...cdNumbers[cdIdx], number: isCdUpdate ? matrixEntity.cdNumber : number};
        idNumbers[idIdx] = {...idNumbers[idIdx], idNumber: isCdUpdate ? number : matrixEntity.idNumber};

        return {
            cdNumbers,
            idNumbers,
        };
    }

    return null;
};
