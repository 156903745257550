import React from 'react';
import PropTypes from 'prop-types';
import formatAddress from 'airborne/search2/helpers/address';

export class CollectionDeliveryAddress extends React.Component {
    static propTypes = {
        address: PropTypes.object.isRequired,
    };

    render() {
        const {address} = this.props;
        const {
            countryCode,
            postalCode,
            stateCode,
            city,
            phone,
            streetAddress,
        } = address;

        const phoneNumber = phone?.number;

        return (
            <div>
                <div>
                    {formatAddress(streetAddress, city, stateCode, postalCode, countryCode)}
                </div>
                <div>
                    {phoneNumber}
                </div>
            </div>
        );
    }
}
