import range from 'lodash/range';

import {getPnrCount} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import generateUUID from 'airborne/checkout2/helpers/uuid';
import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import {getSelectedRateKey} from 'airborne/search2/helpers/rates';
import {selectNextIndex, selectNotBooked} from 'airborne/store/modules/checkout_hotels/selectors/bookable';

import {getRecommendations, getMultiRecommendations} from 'airborne/store/modules/checkout_hotels/actions/recommend';
import {confirmBooking} from './book';
import {isBookable} from 'airborne/homepage2/helpers/pnr';
import browserHistory from 'airborne/browserHistory';

export function clearSelectedRate(index) {
    return {type: 'CLEAR_RATE_BOOK', index};
}

export function selectRate(hotelId, rateKey, index) {
    return function selectRateD(dispatch) {
        dispatch(getRateDetails(hotelId, rateKey, true))
            .catch(()=> dispatch(clearSelectedRate(index)));
        dispatch(getRecommendations(hotelId, rateKey, index));
        dispatch({type: 'SELECT_RATE_BOOK', hotelId, rateKey, index});
    };
}

export function selectRemainingRate(hotelId, rateKey) {
    return function selectRemainingRateD(dispatch, getState) {
        const indexes = range(0, getPnrCount(getState())).filter((index)=> (
            !getSelectedRateKey(getState(), index) && isBookable(getState(), index)
        ));
        dispatch(getRateDetails(hotelId, rateKey))
            .catch(()=> indexes.forEach((index)=> {
                dispatch(clearSelectedRate(index));
            })
            );
        dispatch(getMultiRecommendations(hotelId, rateKey, indexes));
        dispatch({type: 'SELECT_REMAINING_RATE_BOOK', hotelId, rateKey, index: indexes});
    };
}

export function toMultiCheckout() {
    browserHistory.push('/ui/hotels/checkout/');
    return {type: 'TO_MULTI_CHECKOUT', index: 'all', uuid: generateUUID()};
}

export function switchBookingSlot(index) {
    return {type: 'SWITCH_BOOKING', index};
}

function createAllBookings() {
    return function createAllBookingsD(dispatch, getState) {
        return Promise.all(
            selectNotBooked(getState())
                .map((index)=> dispatch(confirmBooking(index)))
        );
    };
}

export function confirmAllBookings(index) {
    return function confirmBookingD(dispatch, getState) {
        const firstIncomplete = selectNextIndex(getState(), index);
        return (firstIncomplete === null)
            ? dispatch(createAllBookings())
            : dispatch(switchBookingSlot(firstIncomplete));
    };
}
