import gettext from 'airborne/gettext';
import {CharField} from 'midoffice/newforms/fields-stateless';

const ID_REGEXP = /^[A-Za-z0-9\.]+$/;

function validateId(value) {
    if (!value) {
        return null;
    }

    return ID_REGEXP.test(value)
        ? null
        : gettext('Only digits, letters and dot are allowed');
}

const IdField = {
    ...CharField,
    validate: validateId,
};

export default IdField;
