import React from 'react';
import PT, {oneOfType} from 'prop-types';
import gettext from 'airborne/gettext';

export default class CancellationPolicyList extends React.Component {
    static propTypes = {
        cancellationPolicy: oneOfType([
            PT.string,
            PT.array,
        ]),
    }

    render() {
        const {cancellationPolicy} = this.props;
        const byDot = /\.\s*(?=[A-Z])/;

        if (!cancellationPolicy) {
            return gettext('Cancellation policy is not available for this rate');
        }

        const mappedPolicy = Array.isArray(cancellationPolicy)
            ? cancellationPolicy.join('. ')
            : cancellationPolicy;

        if (mappedPolicy?.split(byDot).length <= 1) {
            return <>{mappedPolicy}</>
        }

        return (
            <ul>
                {mappedPolicy
                    .split(byDot)
                    .map(policyPart => <li key={policyPart}>{policyPart}</li>)
                }
            </ul>
        )
    }
}
