import settings from "airborne/settings";

const SURVEY_KEY = 'yJGxzQP8Q0OurSgIsh_VE0boj6V2BtFGqYHi7F1CkBVUNkRWRVExWjVPN0VTSDMxRUlJSFFRQUE3Ui4u';

export function shouldInitializeCustomerVoice() {
    return !isCustomerVoiceActive() && !location.pathname.startsWith('/bookings/');
}

export function shouldRemoveCustomerVoice(pathname) {
    return isCustomerVoiceActive() && pathname.startsWith('/bookings/');
}

function createSurveyInstance() {
    return new SurveyEmbed(SURVEY_KEY,"https://customervoice.microsoft.com/","https://mfpembedcdnmsit.azureedge.net/mfpembedcontmsit/","true");
}

export function removeCustomerVoiceSurvey() {
    const customerVoiceContainer = document.getElementById('MfpEmbed_Button_Container_Collapse');
    const customerVoiceContainerExpanded = document.getElementById('MfpEmbed_Button_Container');

    if (customerVoiceContainer) {
        customerVoiceContainer.remove();
    }

    if (customerVoiceContainerExpanded) {
        customerVoiceContainerExpanded.remove();
    }
}

function isCustomerVoiceActive() {
    return Boolean(document.getElementById("MfpEmbed_Button"));
}

export function renderCustomerVoiceButton() {
    const {'first_name': firstName, 'last_name': lastName} = settings.USER;
    const locale = settings.USER_LOCALE || '';

    if (typeof SurveyEmbed === 'undefined' || isCustomerVoiceActive()) {
        return;
    }

    const se = createSurveyInstance();

    const context = {
        "First Name": firstName,
        "Last Name": lastName,
        "locale": locale.replace('_', '-'),
    };
    se.renderButton(context);
}
