import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';

import {
    getPnrByProduct,
    getPnrProfile,
    getRawPnr,
    isMultiPnr
} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

import PNRView from 'midoffice/components/pnr-tool/PNRView';
import {hidePnr} from 'airborne/store/modules/header/actions/pnr';
import settings from 'airborne/settings';

function mapStateProps(state) {
    const profile = getPnrProfile(state, 0);
    const raw = getRawPnr(state, 0);
    const pnr = getPnrByProduct(state);
    return (pnr && !isMultiPnr(state))
        ? {pnr, entityId: profile && profile.company, show: raw}
        : {pnr: null, entityId: null, show: false};
}

export class ControlledRawPnrModal extends React.Component {
    static propTypes = {
        entityId: PropTypes.number,
        pnr: PropTypes.string,
        show: PropTypes.bool,
        onHide: PropTypes.func.isRequired,
    };

    state = {
        open: true,
        max: false,
    };

    handleOpen = () => {
        this.setState({open: !this.state.open, max: false});
    };

    handleMax = () => {
        this.setState({max: !this.state.max, open: true});
    };

    render() {
        const {pnr, entityId, show} = this.props;
        const {open, hide, max} = this.state;
        const system = settings.SUB_PARTNER;
        if (hide || !pnr || !show) {
            return null;
        }
        const cls = classnames('float-panel', 'float-panel--wide', {
            'float-panel--minimized': !open,
            'float-panel--maximized': max,
        });
        const openCls = classnames('glyphicon', {
            'glyphicon-triangle-bottom': open,
            'glyphicon-triangle-top': !open,
        });
        const maxCls = classnames('glyphicon', {
            'glyphicon-resize-small': max,
            'glyphicon-resize-full': !max,
        });
        return (<div className={cls} data-testid="pnr-modal">
            <div className="float-panel__header float-panel__header--control-panel">
                <h4 className="float-panel__title">
                    {gettext('PNR Information')}
                </h4>
                <div className="float-panel__header__controls">
                    <span className={ maxCls } onClick={this.handleMax} />
                    <span className={ openCls } onClick={this.handleOpen} />
                    <span className="glyphicon glyphicon-remove" onClick={this.props.onHide} />
                </div>
            </div>

            <div className="float-panel__body">
                <PNRView match={{params: {pnr, entityId, system}}} plain compat />
            </div>
            <div className="float-panel__bg" />
        </div>);
    }
}

const RawPnrModal = connect(mapStateProps, (dispatch) => ({
    onHide: ()=> dispatch(hidePnr(0))
}))(ControlledRawPnrModal);
export default RawPnrModal;
