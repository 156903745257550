/* global global */
/* eslint-disable immutable/no-mutation */

export default class StorageService {
    storageTypes = {
        'local': 'localStorage',
        'session': 'sessionStorage'
    }
    constructor(storageType, key) {
        this.storage = global[this.storageTypes[storageType]];
        this.key = key;
    }

    getItem() {
        return JSON.parse(this.storage.getItem(this.key));
    }

    setItem(value) {
        return this.storage.setItem(this.key, JSON.stringify(value));
    }

    clear() {
        return this.storage.removeItem(this.key);
    }
}
