import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';

import dataset from 'airborne/bookings2/helpers/dataset';
import gettext from 'airborne/gettext';

import Glyphicons from 'midoffice/components/Glyphicons';

function getOriginalName({
    salutation,
    original_first_name: origFirstName,
    original_last_name: origLastName
}) {
    return compact([
        salutation,
        origFirstName,
        origLastName,
    ]).join(' ');
}

export default class ProfileList extends React.Component {
    static propTypes = {
        selected: PropTypes.string,
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleClick = (event) => {
        const {id=null} = dataset(event.currentTarget);
        this.props.onChange(id);
    }

    renderTraveler(tspm, isLastUpdated) {
        const {id, companyName, email} = tspm;
        const {selected} = this.props;
        const cls = (selected === id) ? 'modal-body__scroll-item--selected' : null;
        return (<li className={cls} key={id} data-id={id} onClick={this.handleClick} >
            {getOriginalName(tspm)}

            {isLastUpdated && <span className="modal-body__scroll__tag">
                <Glyphicons bsClass="glyphicons" glyph="ok" /> {gettext('Last Updated')}
            </span>}
            <div className="modal-body__scroll-info">
                <div>{email}</div>
                <div>{companyName || gettext('Company Unknown')}</div>
            </div>
        </li>);
    }

    render() {
        const {data} = this.props;
        return (<ul className="modal-body__scroll-list">
            {data.map((el, idx)=> this.renderTraveler(el, idx === 0 && data.length > 1))}
        </ul>);
    }
}
