import noop from 'lodash/noop';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class MaskInput extends React.Component {
    static propTypes = {
        mask: PropTypes.string.isRequired,
        maskChar: PropTypes.string,
        formatChars: PropTypes.object,

        className: PropTypes.string,
        disabled: PropTypes.bool,
        inputSize: PropTypes.string,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.any,

        'data-testid': PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        style: PropTypes.object,
    };

    static defaultProps = {
        maskChar: ' ',
        className: 'form-control',
        inputSize: 'medium',
        disabled: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop
    };

    handleChange = (event)=> {
        let {value} = event.target;
        const {onChange, name} = this.props;

        onChange(value, name);
    };

    render() {
        const {mask, maskChar, formatChars, name, value, placeholder, className, inputSize, disabled, style} = this.props;
        return (
            <InputMask
                mask={mask}
                maskChar={maskChar}
                formatChars={formatChars}
                className={cx(className, `input-${inputSize}`)}
                disabled={disabled}
                value={value}
                style={style}
                name={name}
                placeholder={placeholder}
                onChange={this.handleChange}
                data-testid={this.props['data-testid']}
            />
        );
    }
}
