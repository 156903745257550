import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import Footer from 'airborne/header2/Footer';
import Header from 'airborne/header2/Header';
import RawPnrModal from 'airborne/header2/RawPnrModal';
import BodyClassName from 'airborne/homepage2/BodyClassName';
import ModalContainer from 'airborne/homepage2/ModalContainer';
import Step from 'airborne/homepage2/components/Step';
import {MicroFrontEndsContext} from 'airborne/microFrontEnds/MicroFrontEnds';
import settings from 'airborne/settings';
import FeatureFlagModal from 'airborne/store/modules/featureFlags/Modal';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {renderCustomerVoiceButton, shouldInitializeCustomerVoice} from 'airborne/utils/customerVoice';
import Maintenance from 'midoffice/components/Maintenance';
import ProviderTestEnvsWarning from 'midoffice/components/ProviderTestEnvsWarning';
import UATWarning from 'midoffice/components/UATWarning';
import AlertModal from 'midoffice/components/modals/AlertModal';
import ConfirmModal from 'midoffice/components/modals/ConfirmModal';
import SessionModal from 'midoffice/components/modals/SessionModal';
import HeapAnalyticsSecretFields
    from "airborne/homepage2/HeapAnalyticsSecretFields";

const FEATURE_FLAG_URL = '/internal/accounts/flags';

@connect(state => ({
    showRail: getFeatureFlag(state, 'RAIL_ENABLED'),
    showFerrari: getFeatureFlag(state, 'FERRARI_ENABLED'),
    showCustomerVoice: getFeatureFlag(state, 'CUSTOMER_VOICE_ENABLED'),
}))
export default class Homepage extends React.Component {
    static propTypes = {
        showRail: PropTypes.bool,
        showFerrari: PropTypes.bool,
        showCustomerVoice: PropTypes.bool,
    };
    componentDidMount() {
        this.maybeShowCustomerVoice();
        this.maybeLoadRail();
    }
    componentDidUpdate() {
        // The feature flags can arrive after the mount, so we need to try again here.
        this.maybeShowCustomerVoice();
        this.maybeLoadRail();
        this.maybeLoadFerrari();
    }

    static contextType = MicroFrontEndsContext;

    maybeLoadRail() {
        if (this.props.showRail) this?.context?.loadMicroFrontEnd?.('rail', true);
    }

    maybeLoadFerrari() {
        if (this.props.showFerrari) this?.context?.loadMicroFrontEnd?.('ferrari', true);
    }

    maybeShowCustomerVoice() {
        if (this.props.showCustomerVoice && shouldInitializeCustomerVoice()) renderCustomerVoiceButton();
    }

    render() {
        return (
            <div>
                <Maintenance settings={settings} />
                <Header />
                <HeapAnalyticsSecretFields />
                <UATWarning />
                <ProviderTestEnvsWarning />
                <Step />
                <Footer />
                <AlertModal />
                <ConfirmModal />
                <ModalContainer />
                <SessionModal />
                <RawPnrModal />
                <BodyClassName />
                <FeatureFlagModal url={FEATURE_FLAG_URL} shortcut={['ctrl+alt+q', 'ctrl+alt+a']} />
            </div>
        );
    }
}
