import api from 'midoffice/helpers/api';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {apiError} from 'airborne/search2/helpers/apiError';
import {searchBookings} from 'midoffice/bookings/actions';
import {hideModal} from 'airborne/store/modules/header/actions/modal';
import {
    changeResolution,
    changeResolutionSuccess,
    changeResolutionFailure,
    cleanupResolutionForm,
} from './actions';
import {getActionableBookingResolveComment} from '../selectors';

export function hideResolutionModal() {
    return function hideResolutionModalD(dispatch) {
        dispatch(cleanupResolutionForm());
        dispatch(hideModal());
    };
}

function requestFailure(response) {
    return function requestFailureD(dispatch) {
        const message = apiError(response) || 'ERROR UNKNOWN';
        return dispatch(changeResolutionFailure(message));
    };
}

export function makeChangeResolutionRequest({id: bookingUID, issues}, isNote = false) {
    return function makeChangeResolutionRequestD(dispatch, getState) {
        dispatch(changeResolution());

        const UPDATE_RESERVATION_DETAILS = '/latest/hotels/update_reservation_details';
        const comment = getActionableBookingResolveComment(getState());
        const data = dropEmpty({
            'booking_uid': bookingUID,
            'system': 'aft',
            'booking_event': isNote ? 'add_note' : 'change_resolution',
            'resolution_for': isNote ? null : issues.shift(),
            'details': comment,
        });

        return api('POST', UPDATE_RESERVATION_DETAILS, {data})
            .then((response) => dispatch(changeResolutionSuccess(response)))
            .then(() => dispatch(hideResolutionModal()))
            .then(() => dispatch(searchBookings()))
            .catch((response) => dispatch(requestFailure(response)));
    };
}

export function markAsResolved(booking) {
    return makeChangeResolutionRequest(booking);
}

export function addNote(booking) {
    return makeChangeResolutionRequest(booking, true);
}
