
export function getEmailOffers(state) {
    return state.emailOffers.items;
}
export function getEmailOffersErrors(state) {
    return state.emailOffers.errors;
}

export function getEmailOffersPnr(state) {
    return state.emailOffers.pnr;
}
export function getEmailOffersSortBy(state) {
    return state.emailOffers.sortBy;
}
