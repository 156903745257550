'process i18n';

import {ChoiceField, MultiChoiceField} from 'midoffice/newforms/fields-stateless';
import {createSchema, required} from 'midoffice/newforms/helpers';


const SendOffersSchema = createSchema({
    fields: {
        'bed_types': required(MultiChoiceField),
        'room_type': required(ChoiceField),
    }
});

export default SendOffersSchema;
