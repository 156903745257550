import {roundToDecimalNumber} from 'airborne/utils/numbers';
import * as actionTypes from '../actionTypes';
import {getSelectedFlightPriceTravelerPrice} from 'airborne/store/modules/search_air/selectors';
import {getAirExchangeBookingBaseFarePrice} from '../selectors/params';
import {INITIAL_MANUAL_EXCHANGE_FORM} from '../reducers/manualExchangeForm';
import {
    getManualExchangeMergedTaxes,
    getManualExchangeTaxesAdditionalCollectionTotal,
    getManualExchangeTaxesRefundTotal,
} from '../selectors/manualExchangeForm';
import {goToManualExchange} from './params';

const changeManualExchangeForm = ({value, errors}) => ({
    type: actionTypes.AIR_MANUAL_EXCHANGE_FORM_CHANGE,
    payload: {value, errors},
});

const patchManualExchangeForm = ({toChange, toRemove}) => ({
    type: actionTypes.AIR_MANUAL_EXCHANGE_FORM_PATCH,
    payload: {toChange, toRemove},
});

export const getSyncedManualExchangeFormValue = (state, formValue) => {
    const taxes = getManualExchangeMergedTaxes(state, formValue);

    const originalBaseFarePrice = formValue?.originalBaseFarePrice || getAirExchangeBookingBaseFarePrice(state);
    const newBaseFarePrice = formValue?.newBaseFarePrice || getSelectedFlightPriceTravelerPrice(state).totalBeforeTaxes;
    const baseFareDifference = roundToDecimalNumber(newBaseFarePrice - originalBaseFarePrice);

    const addCollectTaxes = getManualExchangeTaxesAdditionalCollectionTotal(state, formValue);
    const residualTaxes = getManualExchangeTaxesRefundTotal(state, formValue);

    const totalCollect = roundToDecimalNumber(addCollectTaxes + (baseFareDifference > 0 ? baseFareDifference : 0) + (formValue?.penalty || 0));
    const totalRefund = roundToDecimalNumber(Math.abs(residualTaxes + (baseFareDifference < 0 ? baseFareDifference : 0)));

    return {
        taxes,
        originalBaseFarePrice,
        newBaseFarePrice,
        baseFareDifference,
        addCollectTaxes,
        residualTaxes,
        totalCollect,
        totalRefund,
    };
};

export const syncChangeManualExchangeForm = ({value, errors}) => (dispatch, getState) => {
    const formValue = {
        ...value,
        ...getSyncedManualExchangeFormValue(getState(), value)
    };
    dispatch(changeManualExchangeForm({value: formValue, errors}));
};

export const prefillManualExchangeForm = () => (dispatch, getState) => {
    const value = {
        ...INITIAL_MANUAL_EXCHANGE_FORM,
        ...getSyncedManualExchangeFormValue(getState())
    };
    dispatch(patchManualExchangeForm({toChange: {value}}));
};

export const makeManualExchange = () => (dispatch) => {
    dispatch(prefillManualExchangeForm());
    dispatch(goToManualExchange());
};
