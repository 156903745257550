'process i18n';
import React from 'react';

import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Glyphicons from 'midoffice/components/Glyphicons';


class ModalAlert extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        title: PropTypes.string.isRequired,
        message: PropTypes.any,
    };

    render () {
        const {type, title, message} = this.props;
        return (<Alert closeLabel="Close alert" variant={type}>
            <Glyphicons bsClass="glyphicon" glyph="exclamation-sign" />
            <strong>{title}</strong>
            <p className="alert__text">
                { message}
            </p>
        </Alert>);
    }
}

export default ModalAlert;
