'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {LabeledSection} from 'airborne/checkout2/LabeledSection';

export default class CheckinInstructions extends React.Component {
    static propTypes = {
        'special_check_in_instructions': PropTypes.string,
        'check_in_instructions': PropTypes.string,
        'important_info': PropTypes.string,
    };

    render() {
        const {
            'special_check_in_instructions': special,
            'check_in_instructions': instructions,
            'important_info': importantInfo
        } = this.props;
        if (!special && !instructions && !importantInfo) {
            return null;
        }

        return (<>
            <h4 className="modal-rate-desc__title">
                <strong>{gettext('Know Before You Go')}</strong>
            </h4>
            {(special || instructions) && <div className="modal-rate-desc__extra">
                <p>
                    <b>{gettext('Check-In Instructions')}</b>
                </p>
                <p>
                    <i>{gettext('The following details are general hotel rules. Information related to this specific rate may differ. Refer to the description above for the most accurate details for this rate.')}</i>
                </p>
                {<span dangerouslySetInnerHTML={{__html: special}}/>}
                {<div dangerouslySetInnerHTML={{__html: instructions}}/>}
            </div>}
            {importantInfo && <LabeledSection label={gettext('Important information')}>
                {<div dangerouslySetInnerHTML={{__html: importantInfo}} />}
            </LabeledSection>}
        </>);
    }
}
