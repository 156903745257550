import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {injectFormContext, injectField} from 'midoffice/newforms/decorators';
import Select from 'midoffice/newforms/widgets/Select';
import Input from 'midoffice/newforms/widgets/Input';
import {TaxShape} from './shapes';
import {formatPrice} from './helpers';

@injectField
class TaxesListField extends React.Component {
    static propTypes = {
        value: TaxShape,
        onChange: PropTypes.func,
        index: PropTypes.number,
        currentCurrency: PropTypes.string,
        selectedCurrency: PropTypes.string,
    };

    handleChange = (fieldValue, fieldName) => {
        const {value, onChange} = this.props;
        onChange({...value, [fieldName]: fieldValue});
    };

    renderOldPrice() {
        const {
            value: {oldPrice},
        } = this.props;

        return <td>{oldPrice?.toFixed(2) || '-'}</td>;
    }

    renderNewPrice() {
        const {
            value: {newPrice},
        } = this.props;

        return <td>{newPrice?.toFixed(2) || '-'}</td>;
    }

    renderTicketPrice() {
        const {
            value: {oldPrice, ticketPrice},
        } = this.props;
        const hasOldPrice = typeof oldPrice === 'number';
        const isAdditionalCost = ticketPrice > 0;

        return (
            <td>
                {hasOldPrice && (
                    <>
                        <div className="col">
                            <strong>PD</strong>
                        </div>
                        <div className="col">{oldPrice.toFixed(2)}</div>
                    </>
                )}
                <br />
                {isAdditionalCost && (
                    <>
                        <div className="col">A/C</div> +{ticketPrice.toFixed(2)}
                    </>
                )}
            </td>
        );
    }

    renderRefundValue() {
        const {
            value: {ticketPrice},
        } = this.props;
        const isRefund = ticketPrice < 0;

        return <td>{isRefund ? Math.abs(ticketPrice).toFixed(2) : '-'}</td>;
    }

    renderRefundSelect() {
        const {
            value: {refundable, oldPrice, newPrice},
        } = this.props;
        const isOnlyOnePrice = !oldPrice || !newPrice;
        const isOldPriceCheaper = oldPrice <= newPrice;
        const isRefundSelectionRestricted = isOnlyOnePrice || isOldPriceCheaper;

        return (
            <td>
                <Select
                    onChange={this.handleChange}
                    value={refundable}
                    name="refundable"
                    choices={[
                        [true, 'Yes'],
                        [false, 'No'],
                    ]}
                    disabled={isRefundSelectionRestricted}
                />
            </td>
        );
    }

    render() {
        const {
            value: {designator},
        } = this.props;

        return (
            <tr>
                <th>{designator}</th>
                {this.renderOldPrice()}
                {this.renderNewPrice()}
                {this.renderTicketPrice()}
                {this.renderRefundValue()}
                {this.renderRefundSelect()}
            </tr>
        );
    }
}

@injectFormContext
export default class Editor extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.object,
        currentCurrency: PropTypes.string,
        selectedCurrency: PropTypes.string,
    };

    inputProps = {
        inputSize: 'xsmall',
        type: 'float',
        precision: 2
    }

    renderOldPriceTotal(oldPriceTotal) {
        const {currentCurrency} = this.props;
        return (
            <td>
                <strong>{formatPrice(oldPriceTotal, currentCurrency)}</strong>
            </td>
        );
    }

    renderNewPriceTotal() {
        const {
            value: {taxes},
            selectedCurrency
        } = this.props;
        const newPriceTotal = taxes.reduce((acc, {newPrice}) => acc + (newPrice || 0), 0);

        return (
            <td>
                <strong>{formatPrice(newPriceTotal, selectedCurrency)}</strong>
            </td>
        );
    }

    renderTotalTicketPrice(oldPriceTotal) {
        const {
            value: {addCollectTaxes},
            currentCurrency
        } = this.props;
        const total = oldPriceTotal + addCollectTaxes;

        return (
            <td>
                <div>
                    <div className="col">
                        <strong>PD</strong>
                    </div>
                    <div className="col">{oldPriceTotal.toFixed(2)}</div>
                </div>
                <div>
                    <div className="col">
                        <strong>A/C</strong>
                    </div>
                    <div className="col">+{addCollectTaxes.toFixed(2)}</div>
                </div>

                <hr className="hr-condensed" />

                <div>
                    <div className="col">
                        <strong>{gettext('Total')}</strong>
                    </div>
                    <div className="col">{formatPrice(total, currentCurrency)}</div>
                </div>
            </td>
        );
    }

    renderTotalRefund() {
        const {
            value: {residualTaxes},
            currentCurrency
        } = this.props;

        return (
            <td>
                <strong>{formatPrice(Math.abs(residualTaxes), currentCurrency)}</strong>
            </td>
        );
    }

    renderEquivalentFareNew() {
        return (
            <tr>
                <td>
                    <p className="form-control-static">{gettext('Equivalent Fare New')}</p>
                </td>
                <td className="text-right">
                    <Input.Field name="newBaseFarePrice" inputSize="xsmall" readOnly {...this.inputProps} />
                </td>
            </tr>
        );
    }

    renderEquivalentFareOriginal() {
        return (
            <tr>
                <td>
                    <p className="form-control-static">{gettext('Equivalent Fare Original Ticket')}</p>
                </td>
                <td className="text-right">
                    <Input.Field name="originalBaseFarePrice" inputSize="xsmall" {...this.inputProps} />
                </td>
            </tr>
        );
    }

    renderEquivalentBaseFareDifference() {
        const {
            value: {baseFareDifference},
            currentCurrency
        } = this.props;

        return (
            <tr>
                <th>
                    <p className="form-control-static">{gettext('Equivalent Fare Difference')}</p>
                </th>
                <th className="text-right">
                    <p className="form-control-static">{formatPrice(baseFareDifference, currentCurrency)}</p>
                </th>
            </tr>
        );
    }

    renderAddCollectTaxes() {
        return (
            <tr>
                <td>
                    <p className="form-control-static">{gettext('Add Collect Taxes')}</p>
                </td>
                <td className="text-right">
                    <Input.Field name="addCollectTaxes" inputSize="xsmall" readOnly {...this.inputProps} />
                </td>
            </tr>
        );
    }

    renderResidualTaxes() {
        return (
            <tr>
                <td>
                    <p className="form-control-static">{gettext('Residual Taxes')}</p>
                </td>
                <td className="text-right">
                    <Input.Field name="residualTaxes" inputSize="xsmall" readOnly {...this.inputProps} />
                </td>
            </tr>
        );
    }

    renderPenalty() {
        return (
            <tr>
                <th>
                    <p className="form-control-static">{gettext('Penalty')}</p>
                </th>
                <td className="text-right">
                    <Input.Field name="penalty" inputSize="xsmall" {...this.inputProps} />
                </td>
            </tr>
        );
    }

    renderCommission() {
        return (
            <table className="table table-condensed">
                <tr>
                    <th colSpan="2">{gettext('Commission')}</th>
                </tr>
                <tr>
                    <td>
                        <p className="form-control-static">{gettext('No Commission due')}</p>
                    </td>
                    <td className="text-right">
                        <Input.Field name="commission" inputSize="xsmall" readOnly {...this.inputProps} />
                    </td>
                </tr>
            </table>
        );
    }

    renderTotalRefundAndCollect() {
        const {value: {
            totalCollect,
            totalRefund,
        }, currentCurrency} = this.props;

        return (
            <table className="table table-condensed">
                <tr>
                    <th>
                        <p className="form-control-static text-xl">{gettext('Add Collect')}</p>
                    </th>
                    <th className="text-right">
                        <p className="form-control-static text-xl">{formatPrice(totalCollect, currentCurrency)}</p>
                    </th>
                </tr>
                <tr>
                    <th>
                        <p className="form-control-static text-xl">{gettext('Refund')}</p>
                    </th>
                    <th className="text-right">
                        <p className="form-control-static text-xl">{formatPrice(totalRefund, currentCurrency)}</p>
                    </th>
                </tr>
            </table>
        );
    }

    render() {
        const {
            value: {taxes},
        } = this.props;
        const oldPriceTotal = taxes.reduce((acc, {oldPrice}) => acc + (oldPrice || 0), 0);

        return (
            <div className="form-horizontal">
                <div className="rev-details__section">
                    <div className="rev-details__content">
                        <h3 className="rev-details__content__title">{gettext('Tax Assessment')}</h3>

                        <table className="table table-condensed">
                            <tr>
                                <th>{gettext('Designator')}</th>
                                <th>{gettext('Old Ticket')}</th>
                                <th>{gettext('New Fare')}</th>
                                <th>{gettext('New Ticket')}</th>
                                <th>{gettext('Refund')}</th>
                                <th>{gettext('Refundable?')}</th>
                            </tr>
                            <TaxesListField.Field multi name="taxes" plainWidget noAddMore taxes={taxes} />
                            <tr>
                                <th />
                                {this.renderOldPriceTotal(oldPriceTotal)}
                                {this.renderNewPriceTotal()}
                                {this.renderTotalTicketPrice(oldPriceTotal)}
                                {this.renderTotalRefund()}
                                <td />
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="rev-details__section rev-details__section--attn">
                    <div className="rev-details__content">
                        <h3 className="rev-details__content__title">{gettext('Exchange Calculation')}</h3>
                        <div className="row">
                            <div className="col-xs-5">
                                <table className="table table-condensed">
                                    {this.renderEquivalentFareNew()}
                                    {this.renderEquivalentFareOriginal()}
                                    {this.renderEquivalentBaseFareDifference()}
                                    {this.renderAddCollectTaxes()}
                                    {this.renderResidualTaxes()}
                                    {this.renderPenalty()}
                                </table>

                                {this.renderTotalRefundAndCollect()}
                            </div>

                            <div className="col-xs-2" />

                            <div className="col-xs-5">
                                {this.renderCommission()}
                                <table className="table table-condensed">
                                    <tr>
                                        <td>
                                            <span className="highlight-red">
                                                * You risk an ADM (agency debit memo) if a manual exchange is invalid
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
