'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';


export default class CancellationPolicy extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        policy: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
    };

    render() {
        const {policy, className} = this.props;
        const values = policy && (Array.isArray(policy) ? policy : [policy]) || null;
        return (<ul className={className}>
            {values ? values.map((text, idx)=> (<li key={idx}>{text}</li>))
                : (<li>{gettext('N/A')}</li>)}
        </ul>);
    }
}
