/* global global */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import 'url-search-params-polyfill';

import {render, unmountComponentAtNode} from 'react-dom';
import SentryAdapter from 'airborne/sentryAdapter';
import setPublicPath from 'midoffice/publicPath';
import settings from 'airborne/settings';
import store from 'airborne/store';
import {installTap} from 'airborne/store/config/rootMiddleware';
import {savedForm, saveSSO, setContainerId} from 'airborne/store/modules/homepage/actions/saved';
import history from 'airborne/homepage2/history';
import {loadI18N, setLang} from 'airborne/store/modules/header/actions/i18n';
import {changeTo} from 'airborne/store/modules/search_hotels/actions/changeTo';

import '../../../midoffice/less/bootstrap-aft.less';

import {
    getSystemData,
    getUserData,
    storeUrl,
} from 'airborne/store/modules/login/actions/login';
import {
    parseHash,
    setCheckinLimits,
} from 'airborne/store/modules/login/helpers/login';
import setFavicon from 'airborne/utils/setFavicon';
import {requestFeatureFlags} from 'airborne/store/modules/featureFlags/actions';
import {connectStreamService} from 'airborne/store/modules/stream/actions';
import session from 'airborne/session';
import {initialActions} from './initialActions';

import App from 'airborne/homepage2/App';
import AppLoader from 'airborne/homepage2/AppLoader';
import * as locale from 'airborne/homepage2/locale';
import {setBroadStreetTargets} from 'airborne/helpers/setBroadStreetTargets';
import {
    getMaintenance,
    startListenMaintenances
} from "airborne/store/modules/maintenance/actions";
import {listenTimeSession} from "midoffice/data/actions";

function renderAppLoader() {
    render(<AppLoader />, document.getElementById('homepage2'));
}

function restart() {
    unmountComponentAtNode(
        document.getElementById('homepage2')
    );
    locale.configure(store, restart);
    render(<App />, document.getElementById('homepage2'));
}

function searchFor(value, label, type) {
    store.dispatch(changeTo(value, label, type));
}

async function start({form}) {
    const userId = settings.USER && settings.USER.id;
    renderAppLoader();
    await getSystemData();
    const initialSearchParams = new URLSearchParams(global.location.search);

    if (location.pathname === '\/' && location.search === '') {
        session.set('sid', '');
    }
    await store.dispatch(requestFeatureFlags());

    if (userId) {
        await store.dispatch(getUserData());
    }

    if (!userId) {
        store.dispatch(setLang(settings.LANGUAGE_CODE));
    }
    else {
        store.dispatch(loadI18N());
    }

    store.dispatch(storeUrl(global.location));
    SentryAdapter.init(settings);
    setPublicPath(settings);
    setFavicon(settings);
    setContainerId(form);
    locale.configure(store, restart);
    listenTimeSession(store.dispatch);

    setBroadStreetTargets();

    const {cloneSid, hotelId, name} = parseHash(global.location.hash.toString());
    //modification flow works by the same logic as sso - we need some data from form and do some search
    //after start app. So we should check is it a modification flow or is it a sso flow.
    const shouldDoSearchAfterStart = form.hasOwnProperty('sso_url') || form.hasOwnProperty('sso_saml_art')
        || form['search_mode']?.modification;
    if (shouldDoSearchAfterStart) {
        if (form) {
            setCheckinLimits(form);
            store.dispatch(saveSSO(form));
            store.dispatch(savedForm(form, cloneSid)).then(() => {
                cloneSid && searchFor(`Hotel:${hotelId}`, decodeURIComponent(name), 'h');
                history(store, !cloneSid);
            });
        }
        history(store, !cloneSid);
    }

    await initialActions(initialSearchParams, settings, store);
    startListenMaintenances(store.dispatch);
    getMaintenance(store.dispatch);

    if (userId) store.dispatch(connectStreamService());

    render(<App />, document.getElementById('homepage2'));
}

global.homepage = {// eslint-disable-line immutable/no-mutation
    start,
    store,
    installTap,
};
