import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import gettext from 'airborne/gettext';
import Glyphicons from 'midoffice/components/Glyphicons';
import Loader from 'midoffice/components/Loader';
import {ReportingSchema} from 'midoffice/data/schemas/Reporting';


export default class ReportProcessingModal extends React.Component {

    static propTypes = {
        errors: PropTypes.object,
        message: PropTypes.string,
        reportUrl: PropTypes.string,
        sendTo: PropTypes.string,
        autoReporting: PropTypes.string,

        type: PropTypes.oneOf([
            'processing',
            'success',
            'error',
        ]),
        onHide: PropTypes.func.isRequired,
        onGenerateSuccess: PropTypes.func,
    };

    static defaultProps = {
        type: 'processing',
    };

    getFormattedErrors() {
        const {message, errors} = this.props;
        const schema = new ReportingSchema();

        if (isEmpty(errors)) {
            return message;
        }

        if (errors && typeof errors === 'string') {
            return errors;
        }

        if ('message' in errors) {
            return errors.message;
        }

        return map(errors.filters, (error, fieldName)=> (
            <div>{schema.fields[fieldName].label}: {error}</div>
        ));
    }

    handleHide = ()=> {
        if (this.props.type === 'success' && this.props.onGenerateSuccess) {
            this.props.onGenerateSuccess();
        }
        this.props.onHide();
    };

    renderProcessing() {
        return (
            <div>
                <div className="modal-content__box__loader">
                    <div className="modal-content__box__loader__text">
                        {gettext('In progress, please wait.')}
                    </div>
                    <Loader loading />
                </div>
            </div>
        );
    }

    renderSuccess() {
        const {reportUrl} = this.props;

        return (
            <div>
                <Alert variant="success" closeLabel="Close alert">
                    <Glyphicons bsClass="glyphicon" glyph="ok-sign"/>
                    <strong>{gettext('Complete')}</strong>

                    <div className="alert__text">
                        {reportUrl ? (
                            <p>
                                {gettext('Report created successfully.')}&nbsp;
                                <a href={reportUrl}> {gettext('Download report')}</a>
                            </p>
                        ) : (
                            <p>
                                {gettext('This report is taking longer than expected to generate. You may close this window, \
                                and when the report is finished it will be emailed to all recipients.')}
                            </p>
                        )}
                    </div>
                </Alert>
            </div>
        );
    }

    renderError() {
        return (
            <Alert variant="danger" className="alert-icon js-upload-fail">
                <h4 className="alert-title">
                    <Glyphicons bsClass="glyphicon" glyph="remove-sign"/>
                    <strong>{gettext('Failed')}</strong>
                </h4>

                <div className="alert__text">
                    <p>{this.getFormattedErrors()}</p>
                </div>
            </Alert>
        );
    }

    render() {
        const {type} = this.props;
        const processing = type === 'processing';

        const renders = {
            processing: 'renderProcessing',
            error: 'renderError',
            success: 'renderSuccess',
        };

        return (
            <Modal show onHide={this.handleHide}>
                <Modal.Header closeButton={!processing}>
                    <Modal.Title>{gettext('Create a New Report')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content__box modal-content__box--inner-padding">
                        {this[renders[type]]()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={this.handleHide}
                        disabled={!!processing}>{gettext('Done')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
