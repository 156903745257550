import React from 'react';
import PropTypes from 'prop-types';

import wrap from 'airborne/search2/LazyComponent';

import WaitBooking from 'airborne/search_cars/WaitBooking';
import {loadCarSearch} from 'airborne/search_cars/lazyloader';

class Loader extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };
    render() {
        const {children} = this.props;
        return children  || (<WaitBooking show />);
    }
}

export function loadCarCheckout() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./index');
            resolve(loaded);
        }, 'car-checkout');
    });
}

function load() {
    return Promise.all([loadCarCheckout(), loadCarSearch()])
        .then(([checkout])=> checkout);
}


const Checkout = wrap(load, 'default', Loader);

export default Checkout;
