import PropTypes from 'prop-types';


export const AddressShape = PropTypes.shape({
    'address': PropTypes.string,
    'city': PropTypes.string,
    'company_name': PropTypes.string,
    'country_code': PropTypes.string,
    'phone': PropTypes.shape({
        'country_code': PropTypes.string,
        'number': PropTypes.string,
    }),
    'postal_code': PropTypes.string,
});

export const CardSourceProp = PropTypes.oneOf(['sso', 'tspm_traveler', 'tspm_company']);

export const CardShape = PropTypes.shape({
    cardIndex: PropTypes.string,
    cardSource: CardSourceProp,
    cardNumber: PropTypes.string,
    cardType: PropTypes.string,
    cardYear: PropTypes.number,
    cardMonth: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
});
