import partial from 'lodash/partial';
import {changeFormValue, sendChanges} from './userSettings';
import {redirect} from 'midoffice/data/actions/auth';

export function sendNewPassword() {
    return function sendNewPasswordD(dispatch) {
        return dispatch(sendChanges('changePassword', null, null, true))
            .then((success) => success && dispatch(redirect('/accounts/login/?source=password-change')));
    };
}
export const changePasswordValue = partial(changeFormValue, 'changePassword');
