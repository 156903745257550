const getCounteroffer = (state) => (
    state['actionableCounteroffer']
);

export const getCounterofferLoading = (state) => (
    getCounteroffer(state)['loading']
);

export const getCounterofferError = (state) => (
    getCounteroffer(state)['error']
);

export const getCounterofferType = (state) => (
    getCounteroffer(state)['requestType']
);

export const getCounterofferRequestSuccess = (state) => (
    getCounteroffer(state)['requestSuccess']
);

export const getBackupBooking = (state) => (
    getCounteroffer(state)['backupBooking']
);
