import {createSchema, required} from 'midoffice/newforms/helpers';
import {gettext} from 'airborne/gettext';
import identity from 'lodash/identity';

import {Field, RegexField} from 'midoffice/newforms/fields-stateless';
import {makePNRField} from './PNRField';

const CompanyField = {
    ...Field,
    emptyMessage: ()=> gettext('Company is required'),
};

const CASE_NUMBER_MAX_LEN = 100;
const CaseNumberField = {
    ...RegexField,
    maxLength: CASE_NUMBER_MAX_LEN,
    regex: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/i,
    invalidFormatMessage: gettext('Ensure this field has only alphanumeric characters divided by hyphen'),
};

export default function pnrSearchSchema({isAddPnr, pnrs, asDesktopEnabled, canBeMulti}) {
    return createSchema({
        fields: {
            'pnr': (isAddPnr ? required : identity)(makePNRField(pnrs, canBeMulti)),
            'company': (isAddPnr ? identity : required)(CompanyField),
            ...(asDesktopEnabled
                ? {'caseNumber': identity(CaseNumberField)}
                : {}
            ),
        },
    });
}
