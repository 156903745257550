import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';

import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';

import Field from 'midoffice/newforms/components/Field';
import Input from 'midoffice/newforms/widgets/Input';
import IdField from './IdField';
import ErrorBox from './ErrorBox';

import {getPnrProfile, getHomepagePnrIndex} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getCompanies, isCompaniesLoading, isSuggestedCompaniesLoading, getCompaniesError, noCompaniesFound} from 'airborne/store/modules/homepage/selectors/tspm';

import dataset from 'airborne/bookings2/helpers/dataset';

import {
    searchTspmCompanyProfile,
    discardTspmCompanyProfiles,
    selectTspmCompanyProfile,
    loadSuggested,
} from 'airborne/store/modules/homepage/actions/tspm';


class SearchForm extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        loading: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const {loading, value, onChange, onSubmit} = this.props;
        const errors = IdField.validate(value);
        return (<div className="modal-header-form">
            <form className="form-inline" onSubmit={onSubmit} >
                <Field
                    name="name"
                    value={value}
                    errors={errors}
                    label={gettext('Search by TSPM Entity ID (TCP#)')}
                    labelSuffix=""
                    autoFocus
                    grid={false}
                    inputSize="max"
                    onChange={onChange}
                    widget={Input} />
                <Button
                    progress={loading}
                    disabled={loading || !value || Boolean(value && errors)}
                    bsStyle="primary"
                    name="search"
                    onClick={this.props.onSubmit}>
                    {gettext('Search')}
                </Button>
            </form>
        </div>);
    }
}

class ProfileList extends React.Component {
    static propTypes = {
        searched: PropTypes.string,
        selected: PropTypes.string,
        loading: PropTypes.bool,
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        onDiscard: PropTypes.func.isRequired,
    };

    handleClick = event => {
        const {id} = dataset(event.currentTarget);
        this.props.onChange(id);
    };

    handleBack = event => {
        event.preventDefault();
        this.props.onDiscard();
    };

    renderCompany({id, name, email}) {
        const {selected} = this.props;
        if (!name || !id) {
            return null;
        }
        const cls = (selected === id) ? 'modal-body__scroll-item--selected' : null;
        return (<li className={cls} key={id} data-id={id} onClick={this.handleClick} >
            {name}
            <div className="modal-body__scroll-info">
                <div>{email}</div>
            </div>
        </li>);
    }

    renderLoading() {
        return (<li>{gettext('Loading more…')}</li>);
    }

    render() {
        const {data, searched, loading} = this.props;
        return (<div>
            {searched && (<h4 className="modal-body__scroll-header">
                {gettext('Search Results for TCP# {id}', {id: searched})}
                <a href="#" className="modal-body__clear-lnk" onClick={this.handleBack} >
                    &laquo;&nbsp;
                    {gettext('Back to pre-selected TSPM profiles')}
                </a>
            </h4>)}
            <ul className="modal-body__scroll-list">
                {data.map((el)=> this.renderCompany(el))}
                {loading && this.renderLoading()}
            </ul>
        </div>);
    }
}

function mapStateProps(state, {index}) {
    const profile = getPnrProfile(state, index);
    return {
        loading: isCompaniesLoading(state, index),
        error: getCompaniesError(state, index),
        loadingSuggested: isSuggestedCompaniesLoading(state, index),
        data: getCompanies(state, index),
        empty: noCompaniesFound(state, index),
        selected: profile['tspm_company_id'] || null,
    };
}

@connect((state)=> ({index: getHomepagePnrIndex(state)}))
@connect(mapStateProps, (dispatch, {index}) => ({
    onSearch: (value)=> dispatch(searchTspmCompanyProfile(value, index)),
    onClose: ()=> dispatch(discardTspmCompanyProfiles(index)),
    onChange: (profile)=> dispatch(selectTspmCompanyProfile(profile, index)),
    onLoad: () => dispatch(loadSuggested()),
}))
export default class CompanyModal extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        loadingSuggested: PropTypes.bool,
        selected: PropTypes.string,
        error: PropTypes.object,
        empty: PropTypes.bool,
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        onLoad: PropTypes.func.isRequired,
    };

    state = {
        value: null,
        selected: this.props.selected,
        searched: null,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    componentWillUnmount() {
        this.props.onClose();
    }

    findProfile(profileId) {
        const {data} = this.props;
        return find(data, (profile)=> profile.id === profileId);

    }

    handleChange = name => {
        this.setState({value: name});
    };

    handleSearch = event => {
        event.preventDefault();

        const {value} = this.state;
        this.props.onSearch(value);
        this.setState({searched: value});
    };

    handleSelect = id => {
        this.setState({selected: id});
    };

    handleDiscard = () => {
        this.setState({searched: null});
        this.props.onClose();
    };

    handleSubmit = () => {
        const profile = this.findProfile(this.state.selected);
        this.props.onChange(profile);
        this.props.onHide();
    };

    renderEmpty() {
        return (<ErrorBox append={gettext('Please enter different search criteria.')}>
            {gettext('No Results Found')}
        </ErrorBox>);
    }


    renderError(error) {
        return (<ErrorBox append={error.body}>{error.title}</ErrorBox>);
    }

    render() {
        const {loading, loadingSuggested, empty, error, data, onHide} = this.props;
        const {value, selected, searched} = this.state;
        const hasProfile = Boolean(
            this.findProfile(selected)
        );

        return (
            <Modal show keyboard backdrop="static" onHide={onHide} className="modal--rev" >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Select TSPM Company Profile')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <SearchForm
                        loading={loading}
                        value={value}
                        onChange={this.handleChange}
                        onSubmit={this.handleSearch} />
                    {!error && (<ProfileList
                        data={data}
                        loading={loadingSuggested}
                        selected={selected}
                        searched={searched}
                        onDiscard={this.handleDiscard}
                        onChange={this.handleSelect} />)}
                    <div className="modal-body__panel">
                        {error && this.renderError(error)}
                        {empty && this.renderEmpty()}
                        {!error && !empty && (<h4>{gettext('Please enter search criteria and click Search.')}</h4>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="light" onClick={onHide} >{gettext('Cancel')}</Button>
                    <Button disabled={!hasProfile || loading} onClick={this.handleSubmit} bsStyle="success" name="select">{gettext('Select')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
