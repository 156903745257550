'process i18n';
import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {
    getCompanyName
} from 'airborne/store/modules/homepage/selectors/homepage';

@connect((state)=> ({
    company: getCompanyName(state),
}))
export default class HeapAnalyticsSecretFields extends React.Component {
     static propTypes = {
        company: PropTypes.string,
    };
    render() {
        const {company} = this.props;
        return (
            <div>
                <input type="hidden" data-heap="current_company_name" value={company}/>
            </div>
        );
    }
}
