import React from 'react';
import PropTypes from 'prop-types';

import PrefIcons from 'airborne/search2/hotel/PrefIcons';
import Ratings from 'airborne/search2/hotel/Ratings';
import formatAddress from 'airborne/search2/helpers/address';
import OutOfPolicy from 'airborne/search2/hotel/OutOfPolicy';


export default class HotelCard extends React.Component {
    static propTypes = {
        address: PropTypes.string.isRequired,
        postalCode: PropTypes.string,
        stateProvinceCode: PropTypes.string,
        city: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        thumbnails: PropTypes.arrayOf(PropTypes.string).isRequired,
        clientPreferred: PropTypes.bool,
        clientPreferenceTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        clientPreferredByChain: PropTypes.bool,
        tmcPreferred: PropTypes.bool,
        tmcPreferenceTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        tmcPreferredByChain: PropTypes.bool,
        hotelRating: PropTypes.number,
        labeling: PropTypes.object,
    };

    render() {
        const {
            address, city,
            postalCode, stateProvinceCode, countryCode,
            name,
            thumbnails,
            clientPreferred, clientPreferenceTier,
            tmcPreferred, tmcPreferenceTier,
            hotelRating, tmcPreferredByChain, clientPreferredByChain,
            labeling, securityVetted,
            available: {
                outOfPolicy, outOfPolicyReason, currency,
            }={},
        } = this.props;

        const [imageUrl] = thumbnails || [];

        return (<div >
            <div className="checkout__info__section">
                <div className="checkout__info__thumb"
                    style={{backgroundImage: `url('${imageUrl}90x90.jpg')`}} />
                <div className="checkout__info__hotel">
                    <h3>
                        {name}
                        {/*
                            //TODO: Feature of this icon is unclear.
                            &nbsp;
                            {isOnRequest && (<sup className="h-item__hotel-name__label">{gettext('on-request')}</sup>)}
                        */}
                    </h3>
                    <div className="checkout__info__header__addr">
                        {formatAddress(address, city, stateProvinceCode, postalCode, countryCode)}
                    </div>
                    <div className="checkout__info__row">
                        <div className="checkout__info__col">
                            <Ratings
                                stars={hotelRating} />
                        </div>
                        <div className="checkout__info__col">
                            <PrefIcons
                                client={clientPreferred}
                                clientTier={clientPreferenceTier}
                                tmc={tmcPreferred}
                                tmcTier={tmcPreferenceTier}
                                clientByChain={clientPreferredByChain}
                                tmcByChain={tmcPreferredByChain}
                                securityVetted={securityVetted}
                                labeling={labeling}
                            >
                                <OutOfPolicy icon reason={outOfPolicyReason} show={outOfPolicy} currency={currency} />
                            </PrefIcons>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
