/* global module */
import React from 'react';
import {hot} from 'react-hot-loader';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import gettext from 'airborne/gettext';
import Button from 'midoffice/components/Button';
import {getAirExchangeCurrentBooking} from 'airborne/store/modules/exchange_air/selectors';
import {goToExchangePricing} from 'airborne/store/modules/exchange_air/actions';
import {toCheckout} from 'airborne/store/modules/search_air/actions/toBook';
import {
    getManualExchangeForm,
} from 'airborne/store/modules/exchange_air/selectors';
import {syncChangeManualExchangeForm} from 'airborne/store/modules/exchange_air/actions/manualExchangeForm';
import createManualExchangeSchema from './schema';
import Editor from './Editor';
import {getSelectedFlightPrice} from 'airborne/store/modules/search_air/selectors';

@hot(module)
@connect(
    (state) => {
        return {
            currentBooking: getAirExchangeCurrentBooking(state),
            selectedBooking: getSelectedFlightPrice(state),
            manualExchangeForm: getManualExchangeForm(state),
        };
    },
    {
        backToPricing: goToExchangePricing,
        toCheckout: toCheckout,
        changeManualExchangeForm: syncChangeManualExchangeForm,
    }
)
export default class ManualExchangeForm extends React.Component {
    static propTypes = {
        currentBooking: PropTypes.any,
        selectedBooking: PropTypes.any,
        backToPricing: PropTypes.func,
        toCheckout: PropTypes.func,
        manualExchangeForm: PropTypes.object,
        changeManualExchangeForm: PropTypes.func,
    };

    render() {
        const {backToPricing, toCheckout, manualExchangeForm, changeManualExchangeForm, currentBooking, selectedBooking} = this.props;
        const {confirmation: {originalCurrency: currentCurrency}} = currentBooking;
        const {currency: selectedCurrency} = selectedBooking;
        const schema = createManualExchangeSchema();
        return (
            <>
                <Editor
                    schema={schema}
                    value={manualExchangeForm.value}
                    onChange={changeManualExchangeForm}
                    errors={manualExchangeForm.errors}
                    currentCurrency={currentCurrency}
                    selectedCurrency={selectedCurrency}
                />
                <Modal.Footer>
                    <Button onClick={backToPricing} bsStyle="link">
                        {gettext('Cancel')}
                    </Button>
                    <Button onClick={toCheckout} bsStyle="primary">
                        {gettext('Confirm')}
                    </Button>
                </Modal.Footer>
            </>
        );
    }
}
