import {createSchema, required} from 'midoffice/newforms/helpers';
import {emptyMessage} from 'airborne/checkout2/fields';
import {CharField} from 'midoffice/newforms/fields-stateless';
import {makeReField} from 'airborne/checkout2/fields';
import gettext from 'airborne/gettext';

const CommentField = makeReField(
    {
        ...CharField,
        minLength: 1,
        maxLength: 250,
    },
    /^[^<>&]*$/,
    ()=> gettext('No special characters are allowed!'),
);

export default function createBookingResolveSchema() {
    return createSchema({
        fields: {
            'comment': required(CommentField, emptyMessage)
        },
    });
};
