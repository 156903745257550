import * as types from '../actionTypes';

export const setFlightOptionsLoading = () => ({
    type: types.AIR_AVAILABILITY_FLIGHT_OPTIONS_LOADING,
});

export const setFlightOptions = data => ({
    type: types.AIR_AVAILABILITY_FLIGHT_OPTIONS_LOADED,
    data,
});
