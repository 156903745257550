import {injectFormContext} from 'midoffice/newforms/decorators';
import React from 'react';
import PropTypes from 'prop-types';
import AirDestinationAutocomplete from 'airborne/homepage2/autocomplete/AirDestinationAutocomplete';
import gettext from 'airborne/gettext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';
import Button from 'midoffice/components/Button';
import SectionRow from 'airborne/homepage2/components/SectionRow';
import Select from 'midoffice/newforms/widgets/Select';

import {valueShape} from './shapes';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';
import {INITIAL_ORIGIN_DESTINATION_VALUE} from 'airborne/store/modules/homepage/reducers/destination';
import {getAirTripTypeTuples, getSearchChoicesValue, handleTripTypeChange, handleSearchOptionsChange} from './helpers';
import AirDestinationField from './AirDestinationField';
import AirAirlineCodesField from './AirAirlineCodesField';
import AirAlliancesField from './AirAlliancesField';
import AddMore from './AddMore';
import AirTransferAirports from 'airborne/homepage2/AirDestinationSearch/AirTransferAirports';
import IdNumbers from 'airborne/homepage2/IdNumbers';
import {prepareChoices} from 'airborne/homepage2/helpers/carVendor';
import settings from 'airborne/settings';
import FlownFlights from 'airborne/exchange_air/Modification/Form/FlownFlights';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';

@injectFormContext
export default class AirAvailabilityEditor extends React.Component {

    static propTypes = {
        value: valueShape,
        errors: PropTypes.any,
        disabled: PropTypes.bool.isRequired,
        onReuseDestination: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        companyId: PropTypes.number.isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        collapsableSections: PropTypes.objectOf(PropTypes.bool).isRequired,
        toggleCollapsableSection: PropTypes.func.isRequired,
        disableRecentSearches: PropTypes.bool,
        isAirExchangeFlow: PropTypes.bool,
        disableSearch: PropTypes.bool,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
    };

    destinationFieldUpdates = 0;

    connectionsAutocomplete = new AirDestinationAutocomplete();

    // [name, label, description, group] tuple
    getSearchChoices() {
        return [
            ['directFlights', gettext('Direct Flights Only'), null, gettext('Search Options')]
        ];
    }

    getSearchChoicesValue() {
        const {value} = this.props;
        const choices = this.getSearchChoices();

        return getSearchChoicesValue(value, choices);
    }

    handleSearchOptionsChange = (newValue) => {
        const {value, onChange} = this.props;
        const form = handleSearchOptionsChange(value, newValue, this.getSearchChoices());

        onChange(form);
    }

    handleFormTripTypeChange = (tripType) => {
        const {value, errors, onChange} = this.props;
        const form = handleTripTypeChange(value, errors, tripType);

        onChange(form);

        // We need to reset AirDestinationField state after Trip Type change,
        // in this way we're preventing EMPTY destinations from MultiCity displaying on OneWay/RoundTrip
        this.destinationFieldUpdates++;
    }

    render() {
        const {
            onReuseDestination,
            companyId,
            onSubmit,
            collapsableSections,
            toggleCollapsableSection,
            disabled,
            loading,
            value,
            disableRecentSearches,
            disableSearch,
            isFirstExchangeOriginDestinationFlown,
        } = this.props;
        const {tripType, originDestinations, connections, airlineAlliances} = value;

        const autocompleteExtra = {'configuration_id': companyId, product: 'air'};

        return (
            <div className="ag-home-form ag-home-form--wide-labels">
                {!isFirstExchangeOriginDestinationFlown &&
                    <Row>
                        <div className="ag-mode__selector">
                            <Select.Field
                                name="tripType"
                                choices={getAirTripTypeTuples()}
                                onChange={this.handleFormTripTypeChange}
                                required={true}
                            />
                        </div>
                    </Row>
                }

                {isFirstExchangeOriginDestinationFlown && (
                    <>
                        <FlownFlights originDestinations={originDestinations} />
                        <h5 className="text-right">
                            <strong>{gettext('Remaining Flight(s)')}</strong>
                        </h5>
                        <hr className="ag-home-form__hr" />
                    </>
                )}

                <AirDestinationField.Field
                    name="originDestinations"
                    companyId={companyId}
                    noAddMore={tripType !== AIR_TRIP_TYPES.MULTI_CITY && !isFirstExchangeOriginDestinationFlown}
                    addMoreLabel={gettext('+ Add Route')}
                    addMoreComponent={AddMore}
                    onReuseDestination={onReuseDestination}
                    tripType={tripType}
                    multi
                    plainWidget
                    disableRecentSearches={disableRecentSearches}
                    key={this.destinationFieldUpdates}
                    noCabin
                    defaultValue={INITIAL_ORIGIN_DESTINATION_VALUE}
                    originDestinations={originDestinations}
                    isFirstExchangeOriginDestinationFlown={isFirstExchangeOriginDestinationFlown}
                />

                <hr className="ag-home-form__hr" />
                <Row>
                    <div className="form-group">
                        <label className="control-label col-xs-2">{gettext('Search Options')}</label>
                        <Col xs={9}>
                            <MultiCheckbox
                                name="select"
                                value={this.getSearchChoicesValue()}
                                choices={this.getSearchChoices()}
                                onChange={this.handleSearchOptionsChange}
                                testIdPrefix="air-homepage-availability-options"
                                table={false}
                            />
                        </Col>
                    </div>
                </Row>
                <hr className="ag-home-form__hr" />
                <SectionRow
                    title={gettext('Airlines & Alliances')}
                    open={collapsableSections['airlines']}
                    name="airlines"
                    handleToggle={toggleCollapsableSection}
                >
                    <Row>
                        <div className="form-group">
                            <AirAirlineCodesField />
                        </div>
                    </Row>
                    <Row>
                        <div className="form-group">
                            <AirAlliancesField chosenValues={airlineAlliances} />
                        </div>
                    </Row>
                </SectionRow>
                <SectionRow
                    title={gettext('Transfer Airport(s)')}
                    open={collapsableSections['transferAirport']}
                    name="transferAirport"
                    handleToggle={toggleCollapsableSection}
                >
                    <AirTransferAirports
                        autocompleteSource={this.connectionsAutocomplete}
                        autocompleteExtra={{...autocompleteExtra, recentSearches: false}}
                        required={false}
                        disabled={disabled}
                        multiCount={originDestinations?.length}
                        tripType={tripType}
                        value={connections}
                    />
                </SectionRow>
                <SectionRow
                    title={gettext('Frequent Flyer Number(s)')}
                    open={collapsableSections['frequentFlyerNumber']}
                    name="frequentFlyerNumber"
                    handleToggle={toggleCollapsableSection}
                    data-testid="frequent-flyer-number-section"
                >
                    <IdNumbers.Field
                        multi
                        plainWidget
                        addMoreComponent={AddMore}
                        addMoreLabel={gettext('+ Add a Frequent Flyer Number')}
                        addMoreTestId="air-homepage-availability-add-ff-number"
                        name="ftNumbers"
                        disabled={disabled}
                        inputPlaceholder={gettext('Frequent Flyer Number')}
                        className="form-group--well"
                        vendors={prepareChoices(settings.AIRLINES)}
                    />
                </SectionRow>
                <div className="ag-home-form__control ag-home-form__control--bottom">
                    <Button className="pull-right" bsStyle="success" data-testid="find-flight-availability-btn" onClick={onSubmit} progress={loading} disabled={disableSearch}>
                        <Shortcut label={gettext('Find a Flight')} shortcut={SHORTCUTS_LIST.G} action={onSubmit} />
                    </Button>
                </div>
            </div>
        );
    }
}
