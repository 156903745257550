import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {gettext, ngettext} from 'airborne/gettext';
import {connect} from 'react-redux';
import settings from 'airborne/settings';

import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import Alert from 'react-bootstrap/Alert';
import AmenitiesIcons from 'midoffice/components/AmenitiesIcons';
import RateDetails from 'airborne/search2/hotel/RateDetails';
import ProviderLabel from 'airborne/search2/hotel/ProviderLabel';
import RateCode from 'airborne/search2/hotel/RateCode';
import CurrencyDisclaimer from 'airborne/checkout2/CurrencyDisclaimer';
import TotalPay from 'airborne/checkout2/TotalPay';

import HotelCard from './HotelCard';
import OneLineText from 'airborne/checkout2/OneLineText';
import normalize from 'airborne/search2/helpers/normalize';
import {formatStrict as formatPrice} from 'airborne/utils/price';
import {hotelLabeling, rateLabeling} from 'airborne/search2/helpers/hyatt';
import {getSelectedRate, getSelectedHotel} from 'airborne/search2/helpers/rates';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {getCompany} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getAvail} from 'airborne/search2/helpers/avail';


class Prices extends React.Component {
    static propTypes = {
        nights: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        average: PropTypes.number.isRequired,
        taxes: PropTypes.number,
        taxMark: PropTypes.oneOf(['TE', 'TI']),
        currency: PropTypes.string.isRequired,
        originalTotal: PropTypes.number.isRequired,
        originalCurrency: PropTypes.string.isRequired,
    };

    render() {
        const {nights, average, total, taxes, taxMark, currency, originalTotal, originalCurrency} = this.props;
        return (<table className="table checkout__info__table">
            <tbody>
                <tr>
                    <td>{gettext('Average Rate / Night')}</td>
                    <td style={{width: 90}} className="text-right">
                        {annotateCurrency(formatPrice(average, currency))}
                    </td>
                </tr>
                <tr>
                    <td>{gettext('Number of Nights')}</td>
                    <td style={{width: 90}} className="text-right">
                        {nights}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>{gettext('Estimated Taxes & Fees*')}</td>
                    <td style={{width: 90}} className="text-right">
                        {taxes
                            ? annotateCurrency(formatPrice(taxes, currency))
                            : {
                                TI: gettext('Included'),
                                TE: '–',
                            }[taxMark]
                        }
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>{gettext('Estimated Total*')} </strong>
                    </td>
                    <td style={{width: 90}} className="text-right">
                        <div>
                            <strong>{annotateCurrency(formatPrice(total, currency))}</strong>
                        </div>
                        {(originalCurrency !== currency) && (<div>
                            {annotateCurrency(formatPrice(originalTotal, originalCurrency))}
                        </div>)}
                    </td>
                </tr>
            </tbody>
        </table>);
    }
}

function PriceChange() {
    return (<Alert variant="warning" closeLabel="Close alert">
        <Glyphicons bsClass="glyphicon" glyph="exclamation-sign" />
        <strong>{gettext('Price Change.')}</strong>
            &nbsp;
        {gettext('The price of your selected hotel has recently changed. Book at the new price or select a different hotel.')}
    </Alert>);
}

function mapStateProps(state, {index}) {
    const {dates: {min, max}} = getDestination(state);
    const minDate = moment(min);
    const maxDate = moment(max);
    const rate = getSelectedRate(state, index);
    const hotel = getSelectedHotel(state, index);
    const companyName = getCompany(state).label;

    const {i18n, hotels: {avail, rates}} = state;
    const available = normalize(
        getAvail(avail?.data, rates, hotel?.id, i18n?.currency)
    );

    return {
        ...rate,
        hotel: {...hotel, available},
        companyName,
        nights: maxDate.diff(minDate, 'days'),
        checkin: minDate,
        checkout: maxDate,
        labeling: normalize(rateLabeling(state)),
        hotelLabeling: hotelLabeling(state),
        rateKey: rate.rateKey,
        hotelId: hotel && hotel.id,
    };
}


@connect(mapStateProps)
export default class RateCard extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number,
        hotel: PropTypes.shape(HotelCard.propTypes),
        companyName: PropTypes.string,
        isConfirmation: PropTypes.bool,
        providerId: PropTypes.any,
        rateKey: PropTypes.string.isRequired,
        nights: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        average: PropTypes.number.isRequired,
        taxes: PropTypes.number,
        currency: PropTypes.string.isRequired,
        originalTotal: PropTypes.number.isRequired,
        originalCurrency: PropTypes.string.isRequired,
        taxMark: PropTypes.oneOf(['TE', 'TI']),
        paidPolicy: PropTypes.oneOf(['D', 'G']),
        provider: PropTypes.string.isRequired,
        rateValueAdds: PropTypes.arrayOf(PropTypes.string).isRequired,
        roomTypeCode: PropTypes.string.isRequired,
        rateCode: PropTypes.string.isRequired,
        rateDescription: PropTypes.string,
        rateDetailsDescription: PropTypes.string,
        rateCompanyName: PropTypes.string,
        checkin: PropTypes.object.isRequired,
        checkout: PropTypes.object.isRequired,
        clientPreferred: PropTypes.bool,
        membersOnly: PropTypes.bool,
        tmcPreferred: PropTypes.bool,
        labeling: PropTypes.shape({
            supplier: PropTypes.bool,
            agencyNegotiated: PropTypes.bool,
            clientNegotiated: PropTypes.bool,
            outOfPolicy: PropTypes.bool,
        }),
        perNight: PropTypes.arrayOf(
            PropTypes.shape({rate: PropTypes.number.isRequired})
        ),
        priceChange: PropTypes.bool,
        paymentOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
        hotelLabeling: PropTypes.object,
    };

    state = {details: false};

    handleDetails = ()=> {
        this.setState({details: true});
    };

    handleCloseDetails = ()=> {
        this.setState({details: false});
    };

    renderModal() {
        const {hotelId, rateKey, currency} = this.props;
        if (!this.state.details) {
            return null;
        }
        return (
            <RateDetails
                noButton
                hotelId={hotelId}
                rateKey={rateKey}
                currency={currency}
                onHide={this.handleCloseDetails} />
        );
    }

    render() {
        const {
            nights,
            provider, providerId,
            rateValueAdds,
            rateCompanyName,
            rateCode, roomTypeCode,
            rateDescription, rateDetailsDescription,
            paidPolicy,
            taxMark,
            average, taxes, total, currency,
            originalTotal, originalCurrency,
            checkin, checkout,
            clientPreferred, tmcPreferred, membersOnly,
            labeling,
            priceChange,
            perNight,
            paymentOptions,
            hotel,
            companyName,
            isConfirmation,
            hotelLabeling,
        } = this.props;
        const {'company_name': TravelerCompanyName} = settings.USER;
        const displayCompanyName = companyName ? companyName : TravelerCompanyName;
        return (<div className="section-wrapper">
            {!isConfirmation && <header className="rev-checkout__sidebar__header">
                {gettext('Trip Summary')}
            </header>}
            {this.renderModal()}
            <div className="checkout__info">
                {hotel ? <HotelCard {...hotel} labeling={hotelLabeling} /> : null}

                <hr/>

                <div className="checkout__info__section">
                    {!isConfirmation && displayCompanyName &&
                    <div>
                        <strong>
                            {gettext('Company Name')}:
                        </strong>
                        &nbsp;
                        {displayCompanyName}
                    </div>
                    }
                    <div>
                        <strong>
                            {ngettext('{num} Night:', '{num} Nights:', nights)}
                        </strong>
                        &nbsp;
                        {checkin.format(settings.DATE_FORMAT)}
                        &nbsp;–&nbsp;
                        {checkout.format(settings.DATE_FORMAT)}
                    </div>

                    <OneLineText>
                        <strong>
                            {gettext('1 Room:')}
                        </strong>
                        &nbsp;
                        {rateDescription || rateDetailsDescription}

                        <div>
                            <ul>
                                {perNight.map(
                                    ({rate}, idx)=> (<li key={idx}>
                                        <strong>{gettext('Night {n}:', {n: idx+1})}</strong>
                                        &nbsp;
                                        {annotateCurrency(formatPrice(rate, currency))}
                                    </li>)
                                )}
                            </ul>
                        </div>
                    </OneLineText>
                </div>

                {rateValueAdds.length > 0 && [
                    <hr key="amenities-hr" />,
                    <div key="amenities-section" className="checkout__info__section">
                        <div className="checkout__info__section__title">
                            {gettext('Amenities')}
                        </div>

                        <div className="checkout__info__section__value">
                            <AmenitiesIcons codes={rateValueAdds} />
                        </div>
                    </div>
                ]}

                {!isConfirmation && <>
                    <hr/>
                    <div className="checkout__info__section">
                        <div className="checkout__info__section__title">
                            <Button variant="link" className="btn-link--narrow" onClick={this.handleDetails}>
                                {gettext('View Rate Details »')}
                            </Button>
                        </div>
                        <div className="checkout__info__section__value">
                            <RateCode
                                provider={provider}
                                inline
                                rate={rateCompanyName || rateCode}
                                room={roomTypeCode}
                                client={clientPreferred && labeling.clientNegotiated}
                                tmc={tmcPreferred && labeling.agencyNegotiated}
                                membersOnly={membersOnly} />

                            &nbsp;
                            <ProviderLabel id={providerId} provider={provider} show={labeling.supplier} />
                        </div>
                    </div>
                </>}

                {priceChange && <PriceChange />}

                <hr/>

                <div className="checkout__info__section">
                    <Prices
                        nights={nights}
                        average={average}
                        total={total}
                        taxes={taxes}
                        taxMark={taxMark}
                        currency={currency}
                        originalTotal={originalTotal}
                        originalCurrency={originalCurrency} />
                </div>
            </div>

            <CurrencyDisclaimer
                className="checkout__info__note" product="hotels"
                total={total}
                currency={currency} originalCurrency={originalCurrency} />

            <p className="checkout__info__note">
                {gettext('* May not include additional taxes, service fees or other hotel charges.')}
            </p>

            {!isConfirmation && <TotalPay className="checkout__info__footer" paidPolicy={paidPolicy} paymentOptions={paymentOptions}
                total={total} currency={currency}/>}
        </div>);
    }
}
