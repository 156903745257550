import every from 'lodash/every';
import range from 'lodash/range';

import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';

import {getPnrCount} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getSelectedRateKey} from 'airborne/search2/helpers/rates';
import {getBookingUID} from 'airborne/store/modules/confirm_hotels/selectors/details';

import {toMultiCheckout} from 'airborne/store/modules/checkout_hotels/actions/multicheckout';


@connect(function mapStateProps(state) {
    return {
        ready: every(range(0, getPnrCount(state))
            .map((index)=>
                getSelectedRateKey(state, index) || getBookingUID(state, index)
            )
        ),
    };
}, {onClick: toMultiCheckout})
export default class ProceedMultiCheckout extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        ready: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {className, ready, onClick} = this.props;
        return (
            <Button
                variant="secondary"
                className={className}
                disabled={!ready}
                onClick={onClick}
            >
                {gettext('Proceed to Checkout')}
            </Button>
        );
    }
}
