import React from 'react';
import PropTypes from 'prop-types';
import Glyphicons from 'midoffice/components/Glyphicons';

export default class ErrorBox extends React.Component {
    static propTypes = {
        append: PropTypes.string,
        children: PropTypes.any,
    };

    render() {
        return (<div className="alert alert-danger">
            <Glyphicons bsClass="glyphicon" glyph="remove-sign" />
            <strong>
                {this.props.children}
            </strong>
            <p className="alert__text">
                {this.props.append}
            </p>
        </div>);
    }
}
