import air from 'airborne/store/modules/search_hotels/actions/air';
import flatten from 'lodash/flatten';
import {getAllErrorMessages, getErrorCodes} from 'airborne/helpers/apiError';
import parsePricingFares, {mergePricingResponses} from 'airborne/pricing_air/helpers/parse';
import * as types from '../actionTypes';
import {getPricingRequestParams} from '../selectors';
import {FIELDS} from 'airborne/pricing_air/types';
import {parsePolicyRules, setPolicyRules} from 'airborne/store/modules/search_air/actions/searchAir';

const setFaresLoading = () => ({
    type: types.AIR_PRICING_SET_FARES_LOADING,
});

const setFaresData = data => ({
    type: types.AIR_PRICING_SET_FARES_DATA,
    data,
});

const setFaresErrors = (errors) => ({
    type: types.AIR_PRICING_SET_FARES_ERRORS,
    errors,
});

const setFaresErrorCodes = (errorCodes) => ({
    type: types.AIR_PRICING_SET_FARES_ERROR_CODES,
    errorCodes,
});

const handleFailedResponse = (dispatch, response) => {
    const isNotFound = response.status === 404;
    if (isNotFound) {
        return dispatch(setFaresData([]));
    }

    const errorMessages = getAllErrorMessages(response);
    dispatch(setFaresErrors(errorMessages));

    const errorCodes = getErrorCodes(response);
    dispatch(setFaresErrorCodes(errorCodes));
}

export const requestFaresPricing = () => async (dispatch, getState) => {
    dispatch(setFaresLoading());
    try {
        const response = await air(
            'POST',
            '/air/pricing/',
            getPricingRequestParams(getState())
        );
        const fares = parsePricingFares(response);
        const policyRules = parsePolicyRules(response);
        dispatch(setFaresData(fares));
        dispatch(setPolicyRules(policyRules));
    }
    catch (response) {
        handleFailedResponse(dispatch, response)
    }
};

export const requestFaresPricingMultiClasses = (services) => async (dispatch, getState) => {
    dispatch(setFaresLoading());

    const pricingRequests = services.map(preselectedServices => {
        return air(
            'POST',
            '/air/pricing/',
            getPricingRequestParams(
                getState(),
                {
                    formValue: {
                        [FIELDS.services]: preselectedServices,
                        [FIELDS.ignoreClassOfService]: true,
                    }
                }
            )
        );
    });

    try {
        const responses = await Promise.all(pricingRequests);
        const data = mergePricingResponses(responses);
        const policyRules = flatten(responses.map(response => parsePolicyRules(response)));
        dispatch(setFaresData(data));
        dispatch(setPolicyRules(policyRules));
    } catch (response) {
        handleFailedResponse(dispatch, response)
    }
};
